import { LibraryWrapper } from "../common/LibraryWrapper";
import { styled } from "styled-components";
import PlayVideo from "../../assets/icons/PlayVideo";
import { colors } from "../../styles/styleUtils";
import { MMLButton } from "../../styles";

const HTPDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HTPTitle = styled.h2`
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.3px;
  margin-bottom: 21px;
  text-transform: uppercase;
`;

const ThumbnailContainer = styled.div`
  position: relative;
  background-color: ${colors["lvl_3"]};
  margin-bottom: 16px;
  aspect-ratio: 16 / 9;
  cursor: pointer;

  svg {
    color: #fdfdfd;
  }

  &:hover {
    #overlay {
      opacity: 0.4;
    }
  }
`;

const PosterImg = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const FadeOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #17171d;
  opacity: 0.6;
  transition: opacity 0.2s;
`;

const StyledPlayVideoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface HTPAction {
  /** Button title */
  title: string;
  /** Button action */
  handleClick: () => void;
  /** (Optional) ARIA title, if it differs from button title. */
  ariaTitle?: string;
}

interface HTPDashboardProps {
  /* Thumbnail to display */
  /* Since no video is played directly in this component, its just an image that looks like a thumbnail */
  thumbnailUrl: string;
  /* Funtion that triggers when the image is clicked */
  handleThumbnailClick: () => void;
  /* Action button that appears below the "video" */
  htpAction: HTPAction;
  /* Ttitle placed above video. Defaults to 'How To Play' */
  title?: string;
}

export const MMLHTPDashboard = ({
  thumbnailUrl,
  handleThumbnailClick,
  htpAction,
  title = "How To Play",
}: HTPDashboardProps) => {
  return (
    <LibraryWrapper>
      {/* HTPDashboardContainer will always stretch to the size of its parent container */}
      <HTPDashboardContainer>
        <HTPTitle>{title}</HTPTitle>
        {/* ThumbnailContainer will always maintain an aspect ratio of 16 / 9 */}
        <ThumbnailContainer onClick={handleThumbnailClick}>
          <PosterImg alt="How to play" src={thumbnailUrl} />
          <FadeOverlay id="overlay" />
          <StyledPlayVideoContainer>
            <PlayVideo />
          </StyledPlayVideoContainer>
        </ThumbnailContainer>
        <MMLButton
          size="md"
          onClick={htpAction.handleClick}
          aria-label={htpAction.ariaTitle || htpAction.title}
          $secondary
        >
          {htpAction.title}
        </MMLButton>
      </HTPDashboardContainer>
    </LibraryWrapper>
  );
};
