import { SVGProps, forwardRef } from "react";

const NCAALogoLight = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  const { className, ...rest } = props;
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="47"
      viewBox="0 0 49 47"
      fill="none"
      className={className}
      {...rest}
    >
      <g clip-path="url(#clip0_12368_10742)">
        <path
          d="M23.8684 46.3648C36.4256 46.3648 46.6053 36.1278 46.6053 23.4999C46.6053 10.872 36.4256 0.635058 23.8684 0.635058C11.3112 0.635058 1.13159 10.872 1.13159 23.4999C1.13159 36.1278 11.3112 46.3648 23.8684 46.3648Z"
          fill="white"
        />
        <path
          d="M41.3568 34.1702H43.9842C41.1951 39.5045 36.4306 43.5209 30.7206 45.3512C25.0107 47.1814 18.8138 46.6786 13.4695 43.9514C8.12516 41.2241 4.06268 36.4916 2.16011 30.7765C0.257542 25.0615 0.667715 18.8231 3.30197 13.4098C5.93623 7.99641 10.583 3.84291 16.2378 1.84703C21.8927 -0.148852 28.1014 0.173204 33.5221 2.74359C38.9427 5.31397 43.1399 9.92621 45.2063 15.5834C47.2727 21.2406 47.0425 27.4883 44.5653 32.9761L43.9337 23.3411H38.0095L37.3147 25.8118H38.2305L35.319 30.4864L34.9084 23.3665H28.9211L28.239 25.8245H29.1484L25.2516 31.7567H22.0305C21.5628 31.7209 21.1282 31.5004 20.8217 31.1434C20.5152 30.7863 20.3617 30.3218 20.3947 29.8513C20.3947 28.2253 21.9295 25.8753 23.4895 25.8753H24.1211C24.0137 26.2755 23.78 27.1011 23.78 27.1011H26.6474L27.6768 23.3411H23.2558C22.0184 23.3573 20.8145 23.7475 19.8007 24.4612C18.7869 25.1748 18.0102 26.1788 17.5716 27.3425L18.7211 23.3411H14.8368L14.1421 25.8118H15.159L14.1358 29.4321L11.559 23.3411H7.88948L7.20106 25.8118H8.21159L5.93159 34.1702H8.86843C8.86843 34.1702 9.99895 30.0228 10.2958 28.9621L12.4368 34.1702H15.7084L17.2053 28.8605C17.0695 29.6656 17.0695 30.4879 17.2053 31.293C17.4895 33.0333 19.1758 34.1702 20.9947 34.1702H26.9316L27.9547 32.6141H32.1484L32.2305 34.1702H35.9316L36.8916 32.6141H41.1737L41.3568 34.1702ZM38.7737 29.9847H41.1611L41.0474 26.5168L38.7737 29.9847ZM29.6537 30.0291H32.079L31.959 26.5549L29.6537 30.0291Z"
          fill="#00A3DE"
        />
        <path
          d="M46.6053 34.1765C46.243 34.1765 45.8889 34.2846 45.5877 34.487C45.2865 34.6893 45.0517 34.977 44.9131 35.3136C44.7745 35.6501 44.7382 36.0205 44.8089 36.3778C44.8796 36.7351 45.054 37.0633 45.3101 37.3209C45.5663 37.5784 45.8927 37.7539 46.2479 37.8249C46.6032 37.896 46.9715 37.8595 47.3062 37.7201C47.6409 37.5807 47.9269 37.3446 48.1282 37.0417C48.3294 36.7388 48.4369 36.3827 48.4369 36.0184C48.4403 35.7756 48.3952 35.5346 48.3044 35.3095C48.2135 35.0845 48.0787 34.8801 47.908 34.7084C47.7372 34.5367 47.5339 34.4011 47.3102 34.3098C47.0864 34.2184 46.8467 34.1731 46.6053 34.1765ZM48.0516 36.0184C48.0516 36.3061 47.9668 36.5873 47.8078 36.8265C47.6489 37.0657 47.423 37.2521 47.1588 37.3622C46.8945 37.4723 46.6037 37.5011 46.3231 37.4449C46.0426 37.3888 45.7848 37.2503 45.5826 37.0469C45.3803 36.8435 45.2426 36.5843 45.1867 36.3022C45.1309 36.0201 45.1596 35.7276 45.2691 35.4618C45.3785 35.1961 45.5639 34.9689 45.8017 34.8091C46.0396 34.6493 46.3192 34.564 46.6053 34.564C46.796 34.5623 46.9851 34.5987 47.1617 34.6711C47.3383 34.7436 47.4988 34.8505 47.6339 34.9858C47.7691 35.1211 47.8761 35.2821 47.9489 35.4594C48.0217 35.6366 48.0588 35.8267 48.0579 36.0184H48.0516Z"
          fill="#00A3DE"
        />
        <path
          d="M47.4958 35.6183C47.4876 35.429 47.4053 35.2507 47.2669 35.1222C47.1284 34.9937 46.9451 34.9255 46.7568 34.9324H45.879V37.0855H46.2832V36.2852H46.5421L47.0726 37.0982H47.559L47.0032 36.2661C47.1478 36.2335 47.2765 36.1507 47.3665 36.0322C47.4566 35.9138 47.5024 35.7673 47.4958 35.6183ZM47.0853 35.6183C47.0804 35.702 47.0434 35.7805 46.9822 35.8373C46.921 35.8942 46.8402 35.925 46.7568 35.9232H46.2832V35.288H46.7568C46.8002 35.2872 46.8433 35.2951 46.8835 35.3114C46.9238 35.3277 46.9603 35.352 46.991 35.3828C47.0217 35.4137 47.0458 35.4504 47.062 35.4909C47.0782 35.5314 47.0861 35.5747 47.0853 35.6183Z"
          fill="#00A3DE"
        />
      </g>
      <defs>
        <clipPath id="clip0_12368_10742">
          <rect width="48" height="47" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
});

NCAALogoLight.displayName = "NCAALogoLight";

export default NCAALogoLight;
