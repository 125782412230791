import { LibraryWrapper } from "../common/LibraryWrapper";
import { MMLCard, mmlCardStyles } from "../../styles/MMLCard.styled";
import { styled } from "styled-components";
import { colors, media } from "../../styles/styleUtils";

const ContainerCard = styled(MMLCard)`
  padding: 24px 16px;
  ${media.svp} {
    padding: 16px;
  }
`;

const RenewTitle = styled.h2`
  font-size: 14px;
  font-weight: 325;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-bottom: 12px;
`;
const GroupGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const MMLRenewGroupButton = styled.button`
  ${mmlCardStyles}
  color: ${colors["lvl_-5"]};
  display: grid;
  grid-template-rows: 1fr auto;
  height: 97px;
  padding: 12px 18px;
  transition: background-color 0.2s ease-in-out;

  > div:last-child {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    > span {
      font-size: 12px;
      font-weight: 325;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
  }

  &:hover,
  &:focus {
    background-color: ${colors.lvl_3};
    border: 0.5px solid ${colors["lvl_-5"]};
  }

  ${media.svp} {
    padding: 10px 12px;
  }
`;

const GroupTitle = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: column;
  width: 100%;

  > div.renew-caption {
    color: ${colors["lvl_-1"]};
    font-size: 9px;
    font-weight: 325;
    line-height: 11px;
    letter-spacing: 0px;
    margin-bottom: 2px;
  }

  > h3 {
    font-size: 12px;
    font-weight: 325;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
  }
`;

type RenewGroupData = {
  name: string;
  action: () => void;
};

type RenewGroupsCardProps = {
  groups: RenewGroupData[];
};
/**
 * A Card element that displays common and game-specific data
 * for a user's entries across all MML games.
 */
export const MMLRenewGroupsCard = ({ groups }: RenewGroupsCardProps) => {
  return (
    <LibraryWrapper>
      <ContainerCard>
        <RenewTitle>Select a group you'd like to renew.</RenewTitle>
        <GroupGrid>
          {groups.map((group, index) => (
            <MMLRenewGroupButton key={index} onClick={group.action}>
              <GroupTitle>
                <div className="renew-caption">GROUP</div>
                <h3>{group.name}</h3>
              </GroupTitle>
              <div>
                <span>RENEW</span>
              </div>
            </MMLRenewGroupButton>
          ))}
        </GroupGrid>
      </ContainerCard>
    </LibraryWrapper>
  );
};
