export const rootFontSize = 16;
/**
 * Converts pixels to a rem value.
 * @example pxToEm(32) // '2rem'
 * pxToEm('32') // '2rem'
 * pxToEm('32px') // '2rem'
 */
export const pxToEm = (px: number | string): string =>
  `${(Number(px.toString().replace("px", "")) / rootFontSize).toFixed(2)}em`;

export const maxPageWidth = "1074px";

// Fonts
// const fallbacks = `system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'`;
export const FONTS = {
  // PRIMARY: `'Ringside', ${fallbacks}`,
  PRIMARY: "Ringside",
  GEOGROTESQUE: "Geogrotesque",
};

export const colors = {
  lvl_0: "var(--color_lvl_0)",
  lvl_1: "var(--color_lvl_1)",
  "lvl_-1": "var(--color_lvl_-1)",
  lvl_2: "var(--color_lvl_2)",
  "lvl_-2": "var(--color_lvl_-2)",
  lvl_3: "var(--color_lvl_3)",
  "lvl_-3": "var(--color_lvl_-3)",
  lvl_4: "var(--color_lvl_4)",
  "lvl_-4": "var(--color_lvl_-4)",
  lvl_5: "var(--color_lvl_5)",
  "lvl_-5": "var(--color_lvl_-5)",
  primary_accent: "var(--color_primaryaccent)",
  secondary_accent: "var(--color_secondaryaccent)",
  bcg_accent_1: "var(--color_bcg_accent1)",
  bcg_accent_1_alt: "var(--color_bcg_accent1_alt)",
  bcg_accent_2: "var(--color_bcg_accent2)",
  bcg_accent_2_hover: "var(--color_bcg_accent2_hover)",
  bcg_accent_3: "var(--color_bcg_accent3)",
  live_error: "var(--color_live_error)",
  live_error_hover: "var(--color_live_error_hover)",
  slc_accent: "var(--color_slc_accent)",
  // variables below do not flip based on color mode:
  primary_accent_dark: "#1da1f1",
};

/**
 * Breakpoints by viewport (small, med, large).
 */
export const breakpoints = {
  svp: "640px",
  mvp: "1024px",
  lvp: "1280px",
};

const customMediaQuery = (maxWidth = "0px") => `@media (max-width: ${maxWidth})`;
/**
 * Quick max-width media queries for Small, Medium, & Large Viewports.
 * @example import { media } from '../style-utils'
 * const Div = styled.div`
 *   ${media.svp} {
 *    font-size: 20px;
 *   }
 * `
 */
export const media = {
  svp: customMediaQuery(breakpoints.svp),
  mvp: customMediaQuery(breakpoints.mvp),
  lvp: customMediaQuery(breakpoints.lvp),
  x: customMediaQuery,
  hover: `@media (hover: hover)`,
  motion: `@media (prefers-reduced-motion: no-preference)`, // Media Query for users with no "reduced-motion" settings enabled. It's helpful to add animation & motion-heavy styles within this query selector.
};

/**
 * Applies alpha to a given hex value.
 * @arg alpha default: 1
 * @example hexRGBA('#335596', 0.25); // rgb(51, 85, 150 / 0.25)
 * hexRGBA('#eee', 0.25); // rgb(238, 238, 238 / 0.25)
 */
export function hexRGBA(hex: string, alpha = 1): string {
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    console.error(`Bad Hex Value: hexRGBA(${hex})`);
    return hex;
  }

  let c;
  c = hex.substring(1).split("");
  if (c.length === 3) {
    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  }
  c = `0x${c.join("")}`;

  return `rgb(${[(+c >> 16) & 255, (+c >> 8) & 255, +c & 255].join(", ")} / ${alpha})`;
}

/**
 * Returns a foreground color that is accessible against a background color.
 * @arg bgHex - Background color hex value (6 digits, with or without `#`)
 * @returns A hexadecimal value for white (`#fff`) or black (`#000`)
 * corresponding to a dark or light background, respectively
 * @example accessibleFontColor('#d71920'); // '#fff'
 * @see [W3C Techniques For Accessibility Evaluation And Repair Tools](https://www.w3.org/TR/AERT/#color-contrast)
 */
export function accessibleFontColor(bgHex: string) {
  if (bgHex.length > 6) {
    bgHex = bgHex.slice(1);
  }

  const bgHexMatched = bgHex.match(/(.{1,2})/g);

  if (bgHexMatched !== null) {
    const rgbArr = bgHexMatched.map((hex) => parseInt(hex, 16));
    return (rgbArr[0] * 299 + rgbArr[1] * 587 + rgbArr[2] * 114) / 1000 < 128 ? "#fff" : "#000";
  } else {
    return "#fff";
  }
}

export function isDarkBackground(bgHex: string) {
  if (bgHex.length > 6) {
    bgHex = bgHex.slice(1);
  }
  const bgHexMatched = bgHex.match(/(.{1,2})/g);

  if (bgHexMatched !== null) {
    const rgbArr = bgHexMatched.map((hex) => parseInt(hex, 16));
    return (rgbArr[0] * 299 + rgbArr[1] * 587 + rgbArr[2] * 114) / 1000 < 128;
  } else {
    return null;
  }
}

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function rgbToHex(r: number, g: number, b: number) {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

export function luminance(r: number, g: number, b: number) {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function contrast(hexcolor1: string, hexcolor2: string) {
  const rgb1 = hexToRgb(hexcolor1);
  const rgb2 = hexToRgb(hexcolor2);
  if (rgb1 && rgb2) {
    const lum1 = luminance(rgb1.r, rgb1.g, rgb1.b);
    const lum2 = luminance(rgb2.r, rgb2.g, rgb2.b);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
  }
}

export function colorsContrastSufficiently(hexcolor1: string, hexcolor2: string, isText = false) {
  const contrastThreshold = isText ? 4.5 : 3;
  const contrastResult = contrast(hexcolor1, hexcolor2);
  if (!contrastResult) {
    throw new Error("One or more invalid color inputs to check contrast");
  }
  return contrastResult >= contrastThreshold;
}

/**
 * Changes RGB values to adjust brightness of a color by a given percent
 * @arg rgb [0-255, 0-255, 0-255]
 * @arg factor 1.2 = increase 20%, 0.8 = decrease in 20%
 * @example adjustBrightness([244, 244, 250], 0.5) -> [ 122, 122, 125 ]
 */
export function adjustBrightness(rgb: [number, number, number], factor: number) {
  return rgb.map((value: number) => Math.round(Math.min(255, Math.max(0, value * factor)))) as [number, number, number];
}
