import _set from "lodash.set";
import NextScript from "next/script";
import React, { useCallback, useMemo, useRef } from "react";
import settings from "../settings";
import useBundle from "./useBundle";
import useJavaScriptBridge from "./useJavaScriptBridge";
const {
  APP_ENV,
  ENV_NAMES: { PROD },
} = settings;

//* 📡 Flip to toggle all analytics debugging.
const loggingEnabled = false;

/**
 * Logs debug messages to the console.
 */
const logMsg =
  // eslint-disable-next-line
  loggingEnabled && APP_ENV !== PROD ? Function.prototype.bind.call(console.debug, console) : Function.prototype;

/**
 * Hook to trigger analytics events & utilize the analytics engine.
 * @example
 * const { updateDataLayer, pushEventData } = useAnalytics()
 */
export default function useAnalytics() {
  const queue: AnalyticsEvent[] = useMemo(() => [], []);
  const backoffMs = useRef(200);
  const timeoutId = useRef<NodeJS.Timeout | number | null>(null);
  const { fireAction: fireBridgeAction } = useJavaScriptBridge();
  const { data: megaBundle } = useBundle();

  const processQueue = useCallback(() => {
    if (typeof window !== "undefined" && window._satellite && window.mmlDataLayer) {
      timeoutId.current = null;

      while (queue.length) {
        const analyticsEvent = queue.shift();
        const hasConsented = window.WBD?.UserConsent?.inUserConsentState?.(["perf-general"], { id: "adobe-analytics" });
        if (hasConsented) {
          analyticsEvent();
        } else {
          logMsg(
            `📡 Analytics: user-consent cease-fire: adobe-analytics preference: ${window.WBD?.UserConsent?.inUserConsentState?.(
              ["perf-general"],
              { id: "adobe-analytics" },
            )}`,
          );
        }
      }
    } else {
      if (backoffMs.current < 5000) {
        // after timeout ms expand to 8000 giveup retrying to look for required config elements.
        logMsg(`📡 Analytics: debouncing ${backoffMs.current}ms | Checking for Analytics config…`);
        timeoutId.current = setTimeout(processQueue, backoffMs.current);
        backoffMs.current = Math.floor(backoffMs.current * 1.25);
      } else {
        const msg = `📡 Analytics Error: Unable to process analytics queue. Analytics config not found.`;
        console.error(msg);
      }
    }
  }, [queue]);

  const enqueue = useCallback(
    (event: AnalyticsEvent) => {
      queue.push(event);
      if (!timeoutId.current) {
        if (window.requestIdleCallback) {
          timeoutId.current = window.requestIdleCallback(processQueue);
        } else {
          timeoutId.current = window.setTimeout(processQueue, 0);
        }
      }
    },
    [processQueue, queue],
  );

  /**
   * Clears mmlDataLayer layer with passed values, with the addition of current user values.
   * This method returns a promise making it chainable if needed.
   * Optionally this method can push a page tracking event.
   */
  const updateDataLayer = useCallback<(mmlDataLayerValues: MMLDataLayer, pushPageEvent?: boolean) => Promise<void>>(
    (mmlDataLayerValues, pushPageEvent = true) =>
      new Promise((resolve) => {
        if (typeof window === "undefined") {
          return;
        }

        const newUser: MMLDataLayer["user"]["properties"] = {
          bcgPlayerStatus: "false",
          wbcgPlayerStatus: "false",
        };

        newUser.bcgPlayerStatus = `${Boolean(megaBundle?.mbcg_entries?.length)}`;
        newUser.wbcgPlayerStatus = `${Boolean(megaBundle?.wbcg_entries?.length)}`;

        if (megaBundle?.user?.mbcgPlayerStatus) {
          const playerType = [1, 3].includes(megaBundle.user.mbcgPlayerStatus) ? "new" : "returning";
          newUser.bcgPlayerType = playerType;

          if (!window.mmlDataLayer?.user?.properties?.bcgPlayerType) {
            // if setting playerStatus for the first time we also fire a bridge action.
            enqueue(() =>
              fireBridgeAction({
                action: "analytics_update_user",
                bcgPlayerType: playerType,
              }),
            );
          }
        }

        const newData = { ...mmlDataLayerValues };
        newData.user = { properties: { ...newUser } };

        const previousData = window.mmlDataLayer ?? {};
        const previousPage = window.mmlDataLayer?.page?.pageInfo?.mmlUrl || "";

        // guard against duplicate page calls
        if (previousData?.page?.pageInfo?.pageName === newData?.page?.pageInfo?.pageName) {
          resolve();
        }

        if (previousPage) {
          _set(newData, "page.pageInfo.mmlReferrer", previousPage);
        }

        if (loggingEnabled && APP_ENV !== PROD) console.groupCollapsed("📡 Analytics: Data Layer Updated");
        logMsg(`Previous Data:`, JSON.stringify(window.mmlDataLayer, null, 2));
        logMsg(`New Data:`, JSON.stringify(newData, null, 2));
        if (loggingEnabled && APP_ENV !== PROD) console.groupEnd();

        window.mmlDataLayer = newData;

        // Only push Launch page events when the page URL has not changed.
        if (pushPageEvent) {
          enqueue(() => {
            logMsg(`🚀 Analytics: Page Update Fired:\n${JSON.stringify(window.mmlDataLayer, null, 2)}`);
            document.body.dispatchEvent(new CustomEvent("mmlTrackPage"));
          });
        }

        // Always push DL updates over the JS bridge to the native apps.
        enqueue(() => {
          const bridgePaylod = {
            action: "analytics_track_screen",
            pageName: mmlDataLayerValues?.page?.pageInfo?.pageName || "",
            mmlUrl: mmlDataLayerValues?.page?.pageInfo?.mmlUrl || "",
            section: mmlDataLayerValues?.page?.pageInfo?.section || "",
            subsection: mmlDataLayerValues?.page?.pageInfo?.subsection || "",
          };
          fireBridgeAction(bridgePaylod);
        });

        resolve();
      }),
    [enqueue, fireBridgeAction, megaBundle],
  );

  /**
   * Adobe Launch Implementation:
   * Adds new event data to the current mmlDataLayer layer and pushes an AnalyticsEvent into the Queue.
   * @param {object} eventDataObj the dataLayer event object
   * @param {object} bridgeEvent optional object to push as an analytics bridge event
   */
  const pushEventData = useCallback(
    (eventObj?: MMLDataLayer["event"]["eventInfo"]) =>
      new Promise<void>((resolve) => {
        enqueue(() => {
          if (eventObj) {
            window.mmlDataLayer.event = { eventInfo: { ...eventObj } };
            logMsg(`🚀 Analytics: Event Fired:\n${JSON.stringify(window.mmlDataLayer.event, null, 2)}`);
            document.body.dispatchEvent(new CustomEvent("mmlTrackEvent"));

            // add a duplicate custom property "appEventAction" per the analytics team.
            if (eventObj.eventAction) {
              eventObj.appEventAction = eventObj.eventAction;
            }
            fireBridgeAction({ action: "analytics_track_event", ...eventObj });
          }
        });
        resolve();
      }),
    [fireBridgeAction, enqueue],
  );

  return { updateDataLayer, pushEventData };
}

export const AnalyticsScript = React.memo(() => (
  <NextScript
    id={`marchmadness-analytics-script-1`}
    key={`marchmadness-analytics-script-1`}
    src={`//lightning.ncaa.com/launch/7be62238e4c3/d27aaa336a89/launch-${
      APP_ENV === PROD ? "8355f7a8c0c6" : "be442b496cf3-staging"
    }.min.js`}
    strategy="afterInteractive"
    async={true}
    onLoad={() => {
      if (typeof window !== "undefined") {
        window.mmlDataLayer = window.mmlDataLayer || {};
      }
    }}
  />
));

type AnalyticsEvent = () => void;

declare global {
  interface Window {
    mmlDataLayer: MMLDataLayer;
    _satellite: object;
    trackMetrics(values: object): void;
  }
}

// Analytics Data Layer
interface MMLDataLayer {
  page?: {
    pageInfo?: {
      pageName?: string;
      mmlUrl?: string;
      subsection?: string;
      section?: string;
      mmlReferrer?: string;
      challengeGameName?: string;
    };
  };
  user?: {
    properties: {
      bcgPlayerStatus: "true" | "false";
      wbcgPlayerStatus: "true" | "false";
      bcgPlayerType?: "new" | "returning";
    };
  };
  event?: {
    eventInfo?: {
      eventName: string;
      eventAction: string;
      eventTarget: string;
      eventType: string;
      challengeGameName?: string;
      appEventAction?: string;
    };
  };
}
