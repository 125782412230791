export default function ModalClose() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.722994 0.734835C0.86944 0.588389 1.10688 0.588388 1.25332 0.734835L19.2534 18.7348C19.3998 18.8813 19.3998 19.1187 19.2534 19.2652C19.1069 19.4116 18.8695 19.4116 18.723 19.2652L0.722994 1.26517C0.576548 1.11872 0.576547 0.881282 0.722994 0.734835Z"
        fill="var(--color_lvl_-5)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2769 0.734835C19.1305 0.588389 18.893 0.588388 18.7466 0.734835L0.746548 18.7348C0.6001 18.8813 0.6001 19.1187 0.746546 19.2652C0.892992 19.4116 1.13043 19.4116 1.27688 19.2652L19.2769 1.26517C19.4234 1.11872 19.4234 0.881282 19.2769 0.734835Z"
        fill="var(--color_lvl_-5)"
      />
    </svg>
  );
}
