import { LibraryWrapper } from "../common/LibraryWrapper";
import { InGameNavigation } from "./InGameNavigation";
import { DesktopGameNavigation } from "./DesktopGameNavigation";
import MobileGameNavigation from "./MobileGameNavigation";
import { HeaderProps } from "./HeaderTypes";
import UtilityBar from "./UtilityBar";
import "./Header.css";

/**
 * Common header for all games.
 */
export const MMLHeader = ({
  activeGame,
  inGameNavItems,
  isIframe,
  isNative,
  mode,
  handleBlockedRedirect,
}: HeaderProps) => {
  const isIframeSession = typeof window !== "undefined" && sessionStorage.getItem("isIframe") !== null;
  return (
    <LibraryWrapper>
      {!isIframe && !isNative && (
        <>
          <UtilityBar />
          <DesktopGameNavigation
            activeGame={activeGame}
            isIframe={isIframe || isIframeSession}
            handleBlockedRedirect={handleBlockedRedirect}
          />
        </>
      )}
      <MobileGameNavigation
        activeGame={activeGame}
        isNative={isNative}
        mode={mode}
        isIframe={isIframe || isIframeSession}
        handleBlockedRedirect={handleBlockedRedirect}
      />
      <InGameNavigation
        mode={mode}
        activeGame={activeGame}
        inGameNavItems={inGameNavItems}
        isWomens={["wctp", "wbcg"].includes(activeGame)}
        handleBlockedRedirect={handleBlockedRedirect}
        isIframe={isIframe || isIframeSession}
      />
    </LibraryWrapper>
  );
};
