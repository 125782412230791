import { colors, pxToEm } from "./styleUtils";
import { styled, css } from "styled-components";

export const mmlCardStyles = css`
  background-color: ${colors.lvl_5};
  border-radius: 7px;
  border: solid 0.5px ${colors.lvl_2};
  padding: ${pxToEm(16)};
`;

export const MMLCard = styled.div`
  ${mmlCardStyles}
`;
