import { styled } from "styled-components";
import { pxToEm, colors, colorsContrastSufficiently } from "../../styles/styleUtils";

const StatBarWrapper = styled.div<{
  $percentageValue: number;
  $isDarkMode: boolean;
  $leftColor: string;
  $rightColor?: string;
}>`
  background-color: ${({ $rightColor }) => ($rightColor ? colors.lvl_5 : colors.lvl_2)};
  border-radius: 75cqh;
  column-gap: 4px;
  container-type: inline-size;
  display: flex;
  height: ${pxToEm(12)};

  > div {
    height: 100%;
    border-radius: 75cqh;
    &:first-child {
      background: ${({ $leftColor }) => $leftColor};
      border: 2px solid
        ${({ $isDarkMode, $leftColor }) => {
          if ($isDarkMode) {
            if (colorsContrastSufficiently($leftColor, "#17171d") === false) {
              return colors.lvl_2;
            }
          }
          return $leftColor;
        }};
      width: clamp(
        12px,
        calc(${({ $percentageValue }) => $percentageValue}cqi - ${({ $rightColor }) => ($rightColor ? 8 : 0)}px),
        100%
      );
    }

    &.right-bar {
      background: ${({ $rightColor }) => $rightColor};
      border: 2px solid
        ${({ $isDarkMode, $rightColor }) => {
          if ($rightColor) {
            if ($isDarkMode) {
              if (colorsContrastSufficiently($rightColor, "#17171d") === false) {
                return colors.lvl_2;
              }
            }
            return $rightColor;
          } else {
            return "transparent";
          }
        }};
      flex-grow: 1;
      margin-bottom: ${pxToEm(10)};
    }
  }
`;

const StatBar = styled.div<{
  $teamColor?: string;
}>`
  background: ${({ $teamColor }) => $teamColor};
`;

type StatBarsProps = {
  /** Team color to display on the left stat in this comparison. */
  leftColor: string;
  /** Team color to display on the left stat in this comparison. */
  rightColor?: string;
  /** Percentage of the total width that the left bar should occupy. */
  leftPercentageValue: number;
  /** Whether dark mode is active--determines whether to show a border to enhance contrast. */
  isDarkMode?: boolean;
};

/**
 * A pair of stat bars to compare two teams.
 * @param param0
 * @returns
 */
export const MMLStatsBars = ({
  leftColor = colors.lvl_5,
  rightColor,
  leftPercentageValue,
  isDarkMode = false,
}: StatBarsProps) => {
  return (
    <StatBarWrapper
      $isDarkMode={isDarkMode}
      $percentageValue={leftPercentageValue}
      $leftColor={leftColor}
      $rightColor={rightColor}
    >
      <StatBar></StatBar>
      {rightColor && <StatBar className="right-bar"></StatBar>}
    </StatBarWrapper>
  );
};
