import useApiFetch, { dynamicApiEndpoint } from "@hooks/useApiFetch";
import { useSearchParams } from "next/navigation";
import { useCallback } from "react";
import settings from "../../settings";
import { useQueryClient } from "@tanstack/react-query";
import useCookie from "@hooks/useCookie";
import useGlobalState, { initialState } from "@hooks/useGlobalState";
import { useRouter } from "next/router";
import useJavaScriptBridge from "@hooks/useJavaScriptBridge";

const { API_BACKEND_URL } = settings;

export default function useSignOut() {
  const { fetchApiEndpoint } = useApiFetch();
  const searchParams = useSearchParams().toString();
  const queryParams = searchParams.length ? `?${searchParams}` : "";
  const queryClient = useQueryClient();
  const { removeAllCookies } = useCookie();
  const [{ isNative, isIframe }, dispatch] = useGlobalState();
  const router = useRouter();
  const { fireAction } = useJavaScriptBridge();

  const signOut = useCallback(() => {
    return fetchApiEndpoint(`${API_BACKEND_URL}${dynamicApiEndpoint}/session/signout.json`, { method: "POST" }).then(
      () => {
        queryClient.clear();
        removeAllCookies();
        dispatch((state) => {
          state.dalton = initialState.dalton;
          state.apiAuthToken = "";
          state.staticEndpointKey = "";
        });
        if (isNative) {
          fireAction({ action: "signout" });
        } else if (isIframe) {
          fireAction({ action: "sign_out" });
        }
        router.push(`/${queryParams}`);
      },
    );
  }, [removeAllCookies, fetchApiEndpoint, router, dispatch, fireAction, isNative, isIframe, queryClient]);

  return { signOut };
}
