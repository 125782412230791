import Loading from "@components/Loading";
import ApplicationError from "@components/Modal/ApplicationError";
import {
  apiAuthTokenCookieName,
  daltonTokenCookieName,
  staticEndpointKeyCookieName,
  bcgNativeDeviceType,
} from "@hooks/useCookie";
import Cookies from "cookie"; // parser for server-side cookies.
import type { NextPage } from "next";
import NextApp, { AppContext, AppProps } from "next/app";
import Head from "next/head";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { ringside } from "@lib/fonts";
import settings from "../settings";
import { UnauthenticatedRedirectContext } from "../components/nextJsClientComponents/UnauthenticatedRequestProvider";
import { useRouter } from "next/router";
import { ColorStyles } from "@f2p-mml-frontends/mml-styles";
import { HydrationBoundary, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AdScript from "../components/Ads/AdScript";
import DarkModeProvider from "../components/DarkModeProvider";
import UpdatedTerms from "../components/Modal/UpdatedTerms";
import StyledComponentsProvider from "../components/StyledComponentsProvider";
import { AnalyticsScript } from "../hooks/useAnalytics";
import { GlobalStateProvider } from "../hooks/useGlobalState";
import GlobalStyles from "../styles/global.styles";
import PagesRouteEffects from "../hooks/PagesRouteEffects";
import { addThirdPartyVendors } from "../lib/vendors";
import { ADFUEL_REGISTRY_URL } from "@hooks/useAds";

const {
  API_BACKEND_URL,
  APP_ENV,
  ENV_NAMES: { DEV },
} = settings;

function queueAdfuelRegistry(windowCalled = false) {
  if (typeof window === "undefined" || !window.AdFuel || !window.AdFuel.queueRegistry) {
    console.log("[PLAY] AdFuel not ready yet");
    return;
  }
  if (windowCalled) {
    console.log("[PLAY] AdFuelCreated event fired");
  }
  try {
    window.AdFuel.queueRegistry(`${ADFUEL_REGISTRY_URL}?cachebuster=${Date.now()}`);
    console.log("[PLAY] queueAdfuelRegistry() called successfully");
  } catch (error) {
    console.error("[PLAY] Error queuing AdFuel registry:", error);
  }
}

function consoleLogAdFuelRequestComplete() {
  console.log("[PLAY] onAdFuelRequestComplete event fired");
}

// Metatag description
const description = "Join the official game of NCAA® March Madness®. May the best lineup win!";

function App({ Component, pageProps, err }: AppPropsWithLayout) {
  const [queryClient] = useState(() => new QueryClient());
  // ⬇️ To avoid Hydration Mismatch, many values on useTheme from next-themes will be undefined until mounted
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  // Initialize AdFuel. Note that this depends on <script> tags found in _document.tsx
  useEffect(() => {
    if (window.AdFuel) {
      queueAdfuelRegistry();
    }
    document.addEventListener("AdFuelCreated", () => {
      queueAdfuelRegistry(false);
    });
    document.addEventListener("onAdFuelRequestComplete", consoleLogAdFuelRequestComplete);
    addThirdPartyVendors();
    return () => {
      document.removeEventListener("AdFuelCreated", () => {
        queueAdfuelRegistry(false);
      });
      document.removeEventListener("onAdFuelRequestComplete", consoleLogAdFuelRequestComplete);
    };
  }, []);

  if (pageProps.isIframe && typeof window !== "undefined") {
    sessionStorage.setItem("isIframe", "true");
  }
  const router = useRouter();
  if (!mounted) return null;

  /**
   * getLayout is a custom page method to optionally use a layout component defined at the page level. Read more: https://nextjs.org/docs/basic-features/layouts#per-page-layouts - https://github.com/vercel/next.js/tree/canary/examples/layout-component
   */
  const getLayout = Component.getLayout ?? ((page) => page);

  const { daltonToken, authToken, isNative, isIframe, ...otherPageProps } = pageProps; // plucking values handled by globalState.

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" key="viewport" />
        <title>Play - NCAA® March Madness®</title>
        <link
          id="march-madness-favicon"
          key="march-madness-favicon"
          rel="shortcut icon"
          href={`/favicon${APP_ENV === DEV ? "-sharplink" : ""}.ico`}
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <GlobalStateProvider {...pageProps}>
            <Head>
              <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" key="viewport" />
              <title>Play - NCAA® March Madness®</title>
              <link
                id="march-madness-favicon"
                key="march-madness-favicon"
                rel="shortcut icon"
                href={`/favicon${APP_ENV === DEV ? "-sharplink" : ""}.ico`}
              />
              <meta property="og:image" key="og:image" content={`${API_BACKEND_URL}/assets/images/og.jpg`} />
              <meta name="description" key="description" content={description} />
              {/* <!-- Facebook Meta Tags --> */}
              <meta property="og:url" key="og:url" content={API_BACKEND_URL} />
              <meta property="og:type" key="og:type" content="website" />
              <meta property="og:title" key="og:title" content="Play - NCAA® March Madness®" />
              <meta property="og:description" key="og:description" content={description} />
              {/* <!-- Twitter Meta Tags --> */}
              <meta property="twitter:url" key="twitter:url" content={API_BACKEND_URL} />
              <meta property="twitter:domain" key="twitter:domain" content="play.ncaa.com" />
              <meta name="twitter:card" key="twitter:card" content="summary_large_image" />
              <meta name="twitter:image" key="twitter:image" content={`${API_BACKEND_URL}/assets/images/og.jpg`} />
              <meta name="twitter:title" key="twitter:title" content="Play - NCAA® March Madness®" />
              <meta name="twitter:description" key="twitter:description" content={description} />
            </Head>
            <GlobalStyles />
            <ColorStyles />
            <DarkModeProvider>
              <PagesRouteEffects />
              <StyledComponentsProvider>
                <UnauthenticatedRedirectContext.Provider
                  value={() => {
                    router.push("/");
                  }}
                >
                  {getLayout(
                    <>
                      <style jsx global>{`
                        html {
                          font-family: ${ringside.style.fontFamily}, -apple-system, BlinkMacSystemFont, "Segoe UI",
                            sans-serif;
                        }
                      `}</style>
                      <Component {...otherPageProps} err={err} />
                    </>,
                  )}
                  <Loading />
                  <ApplicationError />
                </UnauthenticatedRedirectContext.Provider>
              </StyledComponentsProvider>
            </DarkModeProvider>
            <AnalyticsScript />
            <AdScript />
            <UpdatedTerms />
          </GlobalStateProvider>
        </HydrationBoundary>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

/**
 * We use getInitialProps to capture global query params before landing on page routes & before rendering the DOM.
 * This is to avoid flashing and possible layout shifts based on the experience type, in contrast to using next/router.
 *
 * Adding getInitialProps at the App component level opts us out of SSG (unless getStaticProps is explicitly used on child pages).
 * The app is now SSR/CSR by default 🔥 (Server Side Rendered or Client-Side Rendered)
 *
 * Example— to test query param detection, visit: http://play-development.ncaa.com:8000/?iframe
 */
App.getInitialProps = async (appContext: AppContext) => {
  const isIframe = appContext.router.query.iframe !== undefined;

  const req = appContext.ctx.req;

  let authToken = "";
  let daltonToken = "";
  let staticEndpointKey = "";
  let isNative;
  let nativeDeviceType;

  // only perform cookie operations when gIP is run on the server. This check prevents Client-Side Rendered pages from erroring out here.
  if (req?.headers?.cookie) {
    const cookies = Cookies.parse(req.headers.cookie);
    authToken = cookies[apiAuthTokenCookieName] ?? "";
    daltonToken = cookies[daltonTokenCookieName] ?? "";
    staticEndpointKey = cookies[staticEndpointKeyCookieName] ?? "";
    isNative = Boolean(cookies[bcgNativeDeviceType]);
    nativeDeviceType = cookies[bcgNativeDeviceType];
  }

  // call Page's `getInitialProps` and add to `appProps.pageProps`
  const appProps = await NextApp.getInitialProps(appContext);

  if (appProps?.pageProps) {
    appProps.pageProps.isIframe = isIframe;
    appProps.pageProps.isNative = isNative;
    appProps.pageProps.nativeDeviceType = nativeDeviceType;
    appProps.pageProps.authToken = authToken;
    appProps.pageProps.daltonToken = daltonToken;
    appProps.pageProps.staticEndpointKey = staticEndpointKey;
  }

  return { ...appProps };
};

export default App;

type GetLayout = (page: ReactElement) => ReactNode;
type NextPageWithLayout = NextPage & {
  getLayout?: GetLayout;
  err?: Error;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  pageProps: any;
  err: Error;
};
