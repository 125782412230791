import { styled } from "styled-components";
import { conditionallyRoundNumber } from "../../lib/misc";
import { mmlCardStyles } from "../../styles/MMLCard.styled";
import { colors } from "../../styles/styleUtils";
import { MMLGameTypeChip } from "../foundations/GameTypeChip";
import VerifiedBadge from "../../assets/icons/PlayVerified";
import { GroupData } from "../../types/gameData.types";
import { pxToEm } from "../../styles/styleUtils";

const GroupLink = styled.a`
  ${mmlCardStyles}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 97px;
  line-height: 1.2;
  padding: 1.11em;
  text-decoration: none;
  transition:
    border 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  &:hover,
  &:focus {
    background-color: ${colors.lvl_3};
    border: 1px solid ${colors["lvl_-5"]};
  }
`;

const GroupTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GroupTitle = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1em;

  h3 {
    color: ${colors["lvl_-1"]};
    font-size: 9px;
    font-weight: 325;
    margin-bottom: 2px;
  }

  h2 {
    font-size: 12px;
    font-weight: 325;
    letter-spacing: 0.4px;
    word-break: break-all;
  }
  div {
    align-items: center;
    display: flex;
    column-gap: 0.1em;
  }
  svg {
    padding-top: 0.2em;
  }
`;

const GroupStatsRow = styled.div`
  align-items: baseline;
  column-gap: 0.5em;
  display: flex;
  justify-content: space-between;
`;

const GroupStatsItem = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: column;

  &:last-child {
    align-items: flex-end;
  }

  h4 {
    color: ${colors["lvl_-1"]};
    font-size: 9px;
    font-weight: 325;
  }

  h3 {
    font-size: 12px;
    font-weight: 325;
    letter-spacing: 0.4px;
  }
`;

type EntryGroupProps = {
  groupData: GroupData;
  basePath: string;
  tabIndex?: number;
  isCommissioner: boolean;
  id?: string;
};

export const MMLGroupCard = ({ groupData, basePath, tabIndex = 0, id, isCommissioner }: EntryGroupProps) => {
  const queryParams = new URLSearchParams(window.location.search);

  return (
    <GroupLink
      href={`${basePath}/group/${groupData.id}${queryParams.size > 0 ? `?${queryParams}` : ""}`}
      tabIndex={tabIndex}
      id={id}
    >
      <GroupTitleRow>
        <GroupTitle>
          <h3>GROUP{isCommissioner && " | COMMISSIONER"}</h3>
          <div>
            <h2>{groupData.name}</h2>
            {groupData.verifiedType !== "not_verified" && <VerifiedBadge />}
          </div>
        </GroupTitle>
        <div>{groupData.gameType !== undefined && <MMLGameTypeChip gameType={groupData.gameType} />}</div>
      </GroupTitleRow>
      <GroupStatsRow>
        <GroupStatsItem>
          <h4>MEMBERS</h4>
          <h3>{conditionallyRoundNumber(groupData.size)}</h3>
        </GroupStatsItem>
        <GroupStatsItem>
          <h4>RANK</h4>
          <h3>{conditionallyRoundNumber(groupData.rank) || "-"}</h3>
        </GroupStatsItem>
      </GroupStatsRow>
    </GroupLink>
  );
};
