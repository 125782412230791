import * as Collapsible from "@radix-ui/react-collapsible";
import BarAccountOff from "../../assets/icons/BarAccountOff";
import Caret from "../../assets/icons/Caret";
import { HeaderProps } from "./HeaderTypes";
import MBCGHorizontalLight from "../../assets/icons/game-logos/MBCGHorizontalLight";
import MBCGHorizontalDark from "../../assets/icons/game-logos/MBCGHorizontalDark";
import WBCGHorizontalLight from "../../assets/icons/game-logos/WBCGHorizontalLight";
import WBCGHorizontalDark from "../../assets/icons/game-logos/WBCGHorizontalDark";
import { useState } from "react";
import { gameNavItems } from "./HeaderConsts";
import styles from "./MobileGameNavigation.module.css";

type NavigationProps = Pick<HeaderProps, "activeGame" | "isNative" | "mode" | "isIframe" | "handleBlockedRedirect">;

export default function MobileGameNavigation({
  activeGame,
  handleBlockedRedirect,
  isNative,
  mode,
  isIframe,
}: NavigationProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hideBackgroundTrigger, setHideBackgroundTrigger] = useState(true);
  // Womens games will only show on desktop and mobile web
  const includeWomensNavItems = !isNative && !isIframe;
  const gameListInfo = [
    {
      abbrv: "mbcg",
      url: `/mens-bracket-challenge`,
      svg: mode === "light" ? MBCGHorizontalLight : MBCGHorizontalDark,
    },
    {
      abbrv: "wbcg",
      url: `/womens-bracket-challenge`,
      svg: mode === "light" ? WBCGHorizontalLight : WBCGHorizontalDark,
    },
  ];

  function renderGameLogo(activeGame: string) {
    switch (activeGame) {
      case "mbcg":
        if (mode === "light") return <MBCGHorizontalLight className={styles["game-trigger-svg"]} />;
        else return <MBCGHorizontalDark className={styles["game-trigger-svg"]} />;
      case "wbcg":
        if (mode === "light") return <WBCGHorizontalLight className={styles["game-trigger-svg"]} />;
        else return <WBCGHorizontalDark className={styles["game-trigger-svg"]} />;
      default:
        return <MBCGHorizontalLight className={styles["game-trigger-svg"]} />;
    }
  }

  function handleOpenChange(isOpen: boolean) {
    document.body.style.overflowY = isOpen ? "hidden" : "visible";
    if (!isOpen) {
      // Delay giving the "hide" classname to allow fadeOut animation to play
      setTimeout(() => {
        setHideBackgroundTrigger(true);
        setIsOpen(false);
      }, 300);
    } else {
      setIsOpen(true);
      setHideBackgroundTrigger(false);
    }
  }

  // Close collapsible, fade out modal, and rogrammatically route to account settings.
  function handleSettingsClick() {
    handleOpenChange(false);
    setIsOpen(false);
    if (handleBlockedRedirect) {
      handleBlockedRedirect(`/settings${isIframe ? "?iframe=" : ""}`);
    }
  }

  // Close collapsible, fade out modal, and programmatically navigate to game
  function handleGameClick(abbreviation: string) {
    const game = gameNavItems.find((item) => item.abbreviation === abbreviation);
    handleOpenChange(false);
    setIsOpen(false);
    if (handleBlockedRedirect && game) {
      handleBlockedRedirect(game.href);
    }
  }

  return (
    <div className={`${styles.container} ${isNative ? styles["is-native"] : ""}`}>
      <Collapsible.Root className={styles["menu-root"]} open={isOpen} onOpenChange={handleOpenChange}>
        <div className={styles["grid-contents"]}>
          {handleBlockedRedirect ? (
            <button className={styles["account-settings"]} aria-label="Account Settings" onClick={handleSettingsClick}>
              <BarAccountOff className={styles["account-settings-svg"]} />
            </button>
          ) : (
            <a
              className={styles["account-settings"]}
              aria-label="Account Settings"
              href={`/settings${isIframe ? "?iframe=" : ""}`}
            >
              <BarAccountOff className={styles["account-settings-svg"]} />
            </a>
          )}
          <div className={styles["game-trigger"]}>{renderGameLogo(activeGame)}</div>
          <Collapsible.Trigger className={styles["caret-trigger"]}>
            <Caret className={styles["caret-svg"]} />
          </Collapsible.Trigger>
        </div>
        <Collapsible.Content className={styles.collapsible}>
          {gameListInfo.map((game) =>
            activeGame === game.abbrv || (game.abbrv[0] === "w" && !includeWomensNavItems) ? null : (
              <div className={styles["game-row"]} key={`game-row-${game.abbrv}`}>
                {handleBlockedRedirect ? (
                  <button className={styles["game-image-wrapper"]} onClick={() => handleGameClick(game.abbrv)}>
                    <game.svg className={styles["game-image-svg"]} />
                  </button>
                ) : (
                  <a
                    className={styles["game-image-wrapper"]}
                    href={`${game.url}/dashboard${isIframe ? "?iframe=" : ""}`}
                  >
                    <game.svg className={styles["game-image-svg"]} />
                  </a>
                )}
              </div>
            ),
          )}
          <div className={styles["game-row"]}>
            {handleBlockedRedirect ? (
              <button
                className={styles["return-to-play-home"]}
                aria-label="Account Settings"
                onClick={() => {
                  handleBlockedRedirect(`/dashboard${isIframe ? "?iframe=" : ""}`);
                }}
              >
                Return to Play Home
              </button>
            ) : (
              <a
                className={styles["return-to-play-home"]}
                aria-label="Account Settings"
                href={`/dashboard${isIframe ? "?iframe=" : ""}`}
              >
                Return To Play Home
              </a>
            )}
          </div>
        </Collapsible.Content>
        <Collapsible.Trigger
          className={`${styles["background-trigger"]} ${hideBackgroundTrigger ? styles.hide : styles.show}`}
        />
      </Collapsible.Root>
    </div>
  );
}
