export type ImageType = "team" | "conference" | "mascot" | "broadcaster";

export function getGameTeamImage(teamName: string, background: "bgd" | "bgl") {
  return `https://i.turner.ncaa.com/sites/default/files/cssu/mml/2024/teams/${background}/${teamName}.png`;
}

export function getConferenceImage(conferenceName: string, background: "bgd" | "bgl") {
  return `https://i.turner.ncaa.com/sites/default/files/cssu/mml/2024/conferences/${background}/${conferenceName}.png`;
}

export function getBroadcasterImage(broadcaster: string, background: "bgd" | "bgl") {
  let casedBroadcaster = broadcaster.toUpperCase();
  // truTV is the only scenario that is camelCase, adjust acordingly...
  if (casedBroadcaster.includes("TRU")) casedBroadcaster = "truTV";
  return `https://i.turner.ncaa.com/sites/default/files/cssu/mml/2024/networks/${background}/${casedBroadcaster}.png`;
}

export function getMascotImage(teamName: string, background: "bgd" | "bgl") {
  return `https://i.turner.ncaa.com/sites/default/files/cssu/mml/2024/mascots/${background}/${teamName}.png`;
}

export function getImageByType(linkString: string, background: "bgl" | "bgd", imageType: ImageType = "team") {
  if (imageType === "mascot") {
    return getMascotImage(linkString, background);
  }
  if (imageType === "conference") {
    return getConferenceImage(linkString, background);
  }
  if (imageType === "broadcaster") {
    return getConferenceImage(linkString, background);
  }
  if (imageType === "team") {
    return getGameTeamImage(linkString, background);
  }
  throw new Error("Invalid imageType parameter passed to getImageByType function.");
}
