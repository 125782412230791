import { breakpoints } from "@styles/styleUtils";
import fetch from "cross-fetch";
import { useCallback, useRef } from "react";
import { debug } from "../pages/debug";
import settings from "../settings";

const {
  APP_ENV,
  ENV_NAMES: { PROD },
} = settings;

export const ADFUEL_REGISTRY_URL = "https://i.cdn.turner.com/ads/mml/mml_starting_lineup.json";

const loggingEnabled = false;

export enum ADFUEL_IDS {
  "TOP_AD" = "ad_bnr_atf_01", // Responsive: 960x60, 468x60, 300x50
  "COLUMN" = "ad_rect_atf_01", // 300x250
  "SEPARATOR" = "ad_bnr_atf_02", // 468x60
}

type DoubleClickPixelIds =
  | "landingpage"
  | "persistentheader"
  | "presentedbylogo"
  | "leaderboardseeall"
  | "leaderboardheader"
  | "skins"
  | "onboarding"
  | "signup"
  | "login"
  | "dashboard";

enum TwitterPixelIds {
  "TWITTER_CREATE_USER" = "nwbu1",
  "TWITTER_PAGE" = "l5j0p",
  "TWITTER_ENTRY_COMPLETED" = "nu2sb",
}

type PixelIds = DoubleClickPixelIds | keyof typeof TwitterPixelIds;

/**
 * Hook to fire page-level adFuel render calls and fire individual pixel calls.
 * @example
 * const {renderAds, firePixel} = useAds()
 * // 1:
 * useEffect(() => { renderAds() }, [renderAds])
 * // 2:
 * <button onClick={() => firePixel('login')} />
 */
export default function useAds() {
  const backoffMs = useRef(200);
  const timeoutId = useRef<NodeJS.Timeout | number | null>(null);

  const renderAds = () => {
    console.log("Attempting to render ads...");
    console.log("window.AdFuel status:", window.AdFuel);

    if (typeof window === "undefined") {
      console.log("Window is not defined yet");
      return;
    }

    if (!window.AdFuel) {
      console.log("AdFuel not loaded yet, retrying...");
      if (backoffMs.current < 5000) {
        timeoutId.current = setTimeout(renderAds, backoffMs.current);
        backoffMs.current = Math.floor(backoffMs.current * 1.25);
      } else {
        console.error("AdFuel error: unable to render display ads after maximum retries.");
      }
      return;
    }

    try {
      timeoutId.current = null;

      if (APP_ENV !== PROD) {
        console.log("Setting page level target for non-PROD environment");
        if (typeof window.AdFuel.addPageLevelTarget === "function") {
          window.AdFuel.addPageLevelTarget("status", "bcg_test");
        } else {
          console.error("AdFuel.addPageLevelTarget is not a function");
        }
      }

      if (typeof window.AdFuel.refresh === "function") {
        window.AdFuel.refresh();
        console.log("AdFuel refresh completed");
      } else {
        console.error("AdFuel.refresh is not a function");
      }
    } catch (error) {
      console.error("Error in renderAds:", error);
    }
  };
  /**
   * Fire pixel API requests to register pixel events. If a Twitter Pixel id is used it adds a pixel element to the DOM.
   */
  const firePixel = useCallback((pixelId: PixelIds, game: "bcg" | "wbcg" | null) => {
    // Push html pixel elements to the AdScript PixelContainer.
    if (TwitterPixelIds[pixelId]) {
      const pxContainer = document.querySelector("#px-container");
      if (!pxContainer) {
        return debug("Warning: pixel container not found.", pixelId);
      }

      const pxId = TwitterPixelIds[pixelId];
      const socialVendorId: { [key in keyof typeof TwitterPixelIds]: string } = {
        TWITTER_PAGE: `social-vendor-onPageChange-${game}`,
        TWITTER_CREATE_USER: `social-vendor-onSTIUserCreation-bcg_wbcg_slc`,
        TWITTER_ENTRY_COMPLETED: `social-vendor-onFirstBracketCompleted-${game}`,
      };
      const imageSources = [
        `//analytics.twitter.com/i/adsct?txn_id=${pxId}&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0`,
        `//t.co/i/adsct?txn_id=${pxId}&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0`,
      ];

      if (loggingEnabled) debug(`Pixels: Image pixel id: ${pxId}`);

      if (
        window.WBD?.UserConsent?.inUserConsentState?.(["social-vendor"], {
          id: socialVendorId[pixelId] || `social-vendor-${game}`,
        })
      ) {
        return imageSources.forEach((src) => {
          const image = new Image(1, 1);
          image.src = src;
          image.style.cssText = "display:none;";
          image.alt = "";
          pxContainer.appendChild(image);
        });
      }
    }

    const now = Date.now().toString();

    // Set [RDP] to 0 if user declines Do Not Sell or 1 if user enables Do Not Sell (CCPA requirement)
    const RDP = window.WBD?.UserConsent?.inUserConsentState?.(["ads-vendor"], { id: "1x1-ad-pixels" }) ? 0 : 1;
    const statusDebug = (APP_ENV !== PROD && "&status=bcg_test") || "";
    const pos = encodeURIComponent(`pos=ns_atf_07&placement=${pixelId}${statusDebug}`);

    const sport = game === "wbcg" ? "w_basketball" : "m_basketball";
    const requestUrl = `https://securepubads.g.doubleclick.net/gampad/adx?iu=/8663477/NCAA/sports/${sport}/mml_bcg/web&rdp=${RDP}&sz=1x1&c=${now}&t=${pos}&mob=js`;

    interface AdResponse {
      thirdPartyTrackers: string[];
      defaultImpression: string;
    }

    if (loggingEnabled) debug(`\n\nPixels: firePixel(${pixelId})`);
    if (loggingEnabled) debug("Pixels: fetching requestUrl", requestUrl);

    fetch(requestUrl)
      .then((res) => res.json())
      .then((result?: AdResponse) => {
        if (loggingEnabled) debug("Pixels: response", result);

        if (result?.defaultImpression) {
          if (loggingEnabled) debug("Pixels: fetching", result.defaultImpression);
          fetch(result.defaultImpression).catch((e) =>
            debug("Warning: Bad request from Ad Pixel Response", result.defaultImpression, e),
          );
        }
        const urlsToRequest = result?.thirdPartyTrackers || [];
        urlsToRequest.forEach((url) => {
          if (loggingEnabled) debug("Pixels: fetching", url);
          if (url) fetch(url).catch((e) => debug("Warning: Bad request from Ad Pixel Response", url, e));
        });
      })
      .catch((e) => debug("Warning: Bad request from Ad Pixels", requestUrl, e));
  }, []);

  return {
    renderAds,
    firePixel,
  };
}

declare global {
  interface Window {
    AdFuel?: {
      /**
       * Update the AdFuel registry target given a key and value.
       */
      addPageLevelTarget: (key: string, value: string) => void;

      /**
       * Load the AdFuel registry and render ad ids for the first time.
       */
      queueRegistry: (registryUrl: string) => void;

      /**
       * Trigger a reload of all visible ads on the page.
       */
      refresh: () => void;
    };
  }
}
