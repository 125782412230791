import * as React from "react";

export default function InfoOff() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M9 7.25C9.13807 7.25 9.25 7.36193 9.25 7.5V13.25C9.25 13.3881 9.13807 13.5 9 13.5C8.86193 13.5 8.75 13.3881 8.75 13.25V7.5C8.75 7.36193 8.86193 7.25 9 7.25Z"
        fill="currentColor"
      />
      <path
        d="M9.41667 5.41667C9.41667 5.64679 9.23012 5.83333 9 5.83333C8.76988 5.83333 8.58333 5.64679 8.58333 5.41667C8.58333 5.18655 8.76988 5 9 5C9.23012 5 9.41667 5.18655 9.41667 5.41667Z"
        fill="currentColor"
      />
    </svg>
  );
}
