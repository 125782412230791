import { css, styled } from "styled-components";
import { Button } from "../../styles/buttons.styled";
import { MMLCard } from "../../styles/MMLCard.styled";
import { colors, pxToEm, media } from "../../styles/styleUtils";
import { MMLButton } from "../../styles";

export const EntryCardContainer = styled(MMLCard)`
  container-name: entrycontainer;
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  row-gap: 1.7em;
`;

export const IncompleteContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
`;

export const IncompleteTag = styled.span`
  background-color: ${colors.bcg_accent_2};
  color: ${colors.lvl_5};
  font-size: ${pxToEm(9)};
  font-weight: 325;
  margin-right: 10px;
  padding: 2px 5px;
  text-transform: uppercase;
  text-align: center;
  width: 91px;
`;

export const EntryHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
`;

export const EntryTitleWrapper = styled.div`
  min-height: 60px;
  align-items: center;
  display: flex;
  column-gap: 0.75em;
`;

const entryNameStyles = css`
  display: inline;
  cursor: pointer;
  font-weight: 600;
  font-style: normal;
  font-size: 4em;
  letter-spacing: -0.5px;
  line-height: 1;
  margin-right: 8px;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out;

  ${media.svp} {
    font-size: ${pxToEm(28)};
  }

  &:hover,
  &:focus {
    color: ${colors["lvl_-2"]};
  }
`;

export const EntryLink = styled.a`
  ${entryNameStyles};
`;

export const EntryName = styled.p`
  ${entryNameStyles};
`;

export const EntryTitle = styled.span`
  word-break: break-word;
  position: relative;
  text-decoration: none;
`;

export const GameTypeChipWrapper = styled.span`
  position: absolute;
  transform: translate(20%, 105%);

  ${media.svp} {
    transform: translate(20%, 55%);
  }
`;

export const EntryInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 10em;
  margin-left: 10px;
`;

export const InfoButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const ProgressContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`;

export const ProgressText = styled.span`
  font-size: 0.8em;
  font-weight: 325;
`;

export const ProgressWrapper = styled.div`
  height: ${pxToEm(4)};
  width: 100%;
`;

export const ActionsRow = styled.div<{ $actionsLength: number }>`
  column-gap: 0.6em;
  display: grid;
  grid-template-columns: ${({ $actionsLength }) => ($actionsLength === 1 ? "1fr" : `repeat(${$actionsLength}, 1fr)`)};
  justify-content: space-evenly;
  width: 100%;

  ${media.svp} {
    row-gap: 12px;
    grid-template-columns: 1fr;
  }
`;

export const ActionRowItem = styled.div`
  flex-grow: 1;
`;

export const EntryButton = styled(MMLButton)`
  width: 100%;
  font-size: 12px;

  ${media.svp} {
    font-size: 10px;
  }
`;

export const EntryGroupsExpansionContainer = styled.div<{ $groupListMaxHeight?: number }>`
  transition: max-height 0.2s ease-in-out;
  // 12px for row-gap, 97px for the Group Card, and * 2 because we want to show 2 cards
  max-height: calc(12px + 97px * 2);
  overflow: hidden;
  &.is-expanded {
    max-height: ${({ $groupListMaxHeight }) => $groupListMaxHeight}px;
  }
`;

export const EntryGroupsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 12px;
  column-gap: 8px;
`;

export const SeeMoreGroupsContainer = styled.div`
  display: grid;
  place-content: center;
  width: 100%;
`;

export const SeeMoreGroupsButton = styled(Button)`
  border: none;
  grid-area: 1 / 1;
  svg {
    transform: scale(75%);
    animation-play-state: paused;
  }
  &.animate svg {
    animation: rotateOut 0.2s linear forwards;
    animation-play-state: running;
  }
  &.is-expanded svg {
    animation-play-state: paused;
  }
  &.is-expanded.animate svg {
    animation-name: rotateIn;
    animation-play-state: running;
  }
  @keyframes rotateIn {
    from {
      transform: rotate(0deg) scale(75%);
    }
    to {
      transform: rotate(180deg) scale(75%);
    }
  }
  @keyframes rotateOut {
    from {
      transform: rotate(180deg) scale(75%);
    }
    to {
      transform: rotate(360deg) scale(75%);
    }
  }
`;
