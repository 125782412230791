import { SVGProps, forwardRef } from "react";

const Caret = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  const { className, ...rest } = props;
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      className={className}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.10544 10.6071C5.96485 10.7498 5.96485 10.9812 6.10544 11.124L13.5818 18.7139C13.8349 18.9708 14.2451 18.9708 14.4982 18.7139L21.9746 11.124C22.1151 10.9812 22.1151 10.7498 21.9746 10.6071C21.834 10.4644 21.606 10.4644 21.4654 10.6071L14.04 18.1454L6.61456 10.6071C6.47397 10.4644 6.24603 10.4644 6.10544 10.6071Z"
        fill="currentColor"
      />
    </svg>
  );
});

Caret.displayName = "Caret";
export default Caret;
