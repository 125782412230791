export const rootFontSize = 16;
/**
 * Converts pixels to a rem value.
 * @example pxToRem(32) // '2rem'
 * pxToRem('32') // '2rem'
 * pxToRem('32px') // '2rem'
 */
export const pxToRem = (px: number | string): string =>
  `${(Number(px.toString().replace("px", "")) / rootFontSize).toFixed(2)}rem`;

export const maxPageWidth = "1074px";

export const colors = {
  lvl_0: "var(--color_lvl_0)",
  lvl_1: "var(--color_lvl_1)",
  "lvl_-1": "var(--color_lvl_-1)",
  lvl_2: "var(--color_lvl_2)",
  "lvl_-2": "var(--color_lvl_-2)",
  lvl_3: "var(--color_lvl_3)",
  "lvl_-3": "var(--color_lvl_-3)",
  lvl_4: "var(--color_lvl_4)",
  "lvl_-4": "var(--color_lvl_-4)",
  lvl_5: "var(--color_lvl_5)",
  "lvl_-5": "var(--color_lvl_-5)",
  primary_accent: "var(--color_primaryaccent)",
  secondary_accent: "var(--color_secondaryaccent)",
  bcg_accent_1: "var(--color_bcg_accent1)",
  bcg_accent_1_alt: "var(--color_bcg_accent1_alt)",
  bcg_navy: "var( --color_bcg_navy)",
  bcg_accent_2: "var(--color_bcg_accent2)",
  bcg_accent_2_hover: "var(--color_bcg_accent2_hover)",
  bcg_accent_3: "var(--color_bcg_accent3)",
  live_error: "var(--color_live_error)",
  live_error_hover: "var(--color_live_error_hover)",
  slc_accent: "var(--color_slc_accent)",
  // variables below do not flip based on color mode:
  primary_accent_dark: "#1da1f1",
  black: "#17171d",
  white: "#fdfdfd",
};

/**
 * Breakpoints by viewport (small, med, large).
 */
export const breakpoints = {
  svp: "640px",
  singleColumn: "810px", // arbitrary breakpoint added by Turner for reflowing layouts to a single column
  mvp: "1024px",
  lvp: "1280px",
};

const customMediaQuery = (maxWidth = "0px") => `@media (max-width: ${maxWidth})`;
/**
 * Quick max-width media queries for Small, Medium, & Large Viewports.
 * @example import { media } from '../style-utils'
 * const Div = styled.div`
 *   ${media.svp} {
 *    font-size: 20px;
 *   }
 * `
 */
export const media = {
  svp: customMediaQuery(breakpoints.svp),
  singleColumn: customMediaQuery(breakpoints.singleColumn),
  mvp: customMediaQuery(breakpoints.mvp),
  lvp: customMediaQuery(breakpoints.lvp),
  x: customMediaQuery,
  hover: `@media (hover: hover)`,
  motion: `@media (prefers-reduced-motion: no-preference)`, // Media Query for users with no "reduced-motion" settings enabled. It's helpful to add animation & motion-heavy styles within this query selector.
};

/**
 * Applies alpha to a given hex value.
 * @arg alpha default: 1
 * @example hexRGBA('#335596', 0.25); // rgba(51, 85, 150, 0.25)
 * hexRGBA('#eee', 0.25); // rgba(238, 238, 238, 0.25)
 */
export function hexRGBA(hex: string, alpha = 1): string {
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    console.error(`Bad Hex Value: hexRGBA(${hex})`);
    return hex;
  }

  let c;
  c = hex.substring(1).split("");
  if (c.length === 3) {
    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  }
  c = `0x${c.join("")}`;

  return `rgba(${[(+c >> 16) & 255, (+c >> 8) & 255, +c & 255].join(", ")}, ${alpha})`;
}

/** Converts a hexadecimal value to an array of rgb values.
 * @param {string} hexVal - The hexadecimal value to convert.
 * @returns `number[]` array of rgb values.
 */
function hexToRgb(hexVal: string) {
  if (hexVal.length > 6) {
    hexVal = hexVal.slice(1);
  }
  if (hexVal.match(/(.{1,2})/g)?.length === 3) {
    return hexVal.match(/(.{1,2})/g)?.map((hex) => parseInt(hex, 16));
  }
}

/** Determines if a background color is dark or light.
 * @param {string} bgHex - The hexadecimal value of the background color.
 * @returns `boolean` true if dark, false if light.
 * @example isDarkBackground('#000') // true
 */
export function isDarkBackground(bgHex: string) {
  const rgbArr = hexToRgb(bgHex);
  if (rgbArr) return (rgbArr[0] * 299 + rgbArr[1] * 587 + rgbArr[2] * 114) / 1000 < 128;
}

/**
 * Returns a font color that is accessible on a given background color
 * @param bgHex - The hexadecimal value of the background color.
 * @returns `string` hex value of the font color.
 */
export function accessibleFontColor(bgHex: string) {
  return isDarkBackground(bgHex) ? "#fff" : "#000";
}

/** Calculates the luminance of a color.
 * @param {number[]} rgbArray - An array of rgb values.
 * @returns `number` luminance value.
 */
function luminance(rgbArray: number[]): number {
  const a = rgbArray.map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

/** Gets the contrast ratio between two colors.
 * Adapted from WCAG 2.0 contrast ratio calculation.
 * @param hex1 - The first hex value to compare. Defaults to `colors["lvl_-5"]` (default foreground).
 * @param hex2 - The second hex value. Defaults to `colors["lvl_5"]` (default background).
 * @returns `number` contrast ratio.
 * @example getContrastRatio('#000000', '#ffff00') // 1.074
 * @see https://www.w3.org/TR/WCAG20/#contrast-ratiodef
 * @see https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
 */
export function getContrastRatio(hex1: string = colors["lvl_-5"], hex2: string = colors["lvl_5"]): number {
  const lum1 = luminance(hexToRgb(hex1) as number[]);
  const lum2 = luminance(hexToRgb(hex2) as number[]);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}
/** Checks if the contrast ratio between two colors is accessible.
 * Adapted from WCAG 2.0 contrast ratio calculation.
 * @param {string} hex1 - The first hex value to compare. Defaults to `colors["lvl_-5"]` (default foreground).
 * @param {string} hex2 - The second hex value. Defaults to `colors["lvl_5"]` (default background).
 * @param {number} target - The target contrast ratio. Defaults to 4.5. Should be 3 for large text.
 * @returns `boolean` whether the contrast between provided colors meets or exceeds the target.
 * @example isContrastAccessible('#000000', '#ffff00') // false
 * @see https://www.w3.org/TR/WCAG20/#contrast-ratiodef
 * @see https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
 */
export function isContrastAccessible(hex1: string, hex2: string, target = 4.5): boolean {
  return getContrastRatio(hex1, hex2) >= target;
}
