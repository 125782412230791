import { SVGProps, forwardRef } from "react";

const PlayLogo = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  const { className, ...rest } = props;
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      height="27"
      viewBox="0 0 75 27"
      fill="none"
      className={className}
      {...rest}
    >
      <path
        d="M65.5985 19.8815L66.2465 17.5158L75 2.56863L72.0032 0.00534019V0H71.9098H3.38927L9.47001 5.21737L6.68508 18.8028L0 24.5328H0.23675L0.124605 24.5756L2.95315 27H73.7851L65.5985 19.8815Z"
        fill="#009ADE"
      />
      <path
        d="M62.1139 19.375L62.5562 17.3311L71.196 2.03385L70.0872 1.08643L68.9784 2.03385H7.57754L11.994 5.80228L9.02893 20.2266L6.25695 22.5899L3.08008 23.4149L4.18887 24.3623H9.01647V24.357H67.8759L62.1139 19.375Z"
        fill="white"
      />
      <path
        d="M61.4656 16.4039L70.1091 1.08643H6.46393L10.8823 4.86384L7.91594 19.2969L3.08008 23.4313H65.242L60.7676 19.6059L61.4656 16.4039Z"
        fill="#231F20"
      />
      <path
        d="M23.4367 2.64348H10.7913L12.7411 4.30827L9.50811 20.0438L7.42754 21.8526H12.8781C13.5586 21.9184 14.1735 21.5013 14.2548 20.9189L15.3761 15.7324H20.7644L23.935 13.6727L25.7415 4.68178L23.4367 2.64348ZM20.4217 6.70942L19.4313 11.6504C19.4218 11.8025 19.341 11.9449 19.2074 12.0453C19.0737 12.1457 18.8984 12.1957 18.7212 12.184H14.4292L16.1796 11.6131L17.2822 6.21851H19.9732C20.3595 6.19184 20.509 6.37859 20.4217 6.68807V6.70942ZM28.3516 18.2723L30.3574 17.6213L33.4284 2.64348H26.2523L28.1834 4.30293L24.5829 21.8526H33.3661C34.0466 21.9184 34.6615 21.5013 34.7428 20.9189L35.2598 18.2829L28.3516 18.2723ZM49.693 2.64348H43.6319L36.3873 21.8526H40.3616C41.0029 21.8967 41.5779 21.5151 41.6884 20.9722L42.3549 18.9446L40.8038 18.443H45.6627L45.426 21.8793H50.4592L50.97 3.71065C50.9979 3.41616 50.8711 3.12635 50.6242 2.92002C50.3774 2.7137 50.036 2.61233 49.693 2.64348ZM43.6319 15.0707L46.6157 5.99974L45.9928 14.6332L47.3944 15.0867L43.6319 15.0707ZM59.5601 15.8871L67.029 2.64348H59.4667L61.4102 4.30827L58.2146 11.1329L57.5356 3.55591C57.4733 2.95296 57.2179 2.64348 56.4829 2.64348H52.5398L54.6515 15.8818L53.3682 21.8526H60.6502L58.6382 20.1292L59.5601 15.8871Z"
        fill="white"
      />
    </svg>
  );
});

PlayLogo.displayName = "PlayLogo";

export default PlayLogo;
