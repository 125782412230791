import VisibilityOffIcon from "@icons/glyphs-visibility-off.svg";
import VisibilityOnIcon from "@icons/glyphs-visibility-on.svg";
import * as Toggle from "@radix-ui/react-toggle";
import { colors, media } from "@styles/styleUtils";
import { Ref, forwardRef } from "react";
import styled from "styled-components";

// Create a wrapper component for Toggle.Root that forwards the ref correctly
const ToggleRootWithRef = forwardRef<HTMLButtonElement, Toggle.ToggleProps>(({ children, ...props }, ref) => {
  return (
    <Toggle.Root ref={ref} {...props}>
      {children}
    </Toggle.Root>
  );
});

// Use styled-components to style the custom ToggleRootWithRef
const StyledToggle = styled(ToggleRootWithRef)`
  all: unset;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.hover} {
    &:hover:not(:disabled) {
      cursor: pointer;
    }
  }
`;

// Base VisibilityToggle component
export const VisibilityToggle = forwardRef<HTMLButtonElement, VisibilityToggleProps>(
  ({ pressed, onPressedChange, ...otherProps }, ref) => {
    return (
      <StyledToggle
        ref={ref}
        aria-label="Toggle visibility"
        pressed={pressed}
        onPressedChange={onPressedChange}
        {...otherProps}
      >
        {pressed ? <VisibilityOnIcon /> : <VisibilityOffIcon style={{ opacity: 0.5 }} />}
      </StyledToggle>
    );
  },
);

type VisibilityToggleProps = Toggle.ToggleProps & { ref?: Ref<HTMLButtonElement> };

VisibilityToggle.displayName = "VisibilityToggle";

export const StyledVisibilityToggle = styled(VisibilityToggle)`
  width: unset;
  height: unset;
  display: flex;
  align-items: center;
  background: ${colors.lvl_4};
  box-shadow: none;
  transition: background 0.1s ease-in-out, border 0.1s ease-in-out;
  width: auto;

  svg {
    width: 32px;
    position: absolute;
    right: 8px;
    top: 10px;
  }

  &.error {
    border-color: ${colors.live_error};

    &:focus-visible {
      border-color: ${colors.live_error};
    }
  }

  &:disabled svg {
    visibility: hidden;
  }

  &:focus-visible svg {
    border: 2px solid ${colors["lvl_-5"]};
    border-radius: 3px;
    outline-offset: 1px;
  }
`;
