export default function CapitalOne() {
  return (
    <svg width="84" height="31" viewBox="0 0 84 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 24">
        <g id="Group 3">
          <mask id="mask0_5472_2008" maskUnits="userSpaceOnUse" x="23" y="0" width="59" height="31">
            <path
              id="Clip 2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.184 0.198853H81.0284V30.0393H23.184V0.198853Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_5472_2008)">
            <path
              id="Fill 1"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M80.406 2.97217C74.8259 -3.18276 38.9205 2.35363 23.6514 5.68915L23.2958 5.76612C23.2224 5.77905 23.1737 5.84946 23.1858 5.92225C23.1988 5.99623 23.2684 6.04496 23.3424 6.03243L23.6954 5.96342C36.3457 3.71123 62.6528 0.588121 68.3139 6.20149C70.0411 7.9137 69.629 10.1201 67.627 12.7228C68.7067 13.4165 69.4754 14.5074 69.8217 15.8725C77.6393 10.6717 82.8923 5.71441 80.406 2.97217ZM57.1709 21.7814C57.1673 21.7844 57.1636 21.7872 57.1598 21.7894C53.6198 24.2956 49.4263 26.928 44.9154 29.6903L44.7374 29.7944C44.6771 29.8375 44.6614 29.9205 44.7051 29.9821C44.7473 30.0432 44.831 30.0577 44.8921 30.0145L45.0422 29.9354C48.8682 27.9386 53.2626 25.568 57.6847 23.1362C57.7087 23.1231 57.7316 23.1098 57.7555 23.0971C57.5124 22.6987 57.3129 22.2613 57.1709 21.7814Z"
              fill="#CC2326"
            />
          </g>
        </g>
        <path
          id="Fill 4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.5997 20.0493C57.4712 16.3538 60.367 12.5746 64.5727 12.5746C67.3859 12.5746 69.227 14.4491 69.3296 17.3196C69.4643 21.2176 66.7596 24.7955 62.3567 24.7955C59.543 24.7955 57.7021 22.9248 57.5997 20.0493ZM59.4293 20.1655C59.5017 22.3315 60.871 24.1044 62.6588 24.1044C66.122 24.1044 67.5959 19.8524 67.4933 17.0107C67.4201 14.8401 66.0144 13.0704 64.2298 13.0704C61.2505 13.0704 59.3276 17.3047 59.4293 20.1655Z"
          fill="white"
        />
        <path
          id="Fill 6"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.4142 16.7994C21.3989 17.1391 21.3408 17.4367 21.2497 17.9558L20.6308 21.4277C20.5777 21.7354 20.692 21.9782 20.9784 22.1435L20.9593 22.279L17.4176 22.274L17.3856 21.3911C16.8698 21.7024 16.2473 21.9647 15.6841 22.1186C15.1232 22.2626 14.6688 22.3267 13.987 22.3267C12.8428 22.3267 12.1873 22.2077 11.7356 21.8114C11.2758 21.4019 11.0534 21.131 11.0753 20.5681C11.093 20.2443 11.2605 19.8289 11.5035 19.5379C11.7487 19.2424 12.0827 19.0496 12.4462 18.8794C12.8116 18.7052 13.2414 18.5793 13.8673 18.4806C14.4939 18.3784 15.3661 18.2772 16.5347 18.1797C17.1485 18.1286 17.5226 17.9862 17.6986 17.9166C17.9426 17.8217 18.0381 17.7169 18.0743 17.518C18.1397 17.1608 18.0254 16.9579 17.552 16.8635C16.2756 16.6127 13.8846 17.0256 12.6515 17.334L13.3217 15.5272C14.9472 15.2899 16.4713 15.1379 18.0747 15.1379C20.6236 15.1379 21.4399 15.8655 21.4142 16.7994ZM17.7581 19.0733C17.6075 19.1296 17.4136 19.1861 17.1729 19.2362C16.9331 19.2903 16.4943 19.3684 15.8573 19.4689C15.4186 19.5379 15.1145 19.6296 14.9408 19.7539C14.7748 19.8774 14.6771 20.0236 14.6491 20.2022C14.6147 20.3929 14.6771 20.5453 14.8426 20.6646C15.0105 20.7817 15.2921 20.8424 15.6902 20.8424C15.988 20.8424 16.2805 20.7939 16.5636 20.7038C16.85 20.6101 17.091 20.4824 17.2774 20.3249C17.4136 20.2072 17.5113 20.057 17.584 19.8774C17.6262 19.7593 17.6867 19.4926 17.7581 19.0733Z"
          fill="white"
        />
        <path
          id="Fill 8"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.0972 13.7354C33.1986 13.1652 34.0987 12.7021 35.1041 12.7021C36.1115 12.7021 36.8466 13.1652 36.7493 13.7354C36.6431 14.306 35.7447 14.768 34.7368 14.768C33.7262 14.768 32.9951 14.306 33.0972 13.7354ZM31.6801 22.2789L32.9094 15.342H36.3004L35.0713 22.2789H31.6801Z"
          fill="white"
        />
        <path
          id="Fill 10"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.9997 16.8127C51.9858 17.1532 51.9256 17.4533 51.8321 17.9678L51.2179 21.4442C51.1646 21.7503 51.2782 21.9915 51.5634 22.162L51.5423 22.2823L48.0015 22.2892L47.9745 21.4044C47.4568 21.718 46.8309 21.9788 46.2718 22.1307C45.708 22.2789 45.2539 22.3396 44.5684 22.3396C43.4256 22.3396 42.7748 22.2248 42.3213 21.8258C41.8597 21.4151 41.6368 21.1458 41.6616 20.5844C41.6744 20.2584 41.8442 19.8404 42.0884 19.554C42.3337 19.2548 42.668 19.0657 43.0335 18.8943C43.3987 18.7224 43.8307 18.5951 44.4488 18.4915C45.0785 18.3941 45.9538 18.2881 47.1213 18.1906C47.7293 18.1397 48.1038 18.0015 48.2784 17.9344C48.5256 17.8388 48.623 17.7318 48.6594 17.5323C48.7223 17.1822 48.6105 16.9734 48.1398 16.8783C46.8575 16.6252 44.4723 17.0408 43.2398 17.3471L43.9063 15.5408C45.5383 15.3024 47.0544 15.15 48.6602 15.15C51.2078 15.15 52.0204 15.8805 51.9997 16.8127ZM48.3446 19.09C48.1927 19.1417 47.9956 19.1978 47.7561 19.253C47.5186 19.3097 47.0795 19.3799 46.4387 19.4846C46.0027 19.554 45.6931 19.6456 45.5256 19.7702C45.3631 19.8895 45.2662 20.0377 45.2316 20.2163C45.196 20.4082 45.2662 20.5627 45.4254 20.6779C45.5966 20.7954 45.8802 20.8529 46.2786 20.8529C46.5741 20.8529 46.8635 20.8087 47.1483 20.7194C47.4375 20.6226 47.6777 20.4993 47.8653 20.34C47.9956 20.2224 48.0962 20.0693 48.167 19.8895C48.2106 19.7733 48.2696 19.506 48.3446 19.09Z"
          fill="white"
        />
        <g id="Group 14">
          <mask id="mask1_5472_2008" maskUnits="userSpaceOnUse" x="0" y="12" width="13" height="11">
            <path
              id="Clip 13"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.748291 12.5009H12.1925V22.4335H0.748291V12.5009Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_5472_2008)">
            <path
              id="Fill 12"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.6117 19.7853C9.50793 20.0043 8.88701 20.0884 7.79552 20.0884C6.17519 20.0884 4.8886 19.2841 4.95801 17.8098C5.00753 16.8279 6.17122 14.7551 9.12746 14.7551C10.0575 14.7551 10.785 14.9199 11.7633 15.4774L12.1925 13.0679C10.8625 12.5651 9.98168 12.4896 8.83967 12.5021C4.96656 12.5437 1.24341 14.3058 0.78279 17.8871C0.31322 21.5266 4.75853 22.4418 6.73207 22.4335C7.87169 22.4301 9.05606 22.3836 10.1621 22.269L10.6117 19.7853Z"
              fill="white"
            />
          </g>
        </g>
        <path
          id="Fill 15"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.1694 15.3016L38.4132 13.8891L41.9602 13.1172L41.5825 15.3016H43.3196L42.9894 16.6989L41.3046 16.6952L40.2808 22.2739C40.2808 22.2739 36.8659 22.2514 36.854 22.2739L37.8733 16.7085H36.5841L36.8456 15.3016H38.1694Z"
          fill="white"
        />
        <path
          id="Fill 17"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.4416 22.274L51.9877 22.279L53.725 12.6002L57.0897 12.6951L55.4416 22.274Z"
          fill="white"
        />
        <path
          id="Fill 19"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.0201 24.6741L22.6705 15.3761H25.6486L25.4179 16.5631C25.6766 16.2089 26.0893 15.9179 26.6589 15.6806C27.2337 15.4455 27.8803 15.2711 28.5826 15.2711C29.3598 15.2711 29.8393 15.3131 30.4211 15.6142C30.9994 15.9179 31.4024 16.3574 31.6225 16.9306C31.8457 17.4947 31.892 18.1293 31.7711 18.8194C31.5698 19.965 31.0106 20.8694 30.0951 21.5333C29.1858 22.1965 28.3684 22.371 27.1999 22.3755C26.7862 22.3755 26.4404 22.3326 26.153 22.2626C25.8721 22.189 25.6486 22.0999 25.4775 21.9913C25.3149 21.8815 25.1418 21.7299 24.9191 21.4871L24.3646 24.6741H21.0201ZM27.1947 16.9676C26.7504 16.9676 26.3483 17.1017 25.9905 17.3714C25.6365 17.6418 25.4111 18.0348 25.3198 18.5593C25.2132 19.1649 25.2755 19.5991 25.5153 19.8684C25.7578 20.1347 26.1062 20.2687 26.5639 20.2687C26.8719 20.2687 27.1919 20.2134 27.4268 20.0973C27.717 19.9438 27.9173 19.7815 28.0905 19.5267C28.2779 19.2576 28.4004 18.9585 28.4636 18.611C28.5607 18.0635 28.4785 17.6528 28.2246 17.3823C27.9774 17.1043 27.6369 16.9676 27.1947 16.9676Z"
          fill="white"
        />
        <g id="Group 23">
          <mask id="mask2_5472_2008" maskUnits="userSpaceOnUse" x="76" y="16" width="8" height="9">
            <path
              id="Clip 22"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M76.9934 16.4868H83.884V24.7653H76.9934V16.4868Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2_5472_2008)">
            <path
              id="Fill 21"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M82.8842 22.0896L83.1529 22.2716C82.4745 23.6531 81.0917 24.7653 79.469 24.7653C78.142 24.7653 77.0541 23.882 76.9964 22.2225C76.889 19.2491 79.6744 16.4868 81.9562 16.4868C82.9348 16.4868 83.8419 16.9144 83.8827 17.9677C83.9666 20.3323 80.6462 21.0556 78.4588 21.1048C78.3876 21.3516 78.3612 21.6002 78.3739 21.9781C78.4099 22.9584 79.0036 23.7834 80.2295 23.7834C81.2892 23.7834 82.3488 22.9301 82.8842 22.0896ZM82.5865 17.8194C82.5672 17.2599 82.2862 16.8844 81.709 16.8844C80.318 16.8844 78.8389 19.6087 78.5835 20.7074C80.8656 20.7074 82.6418 19.3482 82.5865 17.8194Z"
              fill="white"
            />
          </g>
        </g>
        <path
          id="Fill 24"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.279 17.7413C69.5145 17.71 69.8937 17.6685 70.0574 17.6685C70.3074 17.6685 70.5417 17.7158 70.5532 17.9624C70.5578 18.1092 70.2935 19.1623 70.2372 19.4069L69.7086 21.7393C69.4952 22.6937 69.2633 23.6931 69.058 24.4827H70.417L71.1807 20.7878C73.5113 18.3602 74.4081 17.5686 74.9588 17.5686C75.2058 17.5686 75.3766 17.6985 75.3884 17.9948C75.4019 18.4222 75.1376 19.3924 75.0451 19.6705L74.2657 22.3654C74.0886 22.971 73.9576 23.5167 73.9697 23.9071C73.9936 24.499 74.3512 24.7617 74.8822 24.7617C75.8754 24.7617 76.5958 23.7269 77.2263 22.71L77.0417 22.3765C76.7863 22.7854 76.198 23.6778 75.7332 23.6778C75.5863 23.6778 75.448 23.58 75.4429 23.3335C75.4289 23.0181 75.5622 22.5477 75.6535 22.2351L76.5192 19.0782C76.7511 18.2086 76.8627 17.6492 76.8569 17.3512C76.8353 16.7617 76.491 16.4861 75.9637 16.4861C75.0703 16.4861 73.8822 17.139 71.3691 20.0322H71.3331L71.6495 18.6201C71.8213 17.8483 71.9894 17.0428 72.1525 16.4861C71.2836 16.7993 70.0373 17.1498 69.267 17.2985L69.279 17.7413Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
