import { styled } from "styled-components";
import { colors, colorsContrastSufficiently } from "../../styles/styleUtils";

interface ProgressBarProps {
  pctFilled: number;
  fillColor?: string;
  backgroundFillColor?: string;
  height?: string;
  fillMode?: "left" | "right";
  hasFillColorBorder?: boolean;
  isDarkMode?: boolean;
}

export const StyledProgressBar = styled.div<{
  $pctFilled?: number;
  $fillColor?: string;
  $backgroundFillColor: string;
  $height: string;
  $fillMode: "left" | "right";
  $hasFillColorBorder: boolean;
  $isDarkMode?: boolean;
}>`
  border: 0.5px solid
    ${({ $fillColor, $backgroundFillColor, $hasFillColorBorder }) =>
      $hasFillColorBorder ? $fillColor : $backgroundFillColor};
  border-radius: 50px;
  height: ${({ $height }) => $height};
  background-color: ${({ $backgroundFillColor }) => $backgroundFillColor};
  width: 100%;
  display: flex;
  justify-content: ${({ $fillMode }) => ($fillMode === "left" ? "flex-start" : "flex-end")};

  .progress-bar {
    background-color: ${({ $fillColor }) => $fillColor};
    border-radius: 50px;
    height: 100%;
    width: ${({ $pctFilled }) => `${$pctFilled || 0}%`};
    border: ${({ $pctFilled }) => (!$pctFilled ? 0 : 0.5)} solid
      ${({ $isDarkMode, $fillColor }) => {
        if (!$fillColor) return "transparent";
        if ($isDarkMode) {
          if (colorsContrastSufficiently($fillColor, "#17171d") === false) {
            return colors.lvl_2;
          }
        }
        return $fillColor;
      }};
  }
`;

/**
  @description Progress bar that can be customized

  @param pctFilled - Width of the fill bar
  @param fillColor (optional) - Sets color of the fill bar
  @param backgroundFillColor (optional) - Sets color of the background. Default is transparent
  @param height (optional) - Sets the height of the bar. Default is set to 100% to fill the container its in
  @param fillMode (optional) - Progress bar to fill from left vs right
  @param hasFillColorBorder (optional) - Decides whether or not to have the backround border color as the fill color. Default is _background_ border color
  @param isDarkMode (optional) - Whether dark mode is active--determines whether to show a border to enhance contrast
 **/
export const MMLProgressBar = ({
  pctFilled,
  fillColor = colors["lvl_-5"],
  backgroundFillColor = colors["lvl_3"],
  height = "100%",
  fillMode = "left",
  isDarkMode = false,
  hasFillColorBorder = false,
}: ProgressBarProps) => {
  return (
    <StyledProgressBar
      $pctFilled={pctFilled}
      $fillColor={fillColor}
      $backgroundFillColor={backgroundFillColor}
      $height={height}
      $fillMode={fillMode}
      $isDarkMode={isDarkMode}
      $hasFillColorBorder={hasFillColorBorder}
    >
      <div className="progress-bar" />
    </StyledProgressBar>
  );
};
