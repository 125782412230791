import { styled } from "styled-components";
import { Team, Conference } from "../../types/apiTypes.types";
import { colors, pxToEm } from "../../styles/styleUtils";
import { calculateTeamWinPct, formatTeamRecord } from "../../lib/misc";
import { getConferenceImage } from "../../lib/images";

// TODO: this is a placeholder. Need to get real data from API.
const placeholderSummary = `Team summary coming soon.`;

const Overview = styled.div`
  display: flex;
  flex-direction: column;
  &:first-child:not(:only-child) {
    padding-right: ${pxToEm(36)};
  }

  &:last-child:not(:only-child) {
    border-left: 0.5px solid ${colors.lvl_2};
    padding-left: ${pxToEm(36)};
  }
`;

const Title = styled.h2`
  font-size: ${pxToEm(12)};
  font-style: oblique;
  font-weight: normal;
  line-height: ${pxToEm(14)};
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  margin-bottom: ${pxToEm(36)};
  row-gap: ${pxToEm(16)};
  width: 100%;
`;

const OverviewRow = styled.div`
  display: contents;
  > * {
    border-bottom: 0.5px solid ${colors.lvl_2};
    width: 100%;
  }
  h3 {
    font-size: ${pxToEm(10)};
    font-weight: 325;
    line-height: 1.2;
    padding-bottom: 6px;
    padding-left: 6px;
    text-transform: uppercase;
  }
  span {
    font-size: ${pxToEm(22)};
    font-weight: 600;
    line-height: 1.18;
    padding-bottom: 6px;
    padding-right: 6px;
    text-transform: uppercase;
  }
`;

const StatNameContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const StatValueContainer = styled.div`
  align-items: end;
  display: flex;
  flex-direction: column-reverse;
`;

const ConferenceImageContainer = styled.div`
  align-items: end;
  display: flex;
  flex-direction: column-reverse;
  padding-right: 6px;
  width: 100%;
`;

const ConferenceImage = styled.img`
  height: 40px;
  width: 40px;
  padding-bottom: 6px;
`;

const TeamSummary = styled.p`
  color: ${colors["lvl_-2"]};
  font-size: ${pxToEm(12)};
  letter-spacing: 0.4px;
  line-height: 1.67;
  margin: ${pxToEm(10)} 0;
`;

type OverviewProps = {
  team?: Team;
  conference?: Pick<Conference, "name" | "link">;
  showTeamName?: boolean;
  darkMode?: boolean;
};

const OverviewLineFactory = (statName: string, statValue: string) => {
  return (
    <OverviewRow>
      <StatNameContainer>
        <h3>{statName}</h3>
      </StatNameContainer>
      <StatValueContainer>
        <span>{statValue}</span>
      </StatValueContainer>
    </OverviewRow>
  );
};

export const MMLTeamOverview = ({ team, conference, showTeamName = false, darkMode = false }: OverviewProps) => {
  return (
    <Overview>
      {showTeamName && <Title>{team ? team.school : "TBD"}</Title>}
      <StatsContainer>
        <OverviewRow>
          <StatNameContainer>
            <h3>Conference</h3>
          </StatNameContainer>
          {conference && conference.link && conference.name ? (
            <ConferenceImageContainer>
              <ConferenceImage
                src={getConferenceImage(conference.name, darkMode ? "bgd" : "bgl")}
                alt={`${conference.name} logo`}
              />
            </ConferenceImageContainer>
          ) : (
            <StatValueContainer>
              <span>-</span>
            </StatValueContainer>
          )}
        </OverviewRow>
        {OverviewLineFactory("Overall Record", team?.overall_record ? formatTeamRecord(team.overall_record) : "-")}
        {OverviewLineFactory(
          "Conference Record",
          team?.conference_record ? formatTeamRecord(team.conference_record) : "-",
        )}
        {OverviewLineFactory(
          "Overall Winning %",
          team?.overall_record ? calculateTeamWinPct(team.overall_record) : "-",
        )}
        {/* TODO: this is a placeholder. Need to get real data from API. */}
        {OverviewLineFactory("Conference Rank", team?.conference_rank ? `${team?.conference_rank}` : "-")}
      </StatsContainer>
      <Title>Team Summary</Title>
      <TeamSummary>{team?.team_summary ? team.team_summary : placeholderSummary}</TeamSummary>
    </Overview>
  );
};
