import { SVGProps, forwardRef } from "react";

const MBCGHorizontalDark = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  const { className, ...rest } = props;
  return (
    <svg
      ref={ref}
      width="398"
      height="59"
      viewBox="0 0 398 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <path
        d="M72.7468 37.5241C68.2274 40.7219 62.8702 44.0849 57.1118 47.6131L56.8876 47.7429C56.8552 47.776 56.837 47.8204 56.837 47.8668C56.837 47.9131 56.8552 47.9576 56.8876 47.9907C56.903 48.0064 56.9213 48.0188 56.9416 48.0274C56.9619 48.0359 56.9836 48.0402 57.0056 48.0402C57.0276 48.0402 57.0493 48.0359 57.0696 48.0274C57.0899 48.0188 57.1082 48.0064 57.1236 47.9907L57.3124 47.8963C62.1976 45.3475 67.8144 42.3149 73.4548 39.2115H73.5492C73.2084 38.6869 72.9386 38.1195 72.7468 37.5241ZM102.412 13.5111C95.332 5.65226 49.4418 12.7205 29.9364 16.9803L29.488 17.0747C29.443 17.0833 29.4032 17.109 29.3768 17.1464C29.3504 17.1838 29.3395 17.23 29.3464 17.2753C29.355 17.3202 29.3808 17.3601 29.4182 17.3865C29.4556 17.4129 29.5017 17.4238 29.547 17.4169L29.9954 17.3225C46.1496 14.4551 79.7442 10.4667 86.9658 17.6293C89.1724 19.8241 88.6532 22.6325 86.0926 25.9601C87.5096 26.892 88.5149 28.3324 88.901 29.9839C98.8838 23.3405 105.586 17.0157 102.412 13.5111Z"
        fill="#CC2427"
      />
      <path
        d="M75.6262 35.459C75.7206 38.232 77.467 40.4976 79.7444 40.4976C84.1694 40.4976 86.0574 35.0696 85.9276 31.4352C85.8332 28.6622 84.0396 26.4084 81.7504 26.4084C77.9508 26.4084 75.52 31.86 75.6262 35.459ZM73.2662 35.3174C73.2157 34.1035 73.407 32.8917 73.8289 31.7524C74.2508 30.6131 74.8949 29.569 75.7238 28.6808C76.5528 27.7925 77.55 27.0779 78.6575 26.5784C79.765 26.0789 80.9607 25.8046 82.1752 25.7712C82.9855 25.7306 83.7953 25.8603 84.5525 26.152C85.3096 26.4436 85.9971 26.8907 86.5708 27.4644C87.1445 28.0381 87.5916 28.7256 87.8833 29.4827C88.1749 30.2399 88.3046 31.0497 88.264 31.86C88.441 36.8396 84.9836 41.418 79.3668 41.418C75.7678 41.3826 73.4196 38.94 73.2898 35.3174H73.2662Z"
        fill="white"
      />
      <path
        d="M22.42 34.0666C22.177 34.1531 21.9286 34.2241 21.6766 34.279C21.3698 34.3498 20.8152 34.4442 20.001 34.574C19.5862 34.611 19.184 34.7356 18.821 34.9398C18.7246 35.0043 18.6424 35.0878 18.5795 35.1852C18.5166 35.2826 18.4743 35.3918 18.4552 35.5062C18.4287 35.6181 18.4384 35.7356 18.4829 35.8416C18.5274 35.9477 18.6045 36.0368 18.703 36.0962C19.0293 36.2803 19.4033 36.3625 19.7768 36.3322C20.1768 36.3329 20.5746 36.2732 20.9568 36.1552C21.2883 36.0548 21.597 35.8904 21.8654 35.6714C22.0446 35.517 22.1791 35.3173 22.2548 35.0932C22.3412 34.7568 22.3965 34.4131 22.42 34.0666ZM27.14 31.1638C27.1001 31.6595 27.0292 32.1521 26.9276 32.6388L26.137 37.0756C26.0981 37.2555 26.1212 37.4432 26.2026 37.6082C26.2841 37.7732 26.419 37.9057 26.5854 37.9842V38.1612H21.9716V36.9812C21.292 37.386 20.5619 37.6994 19.8004 37.9134C19.0914 38.0958 18.3612 38.1831 17.6292 38.173C16.6264 38.3091 15.6082 38.0787 14.7618 37.524C14.4953 37.3538 14.2771 37.1178 14.1283 36.8388C13.9795 36.5598 13.9051 36.2472 13.9122 35.931C13.9596 35.4504 14.1485 34.9945 14.455 34.6212C14.7853 34.2594 15.1871 33.9701 15.635 33.7716C16.2131 33.5157 16.8251 33.3448 17.4522 33.2642C18.2546 33.1344 19.3638 33.0046 20.8624 32.8866C21.3727 32.8503 21.8743 32.7349 22.3492 32.5444C22.656 32.4264 22.7858 32.2848 22.833 32.037C22.9156 31.5768 22.7622 31.3172 22.1604 31.1992C20.0567 31.0448 17.942 31.2483 15.9064 31.801L16.756 29.441C18.7672 29.1305 20.7981 28.9648 22.833 28.9454C26.0662 29.0398 27.14 29.972 27.14 31.1638Z"
        fill="white"
      />
      <path
        d="M40.1908 38.1612L41.7602 29.2994H46.0908L44.5214 38.1612H40.1908ZM41.9962 27.258C42.126 26.5264 43.2824 25.9364 44.5686 25.9364C45.8548 25.9364 46.787 26.5264 46.669 27.258C46.551 27.9896 45.3828 28.5678 44.0966 28.5678C42.8104 28.5678 41.8664 27.9778 41.9962 27.258Z"
        fill="white"
      />
      <path
        d="M61.4662 34.0902C61.2231 34.1767 60.9748 34.2477 60.7228 34.3026C60.416 34.3734 59.8496 34.456 59.0354 34.5976C58.6191 34.6268 58.2152 34.752 57.8554 34.9634C57.6643 35.0995 57.5299 35.3011 57.4778 35.5298C57.458 35.6416 57.4708 35.7567 57.5148 35.8613C57.5587 35.966 57.6319 36.0557 57.7256 36.1198C58.0627 36.2972 58.4433 36.375 58.823 36.344C59.1998 36.3444 59.5742 36.2847 59.9322 36.167C60.2657 36.0714 60.5753 35.9066 60.8408 35.6832C61.0159 35.5299 61.1498 35.3352 61.2302 35.1168C61.333 34.7806 61.4119 34.4376 61.4662 34.0902ZM66.1862 31.1874C66.1455 31.6829 66.0746 32.1755 65.9738 32.6624L65.1832 37.0992C65.1464 37.279 65.1705 37.4658 65.2517 37.6303C65.3329 37.7949 65.4666 37.9277 65.6316 38.0078V38.173H61.0296V37.052C60.3495 37.453 59.6195 37.7625 58.8584 37.9724C58.1511 38.1651 57.4201 38.2565 56.6872 38.2438C55.6828 38.3808 54.6629 38.1458 53.8198 37.583C53.2298 37.0638 52.9348 36.7216 52.9702 36.0018C53.0102 35.5158 53.1999 35.0541 53.513 34.6802C53.8393 34.3169 54.2424 34.0307 54.693 33.8424C55.266 33.5816 55.8743 33.4067 56.4984 33.3232C57.3008 33.2052 58.4218 33.0636 59.9086 32.9456C60.4134 32.9058 60.91 32.7945 61.3836 32.6152C61.5073 32.5879 61.6199 32.5236 61.7063 32.4309C61.7927 32.3382 61.8489 32.2214 61.8674 32.096C61.95 31.6594 61.8084 31.388 61.2066 31.27C59.1035 31.1053 56.9878 31.3049 54.9526 31.86L55.8022 29.559C57.8127 29.2431 59.844 29.0775 61.8792 29.0634C65.1242 29.0634 66.1626 29.9956 66.139 31.1874H66.1862Z"
        fill="white"
      />
      <path
        d="M13.2868 34.9752C12.1254 35.2388 10.9377 35.3695 9.74679 35.3646C7.68179 35.3646 6.04159 34.338 6.12419 32.45C6.12419 31.1992 7.66999 28.556 11.446 28.556C12.6358 28.5336 13.8071 28.853 14.8208 29.4764L15.3636 26.3966C14.0013 25.8618 12.5425 25.6167 11.0802 25.6768C6.13599 25.7358 1.38059 28.0368 0.79059 32.5562C0.20059 37.0756 5.86459 38.3736 8.38979 38.3618C9.84119 38.3618 11.3634 38.291 12.7676 38.1494L13.2868 34.9752Z"
        fill="white"
      />
      <path
        d="M48.4744 29.2522L48.793 27.4468L53.3124 26.4674L52.8404 29.2522H55.0588L54.634 31.034H52.4864L51.1766 38.114C51.1766 38.114 46.8106 38.114 46.7988 38.114L48.0968 31.034H46.4566L46.787 29.2404L48.4744 29.2522Z"
        fill="white"
      />
      <path d="M70.5404 38.1612H66.1272L68.3456 25.8066L72.6408 25.9246L70.5404 38.1612Z" fill="white" />
      <path
        d="M34.4678 31.3762C33.9091 31.3727 33.3652 31.5554 32.922 31.8954C32.4549 32.2758 32.1509 32.8204 32.0724 33.4176C31.9841 33.6955 31.9605 33.9899 32.0034 34.2782C32.0464 34.5666 32.1547 34.8413 32.3202 35.0814C32.4918 35.2591 32.6998 35.3975 32.93 35.4871C33.1602 35.5767 33.407 35.6155 33.6536 35.6006C34.0354 35.6099 34.4145 35.5333 34.7628 35.3764C35.1037 35.2089 35.3961 34.9571 35.6124 34.6448C35.8539 34.2906 36.015 33.8879 36.0844 33.4648C36.161 33.1976 36.1731 32.916 36.1197 32.6432C36.0664 32.3704 35.9492 32.1141 35.7776 31.8954C35.6101 31.719 35.4061 31.5811 35.1799 31.4915C34.9537 31.4018 34.7107 31.3625 34.4678 31.3762ZM26.5736 41.2174L28.6858 29.3466H32.4854L32.1904 30.8688C32.6005 30.3341 33.1524 29.9253 33.7834 29.6888C34.5604 29.3592 35.3938 29.1829 36.2378 29.1696C37.0495 29.0992 37.865 29.2501 38.5978 29.6062C39.299 29.9555 39.8456 30.5526 40.1318 31.2818C40.4062 32.0371 40.4714 32.8525 40.3206 33.6418C40.2146 34.3332 39.9697 34.9961 39.6006 35.5903C39.2315 36.1845 38.7458 36.6978 38.173 37.0992C37.1068 37.8821 35.7998 38.2663 34.4796 38.1848C34.0304 38.1903 33.5824 38.1387 33.1462 38.0314C32.8423 37.9652 32.5513 37.8496 32.2848 37.6892C32.0173 37.5056 31.7753 37.2873 31.565 37.0402L30.857 41.1112L26.5736 41.2174Z"
        fill="white"
      />
      <path
        d="M105.197 32.4736C105.197 31.7538 104.819 31.2936 104.017 31.2936C102.247 31.2936 100.347 34.7746 100.029 36.1788C103.002 36.1552 105.268 34.4206 105.197 32.4736ZM105.575 37.9252L105.917 38.1494C105.503 39.0664 104.843 39.8501 104.009 40.4128C103.176 40.9755 102.202 41.295 101.197 41.3354C100.771 41.3493 100.346 41.2741 99.9509 41.1149C99.5555 40.9556 99.1975 40.7156 98.9 40.4103C98.6025 40.105 98.3718 39.741 98.2228 39.3416C98.0737 38.9421 98.0096 38.516 98.0344 38.0904C97.8928 34.2908 101.456 30.7626 104.371 30.7626C105.622 30.7626 106.731 31.3172 106.825 32.6624C106.932 35.6714 102.695 36.6036 99.8988 36.6626C99.8025 37.0481 99.7667 37.4462 99.7926 37.8426C99.7804 38.1531 99.8335 38.4627 99.9486 38.7514C100.064 39.04 100.238 39.3013 100.461 39.5182C100.683 39.7352 100.949 39.9029 101.24 40.0107C101.532 40.1184 101.843 40.1637 102.153 40.1436C102.858 40.0707 103.536 39.8316 104.13 39.446C104.725 39.0603 105.22 38.5392 105.575 37.9252Z"
        fill="white"
      />
      <path
        d="M88.205 32.3674C88.5 32.3674 88.9838 32.273 89.1962 32.273C89.4086 32.273 89.8216 32.332 89.8334 32.6506C89.8452 32.9692 89.503 34.1846 89.4322 34.4914L88.7478 37.4768C88.4764 38.6568 88.1814 39.9666 87.9218 41.0168H89.68L90.6594 36.2968C93.633 33.1934 94.7776 32.1786 95.4856 32.1786C95.5593 32.1731 95.6333 32.1838 95.7025 32.2099C95.7716 32.236 95.8343 32.2769 95.886 32.3297C95.9377 32.3825 95.9772 32.446 96.0018 32.5157C96.0264 32.5854 96.0355 32.6597 96.0284 32.7332C95.9701 33.4603 95.8235 34.1775 95.5918 34.869L94.6006 38.3146C94.3742 38.9441 94.2468 39.6049 94.223 40.2734C94.2127 40.4274 94.2369 40.5818 94.2938 40.7253C94.3507 40.8687 94.4389 40.9977 94.5519 41.1028C94.665 41.2079 94.8 41.2865 94.9472 41.3329C95.0944 41.3793 95.2501 41.3922 95.403 41.3708C96.6774 41.3708 97.5978 40.0492 98.4002 38.7512L98.1642 38.3264C97.8338 38.8456 97.0904 39.9902 96.4886 39.9902C96.2998 39.9902 96.1346 39.8604 96.1228 39.5418C96.1446 39.063 96.236 38.59 96.3942 38.1376L97.4916 34.1138C97.734 33.3841 97.8847 32.627 97.94 31.86C97.9521 31.7051 97.9293 31.5494 97.8732 31.4044C97.8171 31.2595 97.7292 31.1289 97.616 31.0225C97.5027 30.916 97.367 30.8363 97.2189 30.7893C97.0707 30.7423 96.9139 30.7292 96.76 30.7508C95.58 30.7508 94.105 31.5886 90.86 35.282L91.2612 33.4766C91.4854 32.4972 91.6978 31.4588 91.9102 30.7508C90.7073 31.1781 89.4774 31.525 88.2286 31.7892L88.205 32.3674Z"
        fill="white"
      />
      <path
        d="M142.497 37.5359L143.7 30.9633H134.874L135.074 29.9249L135.44 28.1195L135.535 27.6239H122.094L121.658 29.9249H132.714L131.912 34.2909H120.832L120.395 36.5919H133.824L134.437 33.2525H140.998L139.004 44.1203H132.443L133.045 40.7927H119.605L119.168 43.0819H130.295L129.493 47.4597H118.342L117.905 49.7489H131.098L131.133 49.6191L131.57 47.4597L131.77 46.4213H140.845L142.072 39.8369H145.222L145.647 37.5359H142.497Z"
        fill="#009ADE"
      />
      <path
        d="M371.901 12.8621L370.685 19.4347H379.523L379.311 20.4731L378.957 22.2785L378.851 22.7741H392.291L392.728 20.4731H381.671L382.485 16.1071H393.554L393.99 13.8061H380.574L379.96 17.1455H373.399L375.382 6.2777H381.954L381.341 9.61708H394.793L395.229 7.31609H384.09L384.892 2.95009H396.055L396.492 0.649094H383.288L383.264 0.778895L382.827 2.95009L382.615 3.9885H373.553L372.314 10.5611H369.175L368.75 12.8621H371.901Z"
        fill="#009ADE"
      />
      <path
        d="M164.586 30.3496L162.403 27.9896H149.14L151.889 30.9042L148.349 49.5482H158.426L161.506 47.1882L162.792 40.5094L160.845 38.4444L163.406 36.5328L164.586 30.3496ZM157.943 41.123L157.199 45.0052C157.163 45.1698 157.081 45.321 156.963 45.4418C156.838 45.5476 156.678 45.6024 156.515 45.5952H153.801L154.768 40.5684H157.518C157.588 40.5599 157.658 40.5683 157.724 40.593C157.79 40.6176 157.848 40.6578 157.895 40.71C157.931 40.7719 157.954 40.8405 157.962 40.9116C157.97 40.9826 157.964 41.0546 157.943 41.123ZM159.429 32.5916L158.792 35.9192C158.768 36.1052 158.685 36.2788 158.556 36.4148C158.421 36.5261 158.247 36.581 158.072 36.5682H155.512L156.397 32.037H159.005C159.074 32.032 159.144 32.0421 159.209 32.0666C159.274 32.091 159.333 32.1292 159.382 32.1786C159.422 32.2389 159.447 32.3077 159.455 32.3795C159.463 32.4512 159.454 32.5239 159.429 32.5916Z"
        fill="white"
      />
      <path
        d="M180.198 30.3496L178.027 27.9896H167.914L163.772 49.56H167.312C167.632 49.5793 167.949 49.4876 168.209 49.3004C168.455 49.1101 168.616 48.8302 168.657 48.5216L169.967 41.772H171.537L172.835 49.56H176.74C176.874 49.5771 177.009 49.5601 177.134 49.5105C177.258 49.4609 177.368 49.3804 177.453 49.2765C177.538 49.1726 177.595 49.0488 177.619 48.9168C177.643 48.7847 177.633 48.6487 177.59 48.5216L176.268 41.0876L178.416 39.4592L180.198 30.3496ZM175.053 32.5916L174.18 37.2172C174.143 37.3783 174.061 37.5256 173.944 37.642C173.815 37.7496 173.651 37.8045 173.484 37.7954H170.781L171.891 32.037H174.64C174.708 32.0322 174.776 32.0424 174.839 32.0669C174.902 32.0914 174.959 32.1295 175.006 32.1786C175.048 32.2376 175.075 32.3066 175.083 32.3789C175.092 32.4513 175.081 32.5245 175.053 32.5916Z"
        fill="white"
      />
      <path
        d="M192.505 28.0369H186.676L179.36 49.5601H183.183C183.483 49.5758 183.778 49.4883 184.021 49.3123C184.258 49.1153 184.424 48.8458 184.493 48.5453L185.343 45.7133H188.363L188.057 49.5601H192.895L193.709 29.2051C193.722 29.0425 193.702 28.8789 193.649 28.7245C193.597 28.5701 193.512 28.4284 193.402 28.3083C193.279 28.2042 193.136 28.1264 192.982 28.0797C192.827 28.0331 192.665 28.0185 192.505 28.0369ZM188.67 41.9609H186.44L189.473 31.7657L188.67 41.9609Z"
        fill="white"
      />
      <path
        d="M208.742 28.0368H201.78L198.688 30.3968L195.432 47.3299L197.603 49.6899H204.6L207.692 47.3299L208.565 42.7751C208.604 42.6452 208.615 42.5085 208.597 42.3741C208.579 42.2397 208.531 42.1108 208.459 41.9963C208.363 41.8979 208.247 41.8224 208.118 41.7754C207.99 41.7284 207.852 41.7111 207.715 41.7249H204.057L203.408 45.0524C203.381 45.2204 203.298 45.3743 203.172 45.4891C203.049 45.597 202.888 45.6521 202.724 45.6424H201.001C200.934 45.6473 200.866 45.6371 200.803 45.6126C200.739 45.5881 200.682 45.5499 200.635 45.5009C200.591 45.4429 200.562 45.3744 200.552 45.3021C200.541 45.2297 200.55 45.156 200.576 45.0879L202.936 32.6743C202.964 32.5063 203.047 32.3525 203.172 32.2377C203.299 32.1348 203.458 32.0804 203.621 32.0843H205.32C205.388 32.0795 205.456 32.0896 205.519 32.1141C205.582 32.1386 205.639 32.1768 205.686 32.2259C205.721 32.289 205.739 32.3601 205.739 32.4324C205.739 32.5046 205.721 32.5757 205.686 32.6389L205.072 35.8485H208.612C208.933 35.8633 209.25 35.7675 209.509 35.577C209.755 35.3868 209.917 35.1068 209.957 34.7983L210.795 30.3968L208.742 28.0368Z"
        fill="white"
      />
      <path
        d="M221.994 38.6922L227.209 29.087C227.287 28.9816 227.337 28.8578 227.354 28.7277C227.37 28.5975 227.353 28.4653 227.304 28.3437C227.252 28.2378 227.169 28.151 227.065 28.0959C226.961 28.0407 226.842 28.02 226.726 28.0368H222.879L218.454 36.5093H217.498L219.115 28.0368H214.395L210.265 49.5601H213.805C214.124 49.5774 214.44 49.4859 214.701 49.3005C214.94 49.1032 215.099 48.8267 215.15 48.5216L216.696 40.4741H217.616L218.949 49.5601H223.02C223.143 49.5675 223.266 49.5457 223.379 49.4965C223.492 49.4472 223.591 49.372 223.669 49.2768C223.744 49.1608 223.793 49.0308 223.816 48.8949C223.838 48.759 223.832 48.6199 223.799 48.4863L221.994 38.6922Z"
        fill="white"
      />
      <path
        d="M226.242 49.56H235.859C236.179 49.5774 236.495 49.4858 236.756 49.3004C236.994 49.1032 237.153 48.8267 237.204 48.5216L237.771 45.5598H231.729L232.673 40.6274H236.06C236.357 40.6389 236.648 40.5472 236.885 40.3678C237.125 40.1547 237.304 39.882 237.405 39.5772L238.408 36.6272H233.452L234.325 32.0016H239.045C239.365 32.019 239.681 31.9274 239.942 31.742C240.065 31.6464 240.168 31.5273 240.245 31.3916C240.322 31.2559 240.371 31.1063 240.39 30.9514L240.933 28.0014H230.313L226.242 49.56Z"
        fill="white"
      />
      <path
        d="M242.053 32.037H245.876L242.478 49.56H246.018C246.338 49.5774 246.654 49.4858 246.915 49.3004C247.153 49.1032 247.312 48.8267 247.363 48.5216L250.561 32.0016H253.24C253.559 32.0171 253.875 31.9258 254.136 31.742C254.258 31.6454 254.358 31.5258 254.433 31.3902C254.508 31.2545 254.556 31.1054 254.573 30.9514L255.116 28.0014H242.797L242.053 32.037Z"
        fill="white"
      />
      <path
        d="M273.936 28.0368H260.956L263.753 30.9987L260.602 47.3063L262.785 49.6662H269.783L272.863 47.3063L273.748 42.7514C273.785 42.6208 273.794 42.4836 273.774 42.3492C273.753 42.2147 273.704 42.0863 273.63 41.9727C273.537 41.8738 273.423 41.7978 273.296 41.7507C273.169 41.7036 273.033 41.6867 272.898 41.7012H269.228L268.591 45.0289C268.559 45.1951 268.477 45.3476 268.355 45.4655C268.23 45.5713 268.07 45.6261 267.907 45.6189H266.184C266.114 45.6238 266.045 45.6137 265.98 45.5893C265.914 45.5648 265.855 45.5266 265.806 45.4772C265.774 45.4132 265.757 45.3425 265.757 45.2708C265.757 45.1991 265.774 45.1283 265.806 45.0643L268.166 32.6507C268.202 32.4832 268.289 32.3308 268.414 32.2141C268.539 32.1092 268.699 32.0546 268.862 32.0607H270.585C270.653 32.0558 270.721 32.066 270.784 32.0905C270.847 32.115 270.904 32.1532 270.951 32.2022C270.993 32.2616 271.02 32.3299 271.03 32.4017C271.04 32.4735 271.034 32.5466 271.01 32.6153L270.396 35.8249H273.936C274.257 35.8378 274.573 35.7422 274.833 35.5535C275.08 35.3632 275.241 35.0832 275.282 34.7747L276.119 30.3733L273.936 28.0368Z"
        fill="white"
      />
      <path
        d="M287.59 28.0368L285.902 36.816H282.634L284.321 28.0368H279.601L275.471 49.56H279.011C279.331 49.5755 279.646 49.4842 279.908 49.3004C280.15 49.108 280.307 48.8284 280.345 48.5216L281.796 40.9342H285.065L283.413 49.56H286.953C287.272 49.5755 287.588 49.4842 287.849 49.3004C288.091 49.108 288.248 48.8284 288.286 48.5216L292.227 28.0014L287.59 28.0368Z"
        fill="white"
      />
      <path
        d="M303.673 28.0369H297.844L290.528 49.5601H294.351C294.65 49.5758 294.946 49.4883 295.189 49.3123C295.426 49.1153 295.592 48.8458 295.661 48.5453L296.51 45.7133H299.531L299.224 49.5601H304.062L304.877 29.2051C304.89 29.0425 304.87 28.8789 304.817 28.7245C304.764 28.5701 304.68 28.4284 304.57 28.3083C304.447 28.2042 304.304 28.1264 304.149 28.0797C303.995 28.0331 303.833 28.0185 303.673 28.0369ZM299.838 41.9609H297.62L300.652 31.7657L299.838 41.9609Z"
        fill="white"
      />
      <path
        d="M315.285 28.0368H310.565L306.435 49.5601H314.872C315.191 49.5774 315.507 49.4859 315.768 49.3005C316.007 49.1032 316.166 48.8267 316.217 48.5216L316.748 45.5599H311.922L315.285 28.0368Z"
        fill="white"
      />
      <path
        d="M327.391 28.0368H322.671L318.6 49.5601H327.037C327.357 49.5774 327.673 49.4859 327.934 49.3005C328.172 49.1032 328.331 48.8267 328.382 48.5216L328.913 45.5599H324.028L327.391 28.0368Z"
        fill="white"
      />
      <path
        d="M344.808 30.9868L345.351 28.0368H334.731L330.648 49.5601H340.253C340.573 49.5774 340.889 49.4859 341.15 49.3005C341.396 49.1102 341.557 48.8302 341.598 48.5216L342.165 45.5599H336.135L337.079 40.6274H340.465C340.76 40.6457 341.05 40.5531 341.28 40.3679C341.523 40.1579 341.703 39.8841 341.799 39.5772L342.802 36.6272H337.846L338.719 32.0016H343.439C343.759 32.0209 344.076 31.9292 344.336 31.742C344.586 31.5616 344.755 31.2906 344.808 30.9868Z"
        fill="white"
      />
      <path
        d="M354.767 39.6834L352.75 28.0368H348.336L344.195 49.56H347.463C347.783 49.5774 348.099 49.4858 348.36 49.3004C348.606 49.1101 348.768 48.8302 348.808 48.5216L350.826 37.9016L352.655 48.5216C352.699 48.8125 352.836 49.0814 353.045 49.2886C353.26 49.4672 353.532 49.5633 353.812 49.56H357.257L361.399 28.0014H356.998L354.767 39.6834Z"
        fill="white"
      />
      <path
        d="M367.559 28.0368L364.479 30.3968L361.21 47.3298L363.393 49.6898H370.851L373.931 47.3298L375.854 37.4768H370.402C370.105 37.4653 369.813 37.557 369.576 37.7364C369.337 37.9495 369.157 38.2223 369.057 38.527L368.16 41.3H370.45L369.706 45.0288C369.67 45.1934 369.588 45.3446 369.47 45.4654C369.345 45.5712 369.185 45.626 369.022 45.6188H366.827C366.759 45.6236 366.691 45.6134 366.628 45.589C366.565 45.5645 366.508 45.5263 366.461 45.4772C366.427 45.4137 366.409 45.3428 366.409 45.2707C366.409 45.1987 366.427 45.1277 366.461 45.0642L368.821 32.6506C368.853 32.4843 368.936 32.3319 369.057 32.214C369.185 32.1129 369.343 32.0587 369.506 32.0606H371.547C371.615 32.0558 371.682 32.066 371.746 32.0905C371.809 32.115 371.866 32.1531 371.913 32.2022C371.947 32.2657 371.965 32.3367 371.965 32.4087C371.965 32.4808 371.947 32.5517 371.913 32.6152L371.311 35.8248H374.851C375.172 35.836 375.487 35.7405 375.748 35.5534C375.994 35.3631 376.155 35.0832 376.196 34.7746L377.034 30.3732L374.851 28.0132L367.559 28.0368Z"
        fill="white"
      />
      <path
        d="M380.999 28.0368L376.857 49.56H386.462C386.782 49.5793 387.099 49.4876 387.359 49.3004C387.605 49.1101 387.766 48.8302 387.807 48.5216L388.374 45.5598H382.32L383.264 40.6274H386.651C386.945 40.6456 387.235 40.553 387.465 40.3678C387.712 40.159 387.896 39.8851 387.996 39.5772L388.999 36.6272H384.031L384.916 32.0016H389.636C389.956 32.0171 390.271 31.9258 390.533 31.742C390.654 31.6454 390.755 31.5258 390.83 31.3902C390.905 31.2545 390.952 31.1054 390.969 30.9514L391.512 28.0014L380.999 28.0368Z"
        fill="white"
      />
      <path
        d="M128.443 21.2401L127.263 14.7501L126.083 21.2401C126.057 21.4343 125.956 21.6106 125.801 21.7317C125.647 21.8529 125.452 21.9093 125.257 21.8891H123.251L125.8 8.62591H128.502L129.753 15.7885L131.122 8.62591H133.824L131.275 21.8891H129.163C128.982 21.8957 128.806 21.8316 128.672 21.7104C128.537 21.5893 128.455 21.4206 128.443 21.2401Z"
        fill="white"
      />
      <path
        d="M140.42 20.4495L138.52 21.8773H134.225L132.892 20.4495L134.898 10.0419L136.786 8.61409H141.093L142.426 10.0419L141.907 12.7441C141.88 12.9383 141.779 13.1146 141.625 13.2357C141.471 13.3568 141.276 13.4133 141.081 13.3931H138.886L139.264 11.4225C139.264 11.2101 139.264 11.0803 139.004 11.0803H137.942C137.838 11.0737 137.735 11.1083 137.655 11.1767C137.576 11.245 137.526 11.3417 137.517 11.4461L136.054 19.0689C136.054 19.2813 136.054 19.4111 136.314 19.4111H137.376C137.427 19.416 137.479 19.4102 137.528 19.3941C137.577 19.3781 137.623 19.3521 137.662 19.3178C137.7 19.2835 137.732 19.2416 137.753 19.1948C137.775 19.1479 137.787 19.097 137.789 19.0453L138.19 17.0039H140.444C140.88 17.0039 141.045 17.2281 140.963 17.6529L140.42 20.4495Z"
        fill="white"
      />
      <path
        d="M149.86 9.35742L149.352 21.8772H146.32L146.508 19.5172H144.668L144.137 21.2636C144.105 21.4491 144.005 21.616 143.857 21.7317C143.708 21.8474 143.522 21.9035 143.334 21.889H140.974L145.47 8.62583H149.01C149.117 8.59872 149.228 8.59772 149.335 8.62291C149.442 8.6481 149.542 8.69873 149.625 8.77052C149.709 8.8423 149.773 8.93314 149.814 9.03531C149.855 9.13747 149.871 9.24796 149.86 9.35742ZM146.756 17.1808L147.252 10.915L145.387 17.1808H146.756Z"
        fill="white"
      />
      <path
        d="M158.876 9.35737L158.368 21.8772H155.395L155.583 19.5172H153.731L153.212 21.2636C153.175 21.4488 153.072 21.6144 152.922 21.7294C152.772 21.8445 152.586 21.9012 152.397 21.889H150.037L154.58 8.61398H158.12C158.222 8.60077 158.325 8.6108 158.421 8.64331C158.518 8.67582 158.606 8.72995 158.679 8.80159C158.752 8.87324 158.808 8.96052 158.842 9.05682C158.876 9.15312 158.887 9.2559 158.876 9.35737ZM155.76 17.1808L156.256 10.915L154.403 17.1808H155.76Z"
        fill="white"
      />
      <path
        d="M160.681 12.2366L160.221 11.7056L160.704 9.15683L161.318 8.69663H163.501L163.961 9.21583L163.477 11.7764L162.864 12.2366H160.681ZM163.407 9.36924C163.407 9.28664 163.407 9.21583 163.289 9.21583H161.353C161.259 9.21583 161.224 9.21583 161.2 9.35743L160.775 11.564C160.775 11.6584 160.775 11.7056 160.893 11.7056H162.84C162.935 11.7056 162.982 11.7056 162.994 11.5758L163.407 9.36924ZM162.557 11.3162H162.203L162.038 10.7144H161.884L161.802 11.1746C161.794 11.2133 161.774 11.2482 161.744 11.2738C161.714 11.2995 161.676 11.3144 161.637 11.3162H161.306L161.637 9.61703H162.604L162.864 9.90024L162.758 10.443L162.498 10.6672L162.675 11.151C162.683 11.1698 162.687 11.1903 162.684 11.2107C162.682 11.2311 162.675 11.2506 162.663 11.2673C162.651 11.2839 162.635 11.2972 162.617 11.3058C162.598 11.3144 162.577 11.318 162.557 11.3162ZM162.203 10.3486C162.286 10.3486 162.321 10.3486 162.333 10.2188V10.1244C162.333 10.03 162.333 10.0064 162.238 10.0064H162.026L161.955 10.3486H162.203Z"
        fill="white"
      />
      <path
        d="M179.939 8.61408L177.508 21.2401C177.484 21.4333 177.385 21.6096 177.233 21.7309C177.081 21.8523 176.887 21.9091 176.694 21.8891H174.782L176.599 12.4491L173.543 21.2401C173.496 21.4417 173.379 21.6201 173.212 21.7433C173.046 21.8665 172.841 21.9265 172.634 21.9127H170.192L170.593 12.4727L168.894 21.2401C168.867 21.4321 168.768 21.6065 168.616 21.7273C168.465 21.8481 168.273 21.906 168.079 21.8891H166.168L168.74 8.61408H172.28C172.367 8.60345 172.454 8.61324 172.536 8.64267C172.618 8.6721 172.691 8.72033 172.751 8.78349C172.811 8.84665 172.855 8.92297 172.88 9.00627C172.904 9.08958 172.909 9.17755 172.894 9.26308L172.528 17.9479L175.82 8.61408H179.939Z"
        fill="white"
      />
      <path
        d="M187.278 9.35736L186.771 21.8772H183.797L183.986 19.5172H182.133L181.614 21.2636C181.583 21.4491 181.483 21.616 181.334 21.7316C181.186 21.8473 181 21.9034 180.812 21.889H178.452L182.948 8.62577H186.488C186.591 8.60685 186.696 8.61217 186.797 8.64131C186.897 8.67044 186.989 8.7226 187.066 8.79361C187.143 8.86463 187.202 8.95256 187.239 9.05042C187.276 9.14829 187.289 9.25337 187.278 9.35736ZM184.175 17.1808L184.682 10.915L182.818 17.1808H184.175Z"
        fill="white"
      />
      <path
        d="M196.363 21.2401C196.387 21.3194 196.39 21.4032 196.374 21.4842C196.358 21.5652 196.322 21.641 196.269 21.705C196.217 21.7689 196.15 21.8191 196.074 21.8512C195.998 21.8833 195.915 21.8963 195.832 21.8891H193.472L192.67 17.0983H191.714L190.9 21.2401C190.873 21.4343 190.772 21.6106 190.618 21.7317C190.464 21.8529 190.269 21.9093 190.074 21.8891H187.879L190.428 8.62591H196.635L197.98 10.0537L196.906 15.6705L195.573 16.6735L196.363 21.2401ZM193.815 14.6321C193.917 14.6388 194.019 14.6059 194.098 14.54C194.177 14.4741 194.228 14.3803 194.239 14.2781L194.77 11.4343C194.77 11.2219 194.77 11.0921 194.511 11.0921H192.835L192.151 14.6321H193.815Z"
        fill="white"
      />
      <path
        d="M205.154 20.4495L203.255 21.8773H198.959L197.614 20.4495L199.62 10.0419L201.52 8.61409H205.815L207.16 10.0419L206.641 12.7441C206.615 12.9383 206.514 13.1146 206.36 13.2357C206.205 13.3568 206.01 13.4133 205.815 13.3931H203.62L203.986 11.4225C203.986 11.2101 203.986 11.0803 203.727 11.0803H202.676C202.624 11.0754 202.572 11.0812 202.522 11.0971C202.472 11.113 202.426 11.1388 202.386 11.1729C202.347 11.2071 202.314 11.2489 202.291 11.2958C202.268 11.3427 202.255 11.3938 202.252 11.4461L200.777 19.0689C200.777 19.2813 200.777 19.4111 201.036 19.4111H202.098C202.203 19.4176 202.306 19.383 202.385 19.3147C202.464 19.2463 202.514 19.1497 202.523 19.0453L202.912 17.0039H205.166C205.603 17.0039 205.768 17.2281 205.685 17.6529L205.154 20.4495Z"
        fill="white"
      />
      <path
        d="M210.287 16.5201L209.391 21.2401C209.364 21.4343 209.263 21.6106 209.109 21.7317C208.955 21.8529 208.76 21.9093 208.565 21.8891H206.37L208.919 8.62591H211.81L210.783 14.0303H212.789L213.816 8.62591H216.718L214.358 21.2401C214.332 21.4343 214.231 21.6106 214.077 21.7317C213.923 21.8529 213.727 21.9093 213.532 21.8891H211.338L212.352 16.5791L210.287 16.5201Z"
        fill="white"
      />
      <path
        d="M233.51 8.61409L231.079 21.2401C231.053 21.4343 230.952 21.6106 230.798 21.7317C230.643 21.8528 230.448 21.9093 230.253 21.8891H228.342L230.171 12.4491L227.103 21.2637C227.06 21.4656 226.946 21.6451 226.781 21.7689C226.616 21.8927 226.412 21.9521 226.206 21.9363H223.763L224.164 12.4963L222.465 21.2401C222.439 21.4343 222.338 21.6106 222.184 21.7317C222.029 21.8528 221.834 21.9093 221.639 21.8891H219.728L222.276 8.62589H225.816C225.903 8.61526 225.99 8.62505 226.072 8.65448C226.154 8.68391 226.228 8.73216 226.287 8.79532C226.347 8.85848 226.391 8.93478 226.416 9.01808C226.441 9.10139 226.446 9.18936 226.43 9.27489L226.064 17.9597L229.309 8.62589L233.51 8.61409Z"
        fill="white"
      />
      <path
        d="M240.85 9.35736L240.342 21.8772H237.369L237.557 19.5172H235.705L235.186 21.2636C235.154 21.4491 235.054 21.616 234.906 21.7316C234.757 21.8473 234.571 21.9034 234.383 21.889H232.023L236.519 8.62577H240.059C240.162 8.60685 240.268 8.61217 240.368 8.64131C240.469 8.67044 240.561 8.7226 240.637 8.79361C240.714 8.86463 240.773 8.95256 240.81 9.05042C240.847 9.14829 240.861 9.25337 240.85 9.35736ZM237.746 17.1808L238.254 10.915L236.389 17.1808H237.746Z"
        fill="white"
      />
      <path
        d="M251.517 10.0419L249.511 20.4495L247.611 21.8773H241.416L243.965 8.61409H250.16L251.517 10.0419ZM246.455 19.4111C246.507 19.4159 246.559 19.4102 246.609 19.3943C246.659 19.3784 246.705 19.3526 246.745 19.3184C246.784 19.2843 246.817 19.2425 246.84 19.1956C246.863 19.1486 246.876 19.0975 246.879 19.0453L248.354 11.4225C248.354 11.2101 248.354 11.0803 248.095 11.0803H246.407L244.791 19.4111H246.455Z"
        fill="white"
      />
      <path
        d="M256.19 21.2401L255.069 14.7501L253.83 21.2401C253.803 21.4343 253.702 21.6106 253.548 21.7317C253.394 21.8529 253.199 21.9093 253.004 21.8891H250.998L253.535 8.62591H256.249L257.429 15.7885L258.797 8.62591H261.511L258.963 21.8891H256.839C256.67 21.878 256.511 21.8061 256.392 21.6867C256.273 21.5673 256.201 21.4086 256.19 21.2401Z"
        fill="white"
      />
      <path
        d="M265.229 13.9241H268.273L267.659 15.7413C267.618 15.9274 267.513 16.0931 267.362 16.2096C267.211 16.326 267.024 16.3858 266.833 16.3785H264.745L264.167 19.4111H267.86L267.506 21.2283C267.479 21.4225 267.378 21.5988 267.224 21.7199C267.07 21.841 266.875 21.8975 266.68 21.8773H260.78L263.329 8.61409H269.819L269.488 10.4313C269.462 10.6255 269.361 10.8018 269.207 10.9229C269.053 11.044 268.857 11.1005 268.662 11.0803H265.748L265.229 13.9241Z"
        fill="white"
      />
      <path
        d="M277.536 15.3401L276.545 20.4495L274.657 21.8773H270.067L268.733 20.4495L269.288 17.5585H271.542C271.978 17.5585 272.144 17.7591 272.061 18.1957L271.896 19.0689C271.896 19.2931 271.896 19.4111 272.155 19.4111H273.501C273.605 19.4176 273.708 19.383 273.788 19.3147C273.867 19.2463 273.916 19.1497 273.925 19.0453L274.433 16.3785H271.223L269.878 14.9861L270.834 10.0419L272.734 8.61409H277.123L278.457 10.0419L278.091 12.0243C278.064 12.2185 277.963 12.3948 277.809 12.5159C277.655 12.637 277.46 12.6935 277.265 12.6733H275.07L275.294 11.4225C275.294 11.2101 275.294 11.0803 275.035 11.0803H273.855C273.75 11.0737 273.647 11.1083 273.568 11.1767C273.488 11.245 273.439 11.3417 273.43 11.4461L272.958 13.9241H276.179L277.536 15.3401Z"
        fill="white"
      />
      <path
        d="M286.74 15.3401L285.749 20.4495L283.861 21.8773H279.271L277.937 20.4495L278.492 17.5585H280.746C281.182 17.5585 281.347 17.7591 281.265 18.1957L281.1 19.0689C281.1 19.2931 281.1 19.4111 281.359 19.4111H282.704C282.809 19.4176 282.912 19.383 282.991 19.3147C283.071 19.2463 283.12 19.1497 283.129 19.0453L283.637 16.3785H280.427L279.082 14.9861L280.038 10.0419L281.937 8.61409H286.327L287.66 10.0419L287.295 12.0243C287.268 12.2185 287.167 12.3948 287.013 12.5159C286.859 12.637 286.664 12.6935 286.469 12.6733H284.274L284.498 11.4225C284.498 11.2101 284.498 11.0803 284.238 11.0803H283.058C283.007 11.0754 282.955 11.0812 282.906 11.0972C282.857 11.1133 282.811 11.1392 282.772 11.1735C282.734 11.2078 282.702 11.2497 282.681 11.2966C282.659 11.3435 282.647 11.3944 282.645 11.4461L282.162 13.9241H285.383L286.74 15.3401Z"
        fill="white"
      />
      <path
        d="M289.56 12.2366L289.088 11.7056L289.584 9.15683L290.186 8.69663H292.369L292.829 9.21583L292.345 11.7764L291.732 12.2366H289.56ZM292.274 9.36924C292.274 9.28664 292.274 9.21583 292.156 9.21583H290.28C290.186 9.21583 290.15 9.21583 290.139 9.35743L289.702 11.564C289.702 11.6584 289.702 11.7056 289.82 11.7056H291.767C291.861 11.7056 291.909 11.7056 291.92 11.5758L292.274 9.36924ZM291.083 11.3162L290.906 10.7144H290.764L290.67 11.1746C290.664 11.2142 290.644 11.2504 290.614 11.2765C290.583 11.3025 290.544 11.3166 290.504 11.3162H290.174L290.504 9.61703H291.46L291.72 9.90024L291.614 10.443L291.354 10.6672L291.531 11.151C291.539 11.1663 291.543 11.1831 291.543 11.2002C291.544 11.2173 291.541 11.2343 291.534 11.25C291.527 11.2657 291.517 11.2798 291.504 11.2912C291.492 11.3026 291.477 11.3112 291.46 11.3162H291.083ZM291.071 10.3486C291.165 10.3486 291.189 10.3486 291.201 10.2188V10.1244C291.201 10.03 291.201 10.0064 291.118 10.0064H290.894L290.823 10.3486H291.071Z"
        fill="white"
      />
      <path
        d="M317.219 0.295062L313.608 20.8271C313.592 20.9835 313.545 21.1352 313.47 21.2731C313.394 21.4111 313.292 21.5325 313.169 21.6304C313.046 21.7282 312.904 21.8005 312.753 21.8429C312.601 21.8853 312.443 21.8969 312.287 21.8773H309.16L311.862 6.43106L307.142 20.7917C307.074 21.1185 306.889 21.4092 306.622 21.6091C306.354 21.809 306.023 21.9044 305.691 21.8773H301.714L302.115 6.43106L299.59 20.8271C299.574 20.9835 299.527 21.1352 299.451 21.2731C299.376 21.4111 299.273 21.5325 299.15 21.6304C299.027 21.7282 298.886 21.8005 298.734 21.8429C298.583 21.8853 298.424 21.8969 298.268 21.8773H295.153L298.941 0.295062H304.77C304.91 0.276988 305.052 0.291621 305.185 0.337789C305.318 0.383957 305.438 0.460395 305.537 0.561092C305.635 0.661789 305.709 0.783973 305.752 0.918012C305.795 1.05205 305.806 1.19429 305.785 1.33347L305.419 15.4935L310.446 0.295062H317.219Z"
        fill="#009ADE"
      />
      <path
        d="M323.32 8.92083H328.288L327.332 11.8826C327.276 12.1876 327.111 12.4619 326.868 12.6549C326.625 12.8479 326.321 12.9466 326.011 12.9328H322.612L321.751 17.8652H327.793L327.273 20.827C327.257 20.9846 327.21 21.1374 327.134 21.2762C327.057 21.415 326.954 21.5371 326.829 21.6351C326.705 21.7331 326.562 21.805 326.409 21.8466C326.256 21.8882 326.097 21.8986 325.94 21.8772H316.323L320.122 0.295037H330.743L330.247 3.24504C330.231 3.40148 330.184 3.55315 330.108 3.6911C330.033 3.82906 329.93 3.95052 329.807 4.04836C329.684 4.14621 329.543 4.21845 329.391 4.26085C329.24 4.30324 329.081 4.31493 328.925 4.29524H324.205L323.32 8.92083Z"
        fill="#009ADE"
      />
      <path
        d="M337.728 20.827L335.722 10.207L333.869 20.827C333.853 20.9835 333.806 21.1351 333.73 21.2731C333.655 21.4111 333.552 21.5325 333.429 21.6304C333.306 21.7282 333.165 21.8004 333.013 21.8428C332.862 21.8852 332.703 21.8969 332.547 21.8772H329.279L333.067 0.295037H337.48L339.698 11.9416L341.74 0.295037H346.141L342.353 21.8772H338.896C338.606 21.8761 338.327 21.7684 338.111 21.5748C337.896 21.3811 337.759 21.115 337.728 20.827Z"
        fill="#009ADE"
      />
      <path
        d="M347.415 7.08005L348.926 0.306848H353.646L350.932 6.06525C350.83 6.4083 350.605 6.70164 350.3 6.88895C349.995 7.07625 349.632 7.14431 349.28 7.08005H347.415Z"
        fill="#009ADE"
      />
      <path
        d="M365.635 11.2336L364.16 19.5644L361.104 21.9244H353.646L351.428 19.5644L352.254 14.8444H355.924C356.632 14.8444 356.915 15.1866 356.785 15.8946L356.537 17.3106C356.537 17.6764 356.596 17.8652 356.974 17.8652H359.157C359.241 17.8732 359.326 17.8642 359.406 17.8386C359.487 17.8131 359.561 17.7714 359.625 17.7163C359.689 17.6612 359.741 17.5937 359.778 17.5179C359.816 17.442 359.837 17.3595 359.841 17.2752L360.608 12.9328H355.369L353.151 10.62L354.567 2.57236L357.623 0.212356H364.774L366.992 2.57236L366.438 5.80556C366.421 5.96201 366.374 6.11367 366.299 6.25163C366.223 6.38958 366.121 6.51105 365.998 6.60889C365.875 6.70673 365.733 6.77898 365.582 6.82137C365.43 6.86377 365.272 6.87546 365.116 6.85576H361.576L361.906 4.81437C361.965 4.48397 361.847 4.25977 361.482 4.25977H359.594C359.51 4.25335 359.427 4.26362 359.348 4.28996C359.269 4.3163 359.196 4.35819 359.133 4.41315C359.07 4.46812 359.019 4.53506 358.983 4.61004C358.946 4.68502 358.925 4.76653 358.921 4.84977L358.213 8.88536H363.44L365.635 11.2336Z"
        fill="#009ADE"
      />
      <path
        d="M116.171 57.8908H386.391L386.993 54.575H116.82L116.171 57.8908ZM127.44 0.153442L126.803 3.55183H294.505L295.118 0.153442H127.44Z"
        fill="#009ADE"
      />
    </svg>
  );
});

MBCGHorizontalDark.displayName = "MBCGHorizontalDark";

export default MBCGHorizontalDark;
