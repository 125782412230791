import useGlobalState from "@hooks/useGlobalState";
import { Button } from "@styles/buttons.styled";
import React from "react";
import styled from "styled-components";
import { MMLModal } from "@f2p-mml-frontends/mml-components";
import useSignOut from "@hooks/dalton/useSignOut";

const Title = styled.div`
  font-weight: 900;
  font-size: 20px;
  text-align: center;
  line-height: normal;
  margin-bottom: 3rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;

  button {
    flex: 1 1 50%;
  }
`;

export default function SignOut() {
  const [state, dispatch] = useGlobalState();
  const { signOut } = useSignOut();

  const closeModal = () =>
    dispatch((state) => {
      state.modal.openModal = null;
    });

  const handleSignout = async () => {
    await signOut();
    closeModal();
  };

  return (
    <MMLModal maxWidth={450} isOpen={state.modal.openModal === "CONFIRM_SIGN_OUT"} handleClose={() => closeModal()}>
      <Title>Sign Out?</Title>
      <ButtonGroup>
        <Button size="md" $secondary onClick={closeModal}>
          Cancel
        </Button>
        <Button size="md" onClick={handleSignout}>
          Yes
        </Button>
      </ButtonGroup>
    </MMLModal>
  );
}
