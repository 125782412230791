import { Checkbox } from "@components/forms/Checkbox";
import { VisibilityToggle } from "@components/forms/VisibilityToggle";
import SwitchComponent from "@components/SwitchComponent";
import ThemeChanger from "@components/ThemeChanger";
import useGlobalState from "@hooks/useGlobalState";
import AutoPickOn from "@icons/glyphs-bcg-18-px-autopick-on.svg";
import ClearOff from "@icons/glyphs-bcg-18-px-clear-off.svg";
import FacebookOn from "@icons/glyphs-bcg-18-px-fb-on.svg";
import AddBracketOn from "@icons/glyphs-bcg-add-bracket-on.svg";
import AddedOrange from "@icons/glyphs-bcg-added-orange.svg";
import ClearOff32px from "@icons/glyphs-bcg-clear-off.svg";
import ClearOn32px from "@icons/glyphs-bcg-clear-on.svg";
import EditOff from "@icons/glyphs-bcg-edit-off.svg";
import EditOn from "@icons/glyphs-bcg-edit-on.svg";
import ImportOff from "@icons/glyphs-bcg-import-off.svg";
import ImportOn from "@icons/glyphs-bcg-import-on.svg";
import IncompleteOff from "@icons/glyphs-bcg-incomplete-off.svg";
import IncompleteOn from "@icons/glyphs-bcg-incomplete-on.svg";
import LinkOff from "@icons/glyphs-bcg-link-off.svg";
import ManageBracketsOff from "@icons/glyphs-bcg-manage-brackets-off.svg";
import TabPrintOff from "@icons/glyphs-tab-bar-print-off.svg";
import LockButton from "@icons/LockButton.svg";
import { Button } from "@styles/buttons.styled";
import { Input } from "@styles/inputs.styled";
import { rootFontSize } from "@styles/styleUtils";
import { GetServerSideProps } from "next";
import styled from "styled-components";
import settings from "../settings";
import { MMLModal } from "@f2p-mml-frontends/mml-components";
import ApplicationError from "@components/Modal/ApplicationError";
import SignOut from "@components/Modal/SignOut";
import Cookies from "js-cookie";
import useJavaScriptBridge from "@hooks/useJavaScriptBridge";
import { useRouter } from "next/router";

const {
  APP_ENV,
  ENV_NAMES: { PROD },
} = settings;

/**
 * A quick helper allowing us to commit console.debug calls without them appearing in production.
 */
export const debug = (...args) => {
  if (APP_ENV !== PROD) {
    // eslint-disable-next-line
    console?.debug?.(args);
  }
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0 100px;
  padding: 1rem;
  .btn-container {
    display: flex;
    width: 100%;
  }
  .row {
    width: 100%;
    flex: 1;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 50px;
  }
`;

export default function Index() {
  const [, dispatch] = useGlobalState();
  const { fireAction } = useJavaScriptBridge();
  const router = useRouter();

  return (
    <>
      <PageContainer>
        <h3>DEBUG</h3>
        <a href={"/"}>&larr; Back</a>
        <code>APP_ENV: {settings.APP_ENV}</code>
        <code>API_BACKEND_URL: {settings.API_BACKEND_URL}</code>
        <hr style={{ width: "50%", margin: "5rem 0" }} />
        <div className="btn-container">
          <div className="row">
            <ThemeChanger />
          </div>
          <div className="row">
            <h3>Global Loader</h3>
            <Button
              onClick={async () => {
                dispatch((state) => {
                  state.isLoading = true;
                });
                await new Promise((resolve) => {
                  setTimeout(resolve, 3000);
                });
                dispatch((state) => {
                  state.isLoading = false;
                });
              }}
            >
              Trigger Loader
            </Button>
          </div>
          <div className="row">
            <h3>Modal</h3>
            <Button
              onClick={() => {
                dispatch((state) => {
                  state.modal.openModal = "DEBUG";
                });
              }}
            >
              Demo Modal
            </Button>
          </div>
        </div>
        <h3>Misc Modals</h3>
        <br />
        <div className="btn-container">
          <div className="row">
            <Button
              onClick={() => {
                dispatch((state) => {
                  state.modal.openModal = "APPLICATION_ERROR";
                });
              }}
            >
              App Error
            </Button>
          </div>
          <div className="row">
            <Button
              onClick={() => {
                dispatch((state) => {
                  state.modal.openModal = "CONFIRM_SIGN_OUT";
                });
              }}
            >
              Sign Out
            </Button>
          </div>
        </div>

        <h1>Header 1</h1>
        <h2>Header 2</h2>
        <h3>Header 3</h3>
        <p>This is a p tag.</p>
        <a>This is an a tag</a>
        <code>1rem = {rootFontSize}px</code>
        <div className="btn-container">
          <div className="row">
            <h3>Input</h3>
            <Input placeholder="Enter Email" />
          </div>
          <div className="row">
            <h3>Disabled Input</h3>
            <Input placeholder="Enter Email" disabled />
          </div>
          <div className="row">
            <h3>Errored Input</h3>
            <Input className="error" placeholder="Enter Email" />
          </div>
        </div>
        <div className="btn-container">
          <div className="row">
            <h3>BCG Buttons</h3>
            <Button>BCG btn</Button>
            <br />
            <Button size="md">BCG btn md</Button>
            <br />
            <Button size="sm">BCG btn sm</Button>
            <br />
            <Button size="xsm">BCG btn xsm</Button>
            <br />
            <Button size="xsm" disabled>
              BCG disabled
            </Button>
          </div>
          <div className="row">
            <h3>Primary Buttons</h3>
            <Button $primary>Primary btn</Button>
            <br />
            <Button size="md" $primary>
              Primary btn md
            </Button>
            <br />
            <Button size="sm" $primary>
              Primary btn sm
            </Button>
            <br />
            <Button size="xsm" $primary>
              Primary btn xsm
            </Button>
            <br />
            <Button size="xsm" $primary disabled>
              Primary disabled
            </Button>
          </div>
          <div className="row">
            <h3>Secondary Buttons</h3>
            <Button $secondary>Secondary btn</Button>
            <br />
            <Button $secondary size="md">
              Secondary btn md
            </Button>
            <br />
            <Button $secondary size="sm">
              Secondary btn sm
            </Button>
            <br />
            <Button $secondary size="xsm">
              Secondary btn xsm
            </Button>
            <br />
            <Button size="xsm" $secondary disabled>
              Secondary disabled
            </Button>
          </div>
        </div>
        <div className="btn-container">
          <div className="row">
            <h3>Main Switch</h3>
            <SwitchComponent />
          </div>
          <div className="row">
            <h3>Secondary Switch</h3>
            <SwitchComponent secondary={true} />
          </div>
        </div>
        <div className="btn-container">
          <div className="row">
            <h3>Checkbox</h3>
            <Checkbox />
          </div>
          <div className="row">
            <h3>Visibility Toggle</h3>
            <VisibilityToggle />
          </div>
        </div>
        <div className="row"></div>
        <div className="row">
          <h3>Icons</h3>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <ClearOff32px />
            <ClearOn32px />
            <AddBracketOn />
            <ImportOff />
            <ImportOn />
            <EditOff />
            <EditOn />
            <IncompleteOff />
            <IncompleteOn />
            <FacebookOn />
            <AutoPickOn />
            <ClearOff />
            <TabPrintOff />
            <ManageBracketsOff />
            <LinkOff />
            <AddedOrange />
            <LockButton />
          </div>
        </div>
        <div className="row">
          <h3>LocalStorage</h3>
          <div>
            <pre style={{ maxHeight: "400px", overflow: "scroll", width: "80vw" }}>
              {JSON.stringify(localStorage, null, 2)}
            </pre>
          </div>
        </div>
        <div className="row">
          <h3>ROUTER</h3>
          <div>
            <pre style={{ maxHeight: "400px", overflow: "scroll", width: "80vw" }}>{JSON.stringify(router)}</pre>
          </div>
        </div>
        <div className="row">
          <h3>Cookies</h3>
          <div>
            <pre style={{ maxHeight: "400px", overflow: "scroll", width: "80vw" }}>
              {JSON.stringify(Cookies.get(), null, 2)}
            </pre>
          </div>
        </div>
        <div className="row">
          <h3>Fire Bridge Actions</h3>
          <div>
            <Button
              onClick={() => {
                const returnUrl = new URL(window.location.href);
                fireAction({
                  action: "navigate",
                  returnUrl: returnUrl.href,
                });
              }}
            >
              Navigate
            </Button>

            <Button
              onClick={() => {
                fireAction({ action: "sign_out" });
              }}
            >
              Sign Out
            </Button>
          </div>
        </div>
      </PageContainer>
      <DemoModal />
      <ApplicationError />
      <SignOut />
    </>
  );
}

// Prevent access to /debug from production.
export const getServerSideProps: GetServerSideProps = async () => {
  if (APP_ENV === PROD) {
    return {
      redirect: {
        permanent: false,
        destination: "/",
      },
    };
  }

  return {
    props: {},
  };
};

function DemoModal() {
  const [state, dispatch] = useGlobalState();
  return (
    <MMLModal
      isOpen={state.modal.openModal === "DEBUG"}
      title="Test Modal Content"
      handleClose={() =>
        dispatch((state) => {
          state.modal.openModal = null;
        })
      }
    >
      <p style={{ margin: "20px 0 8px" }}>Name:</p>
      <Input />
    </MMLModal>
  );
}
