import * as React from "react";
import { styled } from "styled-components";

const LibraryStyleWrapper = styled.div`
  font-size: 10px;
`;

type LibraryWrapperProps = {};
/** Wraps exported components to properly apply a root font size. */
export const LibraryWrapper = ({ children }: React.PropsWithChildren<LibraryWrapperProps>) => {
  return <LibraryStyleWrapper>{children}</LibraryStyleWrapper>;
};
