import { breakpoints, colors, pxToEm } from "../../styles/styleUtils";
import { styled } from "styled-components";
import { LibraryWrapper } from "../common/LibraryWrapper";
import * as Popover from "@radix-ui/react-popover";
import { slideAndFade } from "../../styles/animations.styled";
import Close from "../../assets/icons/Close";
import { TooltipProps } from "./Tooltip";
import { Align, Position } from "./Tooltip";
import { useEffect, useState } from "react";

interface TooltipTriggerAndContentProps extends TooltipProps {
  setIsOpen: (isOpen: boolean) => void;
}

const StyledContent = styled(Popover.Content)<{
  $styledPosition: Position;
  $styledWidth?: number;
}>`
  animation-duration: 350ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-name: ${({ $styledPosition }) => slideAndFade[$styledPosition || "top"]};
  background-color: ${colors["lvl_-5"]};
  border-radius: 4px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  color: ${colors.lvl_5};
  font-size: ${pxToEm(13)};
  padding: 8px 12px;
  position: relative;
  will-change: transform, opacity;
  width: ${({ $styledWidth }) => ($styledWidth ? `${$styledWidth}` : "250")}px;
  box-sizing: content-box;
`;

const StyledArrow = styled(Popover.Arrow)`
  fill: ${colors["lvl_-5"]};
`;

const Title = styled.p`
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  height: 12px;

  @media (max-width: ${breakpoints.svp}) {
    height: 18px;
    margin-bottom: 0px;
  }
`;

const StyledClose = styled.div`
  position: absolute;
  right: 12px;
  color: ${colors["lvl_5"]};
  cursor: pointer;
`;

const TooltipBody = styled.div`
  line-height: 1.25;
  letter-spacing: 0.4px;
  font-size: 12px;
`;

export const MMLTooltipPortalAndContent = ({
  content,
  title,
  width,
  preventInteractOutsideClose,
  position = "top",
  align = "end",
  setIsOpen,
}: TooltipTriggerAndContentProps) => {
  // For any elements that should not cause the tooltip to close interact outside, mimic the "terms" click functionality here
  const [termsClick, setTermsClick] = useState(false);
  const containingElement = typeof document !== "undefined" && document?.querySelector("#updated-terms-container");

  useEffect(() => {
    // Prevents the tooltip to close with the terms and services.
    if (containingElement !== null) {
      document.addEventListener("click", function (event) {
        const clickedElement = event.target;
        if (containingElement && containingElement?.contains(clickedElement as Node)) {
          setTermsClick(true);
        } else {
          setTermsClick(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document, containingElement, termsClick]);

  function determineAlignOffset(align: Align) {
    // Figma mocks are 9px off each side (unless centered)
    switch (align) {
      case "start":
        return 9;
      case "center":
        return 0;
      case "end":
        return -9;
      default:
        return 0;
    }
  }

  return (
    <Popover.Portal>
      <StyledContent
        sideOffset={2}
        side={position}
        align={align}
        alignOffset={determineAlignOffset(align)}
        $styledPosition={position}
        collisionPadding={2}
        $styledWidth={width}
        onInteractOutside={(e) => {
          if (preventInteractOutsideClose || termsClick) {
            e.preventDefault();
          }
        }}
      >
        <StyledClose
          onClick={(event) => {
            setIsOpen(false);
            event.stopPropagation();
          }}
        >
          <Close />
        </StyledClose>
        <StyledArrow width={6} height={6} />
        <LibraryWrapper>
          {title ? <Title>{title}</Title> : null}
          <TooltipBody>{content}</TooltipBody>
        </LibraryWrapper>
      </StyledContent>
    </Popover.Portal>
  );
};
