import { LibraryWrapper } from "../common/LibraryWrapper";
import {
  ActionRowItem,
  ActionsRow,
  EntryButton,
  EntryCardContainer,
  EntryGroupsGrid,
  EntryHeaderRow,
  EntryInfo,
  EntryTitle,
  EntryTitleWrapper,
  InfoButtons,
  ProgressContainer,
  ProgressText,
  ProgressWrapper,
  IncompleteTag,
  EntryName,
  GameTypeChipWrapper,
  EntryGroupsExpansionContainer,
  SeeMoreGroupsButton,
  IncompleteContainer,
  SeeMoreGroupsContainer,
  EntryLink,
} from "./EntryCard.styled";
import { MMLGroupCard } from "../GroupCard/GroupCard";
import FavoriteOff from "../../assets/icons/FavoriteOff";
import FavoriteOn from "../../assets/icons/FavoriteOn";
import SettingsOff from "../../assets/icons/SettingsOff";
import LiveActivityOn from "../../assets/icons/LiveActivityOn";
import LiveActivityOff from "../../assets/icons/LiveActivityOff";
import { IconButton } from "../../styles/buttons.styled";
import { MMLGameTypeChip } from "../foundations/GameTypeChip";
import { useState, useLayoutEffect, useEffect } from "react";
import { GroupData } from "../../types/gameData.types";
import useWindowSize from "../../hooks/useWindowResize";
import { MMLProgressBar } from "../foundations/ProgressBar";
import ArrowDown from "../../assets/icons/Caret";

type EntryAction = {
  /** Button title */
  title: string;
  /** Button action */
  handleClick: () => void;
  /** (Optional) ARIA title, if it differs from button title. */
  ariaTitle?: string;
  /** (Optional) disabled state for button */
  disabled?: boolean;
};

type EntryCardProps = {
  /** (Optional) Conference Tournament Pick'em game type (Confidence or standard). */
  ctpEntryType?: "c" | "s";
  /** List of actions that will be converted to buttons. */
  entryActions?: EntryAction[];
  /** Entry name, mapped to card title row. */
  entryName: string;
  /** Entry path */
  entryPath: string;
  /** base bath for the given game */
  basePath: string;
  /** Percent of entry completed. */
  entryCompletePct?: number;
  /** Group data to display to user */
  groupData: GroupData[];
  /** Callback to handle Live activity button click */
  handleLiveActivityClick: () => void | null;
  /** Callback to handle settings button click. */
  handleSettingsButtonClick: () => void;
  /** Callback to handle star/favorite button click. */
  handleStarButtonClick: () => void;
  /** Callback to handle behavior to run in lieu of redirecting to entryPath */
  handleBlockRedirect?: () => void | null;
  /** If live "LIVE_ACTIVITY" modal is active */
  liveActivityEnabled: boolean;
  /** Whether the entry is favorited. */
  isFavorite?: boolean;
  /** Game-specific data. See guidelines in README. */
  statusRow?: React.ReactNode;
  /** boolean to show the incomplete tag for TR */
  isIncomplete?: boolean;
  /** boolean to show live activity icon */
  showLiveActivityIcon: boolean;
  /** The users ID */
  userId: string;
};

/**
 * A Card element that displays common and game-specific data
 * for a user's entries across all MML games.
 */
export const MMLEntryCard = ({
  ctpEntryType,
  entryActions,
  userId,
  entryName,
  entryPath,
  basePath,
  entryCompletePct,
  groupData,
  handleLiveActivityClick,
  handleStarButtonClick,
  handleSettingsButtonClick,
  handleBlockRedirect,
  liveActivityEnabled = false,
  isFavorite = false,
  statusRow,
  isIncomplete = false,
  showLiveActivityIcon = false,
}: EntryCardProps) => {
  const windowSize = useWindowSize();
  const isSvp = window.matchMedia(`(max-width: 640px)`).matches;
  const [groupListExpanded, setGroupListExpanded] = useState(false);
  const [innerHeight, setInnerHeight] = useState(500);
  const [buttonAnimation, setButtonAnimation] = useState(false);

  useLayoutEffect(() => {
    const gridHeight = document?.getElementById(`${entryId}-group-list`)?.offsetHeight || 500;
    setInnerHeight(gridHeight);
  }, [windowSize]);

  useEffect(() => {
    if (groupListExpanded && !buttonAnimation) {
      const timer = setTimeout(() => {
        setButtonAnimation(true);
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [groupListExpanded]);

  const entryId = entryPath.split("/").at(-1);

  const getTabIndexForGroupCard = (index: number) => {
    if (index <= 3) {
      return 0;
    } else {
      if (groupListExpanded) {
        return 0;
      } else {
        return -1;
      }
    }
  };

  return (
    <LibraryWrapper>
      {isIncomplete && (
        <IncompleteContainer>
          <IncompleteTag>Incomplete</IncompleteTag>
        </IncompleteContainer>
      )}
      <EntryCardContainer>
        <EntryHeaderRow>
          <EntryTitleWrapper>
            <EntryTitle>
              {handleBlockRedirect ? (
                <EntryName onClick={handleBlockRedirect}>{entryName}</EntryName>
              ) : (
                <EntryLink href={entryPath}>{entryName}</EntryLink>
              )}
              {ctpEntryType !== undefined && (
                <GameTypeChipWrapper>
                  <MMLGameTypeChip gameType={ctpEntryType} />
                </GameTypeChipWrapper>
              )}
            </EntryTitle>
          </EntryTitleWrapper>
          <EntryInfo>
            <InfoButtons>
              {showLiveActivityIcon && (
                <IconButton
                  onClick={(e) => {
                    handleLiveActivityClick();
                    e.stopPropagation();
                  }}
                  aria-label={"Live Activity button"}
                  className="mml-entry-live-activity-button"
                >
                  {liveActivityEnabled ? <LiveActivityOn /> : <LiveActivityOff />}
                </IconButton>
              )}

              <IconButton
                onClick={(e) => {
                  handleStarButtonClick();
                  e.stopPropagation();
                }}
                aria-label={isFavorite ? "Remove favorite" : "Set as favorite"}
                className="mml-entry-favorite-button"
              >
                {isFavorite ? <FavoriteOn /> : <FavoriteOff />}
              </IconButton>
              <IconButton
                onClick={(e) => {
                  handleSettingsButtonClick();
                  e.stopPropagation();
                }}
                aria-label="Entry Settings button"
                className="mml-entry-settings-button"
              >
                <SettingsOff />
              </IconButton>
            </InfoButtons>
            {entryCompletePct !== undefined && (
              <ProgressContainer>
                <ProgressText>{entryCompletePct}% PICKED</ProgressText>
                <ProgressWrapper>
                  <MMLProgressBar
                    backgroundFillColor="#000000000"
                    hasFillColorBorder={true}
                    pctFilled={entryCompletePct}
                  ></MMLProgressBar>
                </ProgressWrapper>
              </ProgressContainer>
            )}
          </EntryInfo>
        </EntryHeaderRow>
        {statusRow}
        {entryActions && entryActions.length > 0 && (
          <ActionsRow $actionsLength={entryActions.length}>
            {entryActions.map((entryAction, index) => {
              return (
                <ActionRowItem key={`${entryName}-action-${index}`}>
                  <EntryButton
                    disabled={entryAction.disabled}
                    size={isSvp ? "sm" : "md"}
                    $primary={index === 0} // Leftmost button is always primary in design
                    $secondary={index > 0}
                    aria-label={entryAction.ariaTitle || entryAction.title}
                    onClick={(e) => {
                      entryAction.handleClick();
                      e.stopPropagation();
                    }}
                  >
                    {entryAction.title}
                  </EntryButton>
                </ActionRowItem>
              );
            })}
            {entryActions.length < 2 && <ActionRowItem />}
          </ActionsRow>
        )}
        {groupData.length ? (
          <EntryGroupsExpansionContainer
            className={groupListExpanded ? "is-expanded" : ""}
            $groupListMaxHeight={innerHeight}
          >
            <EntryGroupsGrid id={`${entryId}-group-list`}>
              {groupData.map((groupDataItem, i) => (
                <MMLGroupCard
                  basePath={basePath}
                  key={`${entryName}-group-${groupDataItem.id}`}
                  groupData={groupDataItem}
                  tabIndex={getTabIndexForGroupCard(i)}
                  id={`${entryId}-group-${i}`}
                  isCommissioner={groupDataItem.creatorId === userId}
                />
              ))}
            </EntryGroupsGrid>
          </EntryGroupsExpansionContainer>
        ) : null}
        {groupData.length > 4 && (
          <SeeMoreGroupsContainer>
            <SeeMoreGroupsButton
              id={`${entryId}-see-more-button`}
              $secondary
              size="xsm"
              onClick={(e) => {
                setGroupListExpanded(!groupListExpanded);
                e.stopPropagation();
              }}
              className={`${buttonAnimation ? "animate" : "initial"} ${groupListExpanded ? "is-expanded" : ""}`.trim()}
            >
              See {groupListExpanded ? "fewer" : "more"} groups
              <ArrowDown />
            </SeeMoreGroupsButton>
          </SeeMoreGroupsContainer>
        )}
      </EntryCardContainer>
    </LibraryWrapper>
  );
};
