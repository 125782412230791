import * as React from "react";

export default function FavoriteOff() {
  return (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="28/Play-Favourite-Stroke">
        <g id="Icon">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5699 25.25C20.7831 25.25 25.8199 20.2132 25.8199 14C25.8199 7.7868 20.7831 2.75 14.5699 2.75C8.35665 2.75 3.31985 7.7868 3.31985 14C3.31985 20.2132 8.35665 25.25 14.5699 25.25ZM14.5699 26C21.1973 26 26.5699 20.6274 26.5699 14C26.5699 7.37258 21.1973 2 14.5699 2C7.94244 2 2.56985 7.37258 2.56985 14C2.56985 20.6274 7.94244 26 14.5699 26Z"
            fill="var(--color_lvl_-5)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.1368 8.80048C14.2843 8.39984 14.8554 8.39984 15.003 8.80048L16.2791 12.266L20.1188 12.3556C20.5583 12.3659 20.7354 12.9225 20.3815 13.1812L17.3443 15.4017L18.4367 18.9055C18.5649 19.3166 18.1021 19.6599 17.7409 19.4216L14.5699 17.3297L11.3988 19.4216C11.0377 19.6599 10.5748 19.3166 10.703 18.9055L11.7954 15.4017L8.75822 13.1812C8.40431 12.9225 8.58142 12.3659 9.0209 12.3556L12.8606 12.266L14.1368 8.80048Z"
            fill="var(--color_lvl_-5)"
          />
        </g>
      </g>
    </svg>
  );
}
