import { GameNav } from "./HeaderTypes";

export const gameNavItems: GameNav[] = [
  {
    title: "play",
    href: "/dashboard",
    abbreviation: "play",
  },
  {
    title: "Men's Bracket Challenge",
    href: "/mens-bracket-challenge/dashboard",
    abbreviation: "mbcg",
  },
  {
    title: "Women's Bracket Challenge",
    href: "/womens-bracket-challenge/dashboard",
    abbreviation: "wbcg",
  },
];
