const LiveActivityOff = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.3679 12.4511C22.3407 12.3152 22.4495 12.1793 22.5855 12.1522C22.7215 12.1522 22.8575 12.2337 22.8847 12.3967C22.9663 12.913 23.0207 13.4565 23.0207 14C23.0207 19.5163 18.5324 24 13.0104 24C7.48834 24 3 19.5163 3 14C3 8.4837 7.48834 4 13.0104 4C14.1801 4 15.3225 4.19022 16.4378 4.59783C16.5738 4.65217 16.6554 4.81522 16.601 4.95109C16.5466 5.08696 16.3834 5.16848 16.2474 5.11413C15.2137 4.7337 14.1256 4.54348 13.0104 4.54348C7.78756 4.54348 3.54404 8.78261 3.54404 14C3.54404 19.2174 7.78756 23.4565 13.0104 23.4565C18.2332 23.4565 22.4767 19.2174 22.4767 13.9728C22.4767 13.4565 22.4495 12.9402 22.3679 12.4511Z"
        fill="var(--color_lvl_-5, #17171d)"
      />
      <path
        d="M23.7387 9.08639C24.4746 7.31159 23.631 5.27686 21.8543 4.5417C20.0777 3.80653 18.0408 4.64933 17.3049 6.42413C16.569 8.19893 17.4127 10.2337 19.1893 10.9688C20.966 11.704 23.0028 10.8612 23.7387 9.08639Z"
        fill="var(--color_lvl_-5, #17171d)"
      />
      <path
        d="M12.82 6.63584C12.7656 6.69019 12.7384 6.74454 12.7384 6.82606V14.3261H6.97159C6.88998 14.3261 6.80837 14.3532 6.75397 14.4076C6.69957 14.4619 6.67236 14.5163 6.67236 14.5978C6.67236 14.6793 6.69957 14.7608 6.75397 14.8152C6.80837 14.8695 6.88998 14.8967 6.97159 14.8967H13.0104C13.0921 14.8967 13.1737 14.8695 13.2281 14.8152C13.2825 14.7608 13.3097 14.6793 13.3097 14.5978V6.82606C13.3097 6.74454 13.2825 6.69019 13.2281 6.63584C13.1737 6.5815 13.0921 6.55432 13.0104 6.55432C12.9288 6.55432 12.8744 6.5815 12.82 6.63584Z"
        fill="var(--color_lvl_-5, #17171d)"
      />
    </svg>
  );
};

export default LiveActivityOff;
