import { SVGProps, forwardRef } from "react";

const WBCGHorizontalLight = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  const { className, ...rest } = props;
  return (
    <svg
      ref={ref}
      width="392"
      height="59"
      viewBox="0 0 392 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <g clipPath="url(#clip0_693_15149)">
        <path
          d="M367.637 12.0634L366.448 18.5555H375.178L374.968 19.5812L374.607 21.3645L374.513 21.854H387.789L388.22 19.5812H377.299L378.103 15.2687H389.036L389.467 12.9958H376.203L375.597 16.2943H369.117L371.087 5.55966H377.567L376.973 8.8465H390.248L390.68 6.58534H379.688L380.481 2.27282H391.507L391.927 0H378.896L378.873 0.12821L378.441 2.27282L378.232 3.2985H369.268L368.056 9.7906H364.956L364.525 12.0634H367.637Z"
          fill="#EC7700"
        />
        <path
          d="M140.693 37.9036L141.882 31.3998H133.164L133.362 30.3741L133.723 28.5909L133.816 28.1013H120.541L120.11 30.3741H131.031L130.238 34.6867H119.294L118.862 36.9595H132.126L132.732 33.6726H139.213L137.243 44.3957H130.763L131.369 41.1088H118.081L117.65 43.37H128.641L127.849 47.6825H116.834L116.403 49.9553H129.446L129.469 49.8271L129.9 47.6825L130.098 46.6685H139.061L140.274 40.1647H143.386L143.805 37.9036H140.693Z"
          fill="#EC7700"
        />
        <path
          d="M161.159 30.549L159.003 28.2179H145.821L148.548 31.1085L144.946 49.6523H154.959L158.036 47.3212L159.318 40.6776L157.395 38.6962L159.947 36.7964L161.159 30.549ZM154.539 41.2604L153.793 45.1184C153.757 45.2837 153.672 45.4343 153.548 45.5496C153.488 45.603 153.418 45.6438 153.342 45.6699C153.266 45.6959 153.186 45.7065 153.105 45.7011H150.413L151.392 40.7126H154.119C154.188 40.7042 154.258 40.7125 154.323 40.7369C154.388 40.7612 154.446 40.8009 154.492 40.8525C154.532 40.912 154.556 40.98 154.564 41.0509C154.572 41.1217 154.564 41.1935 154.539 41.2604ZM155.996 32.7869L155.401 36.132C155.38 36.3188 155.293 36.492 155.157 36.6215C155.023 36.7314 154.852 36.7856 154.679 36.773H152.138L153.024 32.274H155.623C155.694 32.263 155.767 32.2716 155.833 32.2987C155.9 32.3258 155.957 32.3704 156.001 32.4279C156.044 32.4853 156.07 32.5534 156.078 32.6249C156.085 32.6963 156.073 32.7684 156.042 32.8335L155.996 32.7869Z"
          fill="#003A5D"
        />
        <path
          d="M176.685 30.549L174.529 28.2179H164.482L160.321 49.6523H163.817C164.134 49.6652 164.446 49.5707 164.703 49.3843C164.825 49.2898 164.927 49.1722 165.003 49.0382C165.079 48.9041 165.127 48.7563 165.146 48.6033L166.486 41.9597H168.048L169.319 49.7106H173.2C173.33 49.7208 173.461 49.7009 173.582 49.6523C173.703 49.6038 173.812 49.5281 173.899 49.4309C173.979 49.3217 174.035 49.1962 174.061 49.0632C174.087 48.9302 174.084 48.7931 174.051 48.6616L172.78 41.2604L174.925 39.6286L176.685 30.549ZM171.568 32.7869L170.682 37.3791C170.646 37.5382 170.565 37.6837 170.449 37.7987C170.324 37.9092 170.161 37.9676 169.995 37.9619H167.302L168.468 32.2274H171.148C171.215 32.2227 171.282 32.2327 171.345 32.2569C171.407 32.2811 171.464 32.3188 171.51 32.3673C171.551 32.428 171.578 32.4975 171.588 32.5703C171.598 32.643 171.591 32.7172 171.568 32.7869Z"
          fill="#003A5D"
        />
        <path
          d="M188.911 28.2529H183.084L175.811 49.6873H179.61C179.906 49.7028 180.198 49.6164 180.438 49.4425C180.67 49.2389 180.836 48.9713 180.916 48.6733L181.755 45.8526H184.762L184.447 49.6873H189.249L190.112 29.4417C190.125 29.2812 190.105 29.1195 190.053 28.9671C190.001 28.8146 189.918 28.6746 189.809 28.5559C189.56 28.343 189.239 28.2344 188.911 28.2529ZM185.135 42.0879H182.932L185.963 31.9593L185.135 42.0879Z"
          fill="#003A5D"
        />
        <path
          d="M205.043 28.2529H198.049L194.972 30.5839L191.697 47.4261L193.853 49.7572H200.847L203.912 47.4261L204.798 42.8921C204.839 42.7623 204.851 42.6249 204.833 42.4899C204.815 42.3548 204.767 42.2255 204.693 42.1112C204.599 42.014 204.484 41.9395 204.356 41.893C204.229 41.8466 204.093 41.8295 203.959 41.8431H200.287L199.646 45.1533C199.614 45.3175 199.533 45.4681 199.413 45.5845C199.29 45.6891 199.131 45.7432 198.97 45.7361H197.245C197.178 45.7408 197.111 45.7307 197.049 45.7066C196.986 45.6824 196.93 45.6447 196.884 45.5962C196.85 45.5335 196.832 45.4634 196.832 45.3922C196.832 45.321 196.85 45.251 196.884 45.1883L199.285 32.8451C199.312 32.6792 199.394 32.5272 199.518 32.4139C199.644 32.314 199.8 32.2605 199.961 32.2623H201.674C201.743 32.2575 201.811 32.2674 201.876 32.2916C201.94 32.3157 201.999 32.3534 202.047 32.4022C202.081 32.467 202.098 32.539 202.098 32.612C202.098 32.685 202.081 32.757 202.047 32.8218L201.453 35.9688H204.949C205.266 35.9816 205.578 35.8872 205.835 35.7007C205.957 35.6063 206.059 35.4886 206.135 35.3546C206.211 35.2206 206.259 35.0728 206.278 34.9198L207.129 30.549L205.043 28.2529Z"
          fill="#003A5D"
        />
        <path
          d="M218.202 38.8477L223.4 29.2902C223.476 29.1856 223.526 29.064 223.544 28.9361C223.562 28.8081 223.549 28.6775 223.505 28.5559C223.448 28.4537 223.363 28.3704 223.259 28.3166C223.156 28.2627 223.038 28.2405 222.922 28.2528H219.099L214.694 36.6681H213.738L215.37 28.2528H210.707L206.546 49.6873H210.043C210.364 49.7 210.679 49.6057 210.94 49.4192C211.178 49.2215 211.336 48.9438 211.383 48.6383L212.934 40.6543H213.854L215.16 49.6873H219.111C219.235 49.6923 219.358 49.6686 219.471 49.6181C219.585 49.5675 219.685 49.4915 219.764 49.3959C219.837 49.2812 219.886 49.1529 219.908 49.0186C219.93 48.8843 219.925 48.747 219.892 48.6149L218.202 38.8477Z"
          fill="#003A5D"
        />
        <path
          d="M222.433 49.6873H231.99C232.307 49.7001 232.619 49.6056 232.876 49.4192C232.998 49.3248 233.1 49.2071 233.176 49.0731C233.252 48.939 233.3 48.7913 233.319 48.6383L233.902 45.7011H227.899L228.843 40.8058H232.212C232.506 40.8139 232.795 40.7191 233.027 40.5377C233.274 40.3336 233.456 40.0624 233.552 39.7568L234.554 36.8196H229.624L230.498 32.2274H235.219C235.538 32.2426 235.853 32.1525 236.116 31.9709C236.354 31.7732 236.511 31.4956 236.559 31.19L237.095 28.2529H226.605L222.433 49.6873Z"
          fill="#003A5D"
        />
        <path
          d="M238.156 32.2274H241.944L238.529 49.7106H242.084C242.405 49.7233 242.72 49.629 242.981 49.4425C243.219 49.2448 243.377 48.9671 243.424 48.6616L246.63 32.2507H249.299C249.615 32.2678 249.927 32.1774 250.185 31.9943C250.306 31.8999 250.408 31.7822 250.484 31.6482C250.56 31.5141 250.609 31.3664 250.627 31.2134L251.175 28.2762H238.902L238.156 32.2274Z"
          fill="#003A5D"
        />
        <path
          d="M269.847 28.2529H256.898L259.73 31.19L256.56 47.4145L258.728 49.7455H265.721L268.798 47.4145L269.673 42.8805C269.714 42.7507 269.726 42.6133 269.708 42.4782C269.69 42.3432 269.642 42.2139 269.568 42.0996C269.473 42.0024 269.358 41.9278 269.231 41.8814C269.104 41.8349 268.968 41.8179 268.833 41.8315H265.197L264.556 45.1416C264.52 45.307 264.435 45.4575 264.311 45.5729C264.251 45.6262 264.181 45.6671 264.105 45.6931C264.029 45.7191 263.948 45.7298 263.868 45.7244H262.062C261.995 45.7309 261.927 45.7216 261.864 45.6973C261.802 45.673 261.745 45.6344 261.7 45.5845C261.656 45.5273 261.628 45.4597 261.618 45.3882C261.607 45.3168 261.616 45.2439 261.642 45.1766L264.043 32.8335C264.079 32.6709 264.16 32.5215 264.276 32.4022C264.406 32.3006 264.566 32.2471 264.731 32.2507H266.444C266.515 32.2397 266.588 32.2482 266.654 32.2753C266.721 32.3024 266.779 32.3471 266.822 32.4045C266.865 32.462 266.891 32.5301 266.899 32.6015C266.906 32.673 266.894 32.7451 266.864 32.8101L266.246 35.9921H269.742C270.063 36.0048 270.379 35.9105 270.64 35.724C270.88 35.5285 271.038 35.2497 271.083 34.9431L271.922 30.5723L269.847 28.2529Z"
          fill="#003A5D"
        />
        <path
          d="M283.402 28.2529L281.712 36.9711H278.472L280.162 28.2529H275.5L271.339 49.6873H274.882C275.199 49.7001 275.511 49.6056 275.768 49.4192C275.89 49.3248 275.992 49.2071 276.068 49.0731C276.144 48.939 276.192 48.7913 276.211 48.6383L277.668 41.1088H280.92L279.253 49.6873H282.75C283.07 49.7 283.386 49.6057 283.647 49.4192C283.885 49.2215 284.042 48.9438 284.09 48.6383L288.041 28.2529H283.402Z"
          fill="#003A5D"
        />
        <path
          d="M299.359 28.2529H293.532L286.224 49.6873H290.035C290.334 49.7027 290.63 49.6164 290.874 49.4425C291.102 49.2378 291.264 48.9701 291.34 48.6733L292.191 45.8526H295.187L294.872 49.6873H299.686L300.548 29.4417C300.562 29.2812 300.542 29.1195 300.489 28.9671C300.437 28.8146 300.354 28.6746 300.245 28.5559C299.999 28.3458 299.682 28.2374 299.359 28.2529ZM295.513 42.0879H293.31L296.352 31.9593L295.513 42.0879Z"
          fill="#003A5D"
        />
        <path
          d="M310.933 28.2529H306.271L302.11 49.6873H310.502C310.819 49.7001 311.131 49.6056 311.388 49.4192C311.509 49.3248 311.611 49.2071 311.687 49.0731C311.763 48.939 311.812 48.7913 311.831 48.6383L312.378 45.7011H307.518L310.933 28.2529Z"
          fill="#003A5D"
        />
        <path
          d="M322.961 28.2529H318.299L314.138 49.6873H322.529C322.85 49.6983 323.165 49.6041 323.427 49.4192C323.665 49.2215 323.822 48.9438 323.87 48.6383L324.406 45.7011H319.604L322.961 28.2529Z"
          fill="#003A5D"
        />
        <path
          d="M340.269 31.19L340.817 28.2529H330.327L326.166 49.6873H335.712C336.033 49.7 336.349 49.6057 336.61 49.4192C336.847 49.2215 337.005 48.9438 337.053 48.6383L337.624 45.7011H331.621L332.565 40.8058H335.934C336.229 40.8158 336.518 40.7209 336.749 40.5377C336.996 40.3336 337.178 40.0624 337.274 39.7568L338.276 36.8196H333.346L334.232 32.2274H338.894C339.214 32.2444 339.529 32.1541 339.792 31.9709C340.042 31.7791 340.213 31.5007 340.269 31.19Z"
          fill="#003A5D"
        />
        <path
          d="M350.153 39.8267L348.16 28.2529H343.778L339.617 49.6873H342.869C343.186 49.7001 343.498 49.6056 343.754 49.4192C343.876 49.3248 343.978 49.2071 344.054 49.0731C344.13 48.939 344.179 48.7913 344.197 48.6383L346.237 38.1483L348.032 48.6383C348.057 48.9343 348.195 49.2095 348.417 49.4075C348.623 49.5959 348.895 49.6963 349.174 49.6873H352.601L356.762 28.2529H352.38L350.153 39.8267Z"
          fill="#003A5D"
        />
        <path
          d="M362.881 28.2529L359.815 30.584L356.529 47.4261L358.697 49.7572H366.098L369.175 47.4261L371.098 37.6239H365.678C365.385 37.6125 365.096 37.7031 364.862 37.8803C364.628 38.0929 364.451 38.3616 364.349 38.6612L363.464 41.4119H365.736L364.99 45.1184C364.954 45.281 364.874 45.4303 364.757 45.5496C364.631 45.6559 364.468 45.7101 364.303 45.7011H362.135C362.066 45.706 361.997 45.6961 361.933 45.6719C361.869 45.6478 361.81 45.61 361.762 45.5613C361.73 45.498 361.714 45.4281 361.714 45.3573C361.714 45.2865 361.73 45.2166 361.762 45.1533L364.163 32.8102C364.195 32.6459 364.276 32.4954 364.396 32.3789C364.525 32.2773 364.686 32.2238 364.851 32.2274H366.867C366.938 32.2164 367.011 32.2249 367.077 32.252C367.144 32.2791 367.202 32.3238 367.245 32.3813C367.288 32.4387 367.314 32.5068 367.322 32.5783C367.329 32.6497 367.317 32.7218 367.287 32.7869L366.681 35.9688H370.177C370.498 35.9816 370.814 35.8872 371.075 35.7007C371.315 35.5052 371.473 35.2265 371.518 34.9198L372.357 30.549L370.2 28.2179L362.881 28.2529Z"
          fill="#003A5D"
        />
        <path
          d="M376.285 28.2529L372.124 49.6873H381.669C381.99 49.7 382.306 49.6057 382.567 49.4192C382.805 49.2215 382.962 48.9438 383.01 48.6383L383.581 45.7011H377.578L378.534 40.8058H381.891C382.186 40.8158 382.475 40.7209 382.707 40.5377C382.953 40.3336 383.136 40.0624 383.231 39.7568L384.234 36.8196H379.303L380.189 32.2274H384.851C385.171 32.2444 385.487 32.1541 385.749 31.9709C385.987 31.7732 386.144 31.4956 386.192 31.19L386.739 28.2529H376.285Z"
          fill="#003A5D"
        />
        <path
          d="M270.546 1.69007L269.322 14.7792L273.413 0.687704H278.379L277.679 14.7792L281.281 0.687704H285.745L279.731 20.1407C279.642 20.4359 279.46 20.6944 279.212 20.8773C278.964 21.0602 278.663 21.1576 278.355 21.1547H273.996L274.695 7.58774L271.047 20.1407C270.922 20.435 270.715 20.6872 270.45 20.867C270.186 21.0468 269.875 21.1467 269.555 21.1547H265.278L266.129 0.687704H269.625C270.313 0.687704 270.604 1.03737 270.546 1.69007Z"
          fill="#EC7700"
        />
        <path
          d="M296.282 0.687704L298.391 2.87893L295.548 18.9635L292.657 21.1547H285.582L283.472 18.9635L286.316 2.87893L289.207 0.687704H296.282ZM290.454 5.04685L288.379 16.8305C288.379 17.1569 288.438 17.355 288.787 17.355H290.862C290.941 17.3614 291.021 17.3518 291.096 17.327C291.171 17.3021 291.241 17.2625 291.3 17.2104C291.36 17.1582 291.409 17.0947 291.444 17.0234C291.478 16.9522 291.499 16.8747 291.503 16.7956L293.531 5.02354C293.589 4.69719 293.473 4.48739 293.123 4.48739H291.049C290.969 4.48105 290.89 4.49058 290.815 4.51542C290.739 4.54027 290.67 4.57992 290.61 4.63206C290.55 4.68419 290.502 4.74774 290.467 4.81898C290.432 4.89021 290.412 4.96769 290.408 5.04685H290.454Z"
          fill="#EC7700"
        />
        <path
          d="M318.275 0.687704L314.848 20.164C314.805 20.4587 314.65 20.7256 314.416 20.9099C314.182 21.0941 313.886 21.1817 313.59 21.1547H310.641L313.217 6.50378L308.683 20.1407C308.616 20.4459 308.441 20.7166 308.191 20.903C307.94 21.0894 307.63 21.1788 307.319 21.1547H303.543L303.927 6.50378L301.549 20.164C301.506 20.4587 301.351 20.7256 301.117 20.9099C300.883 21.0941 300.587 21.1817 300.291 21.1547H297.342L300.932 0.687704H306.456C307.132 0.687704 307.424 1.03737 307.424 1.69007L307.074 15.1521L311.841 0.687704H318.275Z"
          fill="#EC7700"
        />
        <path
          d="M323.486 8.8815H328.148L327.332 11.6555C327.276 11.9444 327.118 12.2035 326.887 12.3854C326.656 12.5674 326.367 12.6599 326.073 12.6462H322.856L322.04 17.3084H327.763L327.274 20.1174C327.23 20.4121 327.075 20.679 326.841 20.8633C326.607 21.0475 326.311 21.1351 326.015 21.1081H316.842L320.443 0.687704H330.467L330.001 3.49667C329.958 3.7914 329.803 4.0583 329.569 4.24255C329.335 4.4268 329.039 4.51441 328.742 4.48739H324.243L323.486 8.8815Z"
          fill="#EC7700"
        />
        <path
          d="M336.551 20.164L334.651 10.1403L332.903 20.164C332.86 20.4587 332.705 20.7256 332.471 20.9099C332.237 21.0941 331.941 21.1817 331.644 21.1547H328.497L332.146 0.687704H336.318L338.428 11.7487L340.363 0.687704H344.535L340.945 21.1547H337.67C337.393 21.1619 337.123 21.0636 336.916 20.8798C336.708 20.6959 336.578 20.4401 336.551 20.164Z"
          fill="#EC7700"
        />
        <path
          d="M345.153 7.12152L346.587 0.687704H351.062L348.487 6.15412C348.391 6.47721 348.181 6.75416 347.895 6.93247C347.61 7.11078 347.268 7.17805 346.936 7.12152H345.153Z"
          fill="#EC7700"
        />
        <path
          d="M361.855 11.0727L360.445 18.9635L357.554 21.1547H350.479L348.37 18.9635L349.162 14.4878H352.659C353.323 14.4878 353.591 14.8141 353.475 15.4902L353.242 16.8305C353.184 17.1802 353.242 17.355 353.65 17.355H355.724C355.804 17.3631 355.884 17.3547 355.96 17.3305C356.036 17.3063 356.106 17.2667 356.166 17.2143C356.226 17.1618 356.275 17.0976 356.309 17.0256C356.344 16.9536 356.363 16.8753 356.365 16.7956L357.1 12.6812H352.123L350.013 10.49L351.354 2.85562L354.256 0.687704H361.039L363.137 2.87893L362.613 5.95598C362.569 6.2507 362.415 6.5176 362.181 6.70185C361.946 6.8861 361.651 6.97371 361.354 6.94669H357.962L358.289 5.02354C358.347 4.69719 358.23 4.48739 357.881 4.48739H356.097C356.018 4.48102 355.938 4.49047 355.862 4.51518C355.786 4.53989 355.715 4.57938 355.655 4.63137C355.594 4.68337 355.544 4.74684 355.508 4.81815C355.472 4.88945 355.451 4.96717 355.445 5.04685L354.78 8.8815H359.746L361.855 11.0727Z"
          fill="#EC7700"
        />
        <path
          d="M126.147 20.2689L125.191 14.6742L124.107 20.2689C124.081 20.4338 123.993 20.5825 123.86 20.6844C123.728 20.7863 123.562 20.8336 123.396 20.8167H121.671L123.885 9.40597H126.216L127.277 15.5717L128.443 9.40597H130.774L128.559 20.8167H126.729C126.581 20.817 126.437 20.7604 126.329 20.6585C126.221 20.5567 126.155 20.4173 126.147 20.2689Z"
          fill="#003A5D"
        />
        <path
          d="M136.182 19.5928L134.55 20.8167H130.844L129.678 19.5928L131.427 10.6298L133.058 9.46424H136.765L137.93 10.6298L137.487 12.9609C137.461 13.1258 137.373 13.2745 137.241 13.3764C137.109 13.4783 136.943 13.5256 136.776 13.5087H134.877L135.203 11.8186C135.203 11.6438 135.203 11.5273 134.982 11.5273H134.072C133.985 11.5207 133.898 11.5488 133.83 11.6054C133.763 11.662 133.72 11.7427 133.711 11.8303L132.429 18.404C132.429 18.5788 132.429 18.6954 132.65 18.6954H133.56C133.648 18.6994 133.735 18.6707 133.804 18.6148C133.872 18.559 133.918 18.4798 133.933 18.3923L134.271 16.6324H136.182C136.567 16.6324 136.707 16.8188 136.637 17.1802L136.182 19.5928Z"
          fill="#003A5D"
        />
        <path
          d="M143.933 10.0353L143.467 20.8167H140.915L141.078 18.7769H139.481L139.026 20.2805C138.998 20.4389 138.912 20.5813 138.785 20.6803C138.658 20.7792 138.499 20.8278 138.339 20.8167H136.311L140.215 9.40595H143.292C143.378 9.39482 143.465 9.40334 143.547 9.43086C143.629 9.45838 143.704 9.50418 143.766 9.5648C143.828 9.62542 143.875 9.69927 143.904 9.78079C143.933 9.8623 143.943 9.94934 143.933 10.0353ZM141.253 16.7722L141.696 11.3757L140.075 16.7722H141.253Z"
          fill="#003A5D"
        />
        <path
          d="M151.743 10.0353L151.335 20.8167H148.77L148.945 18.7769H147.337L146.894 20.2805C146.863 20.4399 146.775 20.5824 146.646 20.6812C146.517 20.78 146.357 20.8282 146.195 20.8167H144.178L148.071 9.40594H151.148C151.23 9.40215 151.312 9.41612 151.388 9.44691C151.465 9.47769 151.533 9.52456 151.59 9.58431C151.646 9.64406 151.689 9.71529 151.715 9.79313C151.742 9.87097 151.751 9.95359 151.743 10.0353ZM149.062 16.7722L149.505 11.3757L147.838 16.7722H149.062Z"
          fill="#003A5D"
        />
        <path
          d="M152.978 12.5063L152.582 12.0634L153.013 9.86052L153.537 9.46424H155.414L155.81 9.9188L155.379 12.11L154.855 12.5063H152.978ZM155.309 10.0354C155.309 9.96542 155.309 9.9188 155.204 9.9188H153.537C153.456 9.9188 153.421 9.9188 153.409 10.0237L153.036 11.9352C153.036 12.0168 153.036 12.0634 153.141 12.0634H154.831C154.913 12.0634 154.948 12.0634 154.959 11.9469L155.309 10.0354ZM154.272 11.7254L154.12 11.2009H154.004L153.922 11.5972C153.917 11.6332 153.898 11.666 153.87 11.6896C153.842 11.7131 153.807 11.7258 153.771 11.7254H153.491L153.782 10.2568H154.621L154.831 10.4899L154.738 10.9561L154.505 11.1426L154.656 11.5622C154.726 11.6555 154.68 11.7254 154.598 11.7254H154.272ZM154.26 10.8862C154.342 10.8862 154.365 10.8862 154.377 10.7697V10.6881C154.377 10.6181 154.377 10.5948 154.307 10.5948H154.109V10.8862H154.26Z"
          fill="#003A5D"
        />
        <path
          d="M167.943 9.40595L165.845 20.2689C165.819 20.4338 165.731 20.5825 165.598 20.6844C165.466 20.7863 165.3 20.8336 165.134 20.8167H163.479L165.064 12.6578L162.418 20.2572C162.377 20.4297 162.276 20.5819 162.133 20.6865C161.99 20.7912 161.814 20.8414 161.637 20.8283H159.493L159.854 12.6695L158.374 20.2922C158.348 20.4571 158.259 20.6058 158.127 20.7077C157.995 20.8096 157.829 20.8569 157.663 20.84H156.019L158.327 9.40595H161.404C161.478 9.39597 161.553 9.40336 161.623 9.42755C161.694 9.45173 161.758 9.49204 161.81 9.54525C161.862 9.59847 161.901 9.66311 161.923 9.73404C161.946 9.80496 161.952 9.8802 161.94 9.95376L161.556 17.4832L164.364 9.44092L167.943 9.40595Z"
          fill="#003A5D"
        />
        <path
          d="M173.958 10.0353L173.491 20.8167H170.939L171.102 18.7769H169.505L169.051 20.2805C169.022 20.4389 168.936 20.5813 168.81 20.6803C168.683 20.7792 168.524 20.8278 168.363 20.8167H166.335L170.24 9.40595H173.317C173.402 9.39482 173.49 9.40334 173.572 9.43086C173.654 9.45838 173.728 9.50418 173.79 9.5648C173.852 9.62542 173.899 9.69927 173.928 9.78079C173.957 9.8623 173.967 9.94934 173.958 10.0353ZM171.277 16.7722L171.72 11.3757L170.1 16.7722H171.277Z"
          fill="#003A5D"
        />
        <path
          d="M181.429 20.2689C181.451 20.3354 181.456 20.4064 181.443 20.4753C181.43 20.5441 181.401 20.6088 181.356 20.6632C181.312 20.7177 181.255 20.7603 181.191 20.7871C181.126 20.8139 181.056 20.824 180.986 20.8167H178.911L178.235 16.6906H177.408L176.709 20.2689C176.683 20.4338 176.594 20.5825 176.462 20.6844C176.33 20.7863 176.164 20.8336 175.998 20.8167H174.098L176.324 9.40597H181.639L182.804 10.5715L181.872 15.4085L180.706 16.271L181.429 20.2689ZM179.308 14.5693C179.351 14.5742 179.396 14.5701 179.438 14.5573C179.48 14.5445 179.519 14.5233 179.553 14.495C179.587 14.4667 179.615 14.4318 179.634 14.3925C179.654 14.3532 179.666 14.3103 179.669 14.2663L180.135 11.8187C180.135 11.6438 180.135 11.5273 179.914 11.5273H178.457L177.862 14.5693H179.308Z"
          fill="#003A5D"
        />
        <path
          d="M188.69 19.5928L187.046 20.8167H183.351L182.186 19.5928L183.969 10.6298L185.601 9.46424H189.307L190.473 10.6298L190.018 12.9609C189.992 13.1258 189.904 13.2745 189.772 13.3764C189.64 13.4783 189.474 13.5256 189.307 13.5087H187.419L187.746 11.8186C187.746 11.6438 187.746 11.5273 187.512 11.5273H186.603C186.516 11.5207 186.429 11.5488 186.361 11.6054C186.294 11.662 186.251 11.7427 186.242 11.8303L184.96 18.404C184.96 18.5788 184.96 18.6954 185.181 18.6954H186.091C186.135 18.7002 186.18 18.6962 186.223 18.6835C186.266 18.6709 186.306 18.6499 186.34 18.6217C186.375 18.5935 186.404 18.5587 186.425 18.5194C186.446 18.48 186.459 18.4368 186.464 18.3923L186.801 16.6324H188.748C189.121 16.6324 189.261 16.8188 189.191 17.1802L188.69 19.5928Z"
          fill="#003A5D"
        />
        <path
          d="M192.793 16.2477L192.023 20.2689C191.997 20.4338 191.909 20.5825 191.777 20.6844C191.645 20.7863 191.479 20.8336 191.312 20.8167H189.413L191.627 9.40597H194.133L193.224 14.0682H194.949L195.858 9.40597H198.352L196.243 20.2689C196.217 20.4338 196.129 20.5825 195.996 20.6844C195.864 20.7863 195.698 20.8336 195.532 20.8167H193.644L194.518 16.2477H192.793Z"
          fill="#003A5D"
        />
        <path
          d="M212.164 9.40595L210.055 20.2689C210.029 20.4338 209.94 20.5825 209.808 20.6844C209.676 20.7863 209.51 20.8336 209.344 20.8167H207.7L209.285 12.6578L206.628 20.2572C206.589 20.4287 206.49 20.5807 206.349 20.6855C206.208 20.7903 206.034 20.841 205.859 20.8283H203.784L204.145 12.6695L202.618 20.2689C202.59 20.4347 202.499 20.5835 202.365 20.6852C202.231 20.7869 202.063 20.8339 201.896 20.8167H200.287L202.502 9.40595H205.59C205.664 9.3954 205.739 9.40251 205.809 9.42669C205.879 9.45088 205.942 9.49144 205.994 9.545C206.045 9.59857 206.083 9.6636 206.104 9.73469C206.125 9.80578 206.129 9.88088 206.115 9.95376L205.765 17.4832L208.574 9.44092L212.164 9.40595Z"
          fill="#003A5D"
        />
        <path
          d="M218.167 10.0353L217.771 20.8167H215.207L215.381 18.7769H213.785L213.33 20.2805C213.299 20.4399 213.211 20.5824 213.082 20.6812C212.953 20.78 212.793 20.8282 212.631 20.8167H210.614L214.507 9.40595H217.584C217.665 9.40387 217.746 9.4191 217.821 9.45064C217.896 9.48218 217.963 9.5293 218.018 9.58886C218.073 9.64841 218.115 9.71904 218.141 9.79603C218.166 9.87302 218.175 9.9546 218.167 10.0353ZM215.44 16.7722L215.883 11.3757L214.274 16.7722H215.44Z"
          fill="#003A5D"
        />
        <path
          d="M227.094 10.6298L225.346 19.5929L223.598 20.8167H218.271L220.486 9.40597H225.929L227.094 10.6298ZM222.654 18.6488C222.698 18.6536 222.743 18.6496 222.786 18.6369C222.829 18.6243 222.869 18.6033 222.903 18.5751C222.938 18.5469 222.967 18.5121 222.988 18.4727C223.009 18.4334 223.022 18.3902 223.027 18.3457L224.309 11.772C224.309 11.5972 224.309 11.4806 224.076 11.4806H222.63L221.267 18.6488H222.654Z"
          fill="#003A5D"
        />
        <path
          d="M230.719 20.2689L229.763 14.6742L228.679 20.2689C228.653 20.4338 228.565 20.5825 228.433 20.6844C228.301 20.7863 228.135 20.8336 227.968 20.8167H226.243L228.458 9.40597H230.789L231.85 15.5717L233.015 9.40597H235.346L233.132 20.8167H231.302C231.153 20.817 231.01 20.7604 230.902 20.6585C230.793 20.5567 230.728 20.4173 230.719 20.2689Z"
          fill="#003A5D"
        />
        <path
          d="M238.191 13.9866H240.814L240.289 15.5484C240.253 15.7107 240.161 15.855 240.029 15.9553C239.896 16.0557 239.732 16.1057 239.566 16.0962H237.783L237.27 18.6488H240.464L240.161 20.2223C240.135 20.3872 240.047 20.5359 239.914 20.6378C239.782 20.7397 239.616 20.787 239.45 20.7701H234.368L236.583 9.35938H242.177L241.886 10.9212C241.863 11.0883 241.776 11.2398 241.643 11.3441C241.51 11.4484 241.343 11.4973 241.175 11.4807H238.657L238.191 13.9866Z"
          fill="#003A5D"
        />
        <path
          d="M248.47 15.1521L247.608 19.5579L245.976 20.7817H242.025L240.859 19.5579L241.349 17.0636H243.295C243.668 17.0636 243.808 17.2501 243.738 17.623L243.598 18.369C243.598 18.5671 243.598 18.6604 243.82 18.6604H244.985C245.073 18.6669 245.16 18.6389 245.228 18.5823C245.295 18.5257 245.338 18.445 245.347 18.3573L245.801 16.0262H243.027L241.862 14.8607L242.689 10.6065L244.333 9.4409H248.074L249.24 10.6065L248.913 12.3198C248.887 12.4847 248.799 12.6334 248.667 12.7353C248.535 12.8372 248.368 12.8846 248.202 12.8676H246.314L246.512 11.7953C246.512 11.6205 246.512 11.5039 246.291 11.5039H245.3C245.256 11.4991 245.211 11.5031 245.168 11.5158C245.125 11.5284 245.085 11.5494 245.05 11.5776C245.016 11.6058 244.987 11.6406 244.966 11.6799C244.945 11.7193 244.932 11.7625 244.927 11.807L244.578 13.9865H247.352L248.47 15.1521Z"
          fill="#003A5D"
        />
        <path
          d="M256.093 15.1521L255.231 19.5579L253.587 20.7817H249.648L248.482 19.5579L248.972 17.0636H250.906C251.279 17.0636 251.431 17.2501 251.349 17.623L251.21 18.369C251.21 18.5671 251.21 18.6604 251.431 18.6604H252.597C252.684 18.6669 252.771 18.6389 252.839 18.5823C252.906 18.5257 252.949 18.445 252.958 18.3573L253.401 16.0262H250.638L249.473 14.8607L250.3 10.6065L251.932 9.4409H255.72L256.886 10.6065L256.559 12.3198C256.533 12.4847 256.445 12.6334 256.313 12.7353C256.181 12.8372 256.014 12.8846 255.848 12.8676H253.949L254.158 11.7953C254.158 11.6205 254.158 11.5039 253.925 11.5039H252.935C252.847 11.4974 252.76 11.5254 252.692 11.582C252.625 11.6386 252.582 11.7193 252.573 11.807L252.165 13.9865H254.939L256.093 15.1521Z"
          fill="#003A5D"
        />
        <path
          d="M258.191 12.5063L257.795 12.0634L258.215 9.86052L258.751 9.46424H260.616L261.024 9.9188L260.592 12.11L260.068 12.5063H258.191ZM260.523 10.0354C260.523 9.96542 260.522 9.9188 260.418 9.9188H258.751C258.669 9.9188 258.634 9.9188 258.623 10.0237L258.25 11.9352C258.25 12.0168 258.25 12.0634 258.343 12.0634H260.033C260.103 12.0634 260.138 12.0634 260.161 11.9469L260.523 10.0354ZM259.485 11.7254L259.334 11.2009H259.205L259.136 11.5972C259.13 11.6332 259.112 11.666 259.084 11.6896C259.056 11.7131 259.02 11.7258 258.984 11.7254H258.704L258.996 10.2568H259.823L260.045 10.5016L259.963 10.9678L259.73 11.1543L259.881 11.5739C259.94 11.6555 259.893 11.7254 259.812 11.7254H259.485ZM259.474 10.8862C259.555 10.8862 259.578 10.8862 259.59 10.7697V10.6881C259.59 10.6181 259.59 10.5948 259.508 10.5948H259.322L259.264 10.8862H259.474Z"
          fill="#003A5D"
        />
        <path
          d="M114.41 59H381.985L382.579 55.7248H115.028L114.41 59ZM125.599 0.80423L124.97 4.16101H261.817L262.435 0.80423H125.599Z"
          fill="#EC7700"
        />
        <path
          d="M72.147 37.9036C67.683 41.0622 62.3914 44.384 56.7035 47.869L56.482 47.9972C56.445 48.0219 56.4192 48.0604 56.4105 48.1041C56.4017 48.1478 56.4107 48.1932 56.4354 48.2303C56.4602 48.2674 56.4986 48.2932 56.5423 48.3019C56.586 48.3107 56.6314 48.3017 56.6685 48.2769L56.8667 48.172C61.692 45.6545 67.2284 42.6707 72.8114 39.6053L72.893 39.547C72.5731 39.0344 72.3223 38.4818 72.147 37.9036ZM101.461 14.173C94.4672 6.41049 49.1274 13.3921 29.8726 17.5997L29.418 17.7046C29.3736 17.7132 29.3342 17.7386 29.3082 17.7755C29.2821 17.8125 29.2713 17.8581 29.2782 17.9028C29.2867 17.9472 29.3121 17.9866 29.349 18.0127C29.386 18.0387 29.4316 18.0495 29.4763 18.0426L29.9192 17.9494C45.8756 15.1521 79.0704 11.1659 86.2035 18.2524C88.3831 20.4087 87.8586 23.1944 85.341 26.4812C86.7347 27.4003 87.7232 28.8185 88.1033 30.4441C97.9639 23.8937 104.596 17.6347 101.461 14.173Z"
          fill="#CC2427"
        />
        <path
          d="M74.9906 35.8639C75.0838 38.5913 76.8088 40.7942 79.07 40.7942C83.4408 40.7942 85.294 35.4327 85.1658 31.8544C85.0726 29.1154 83.3009 26.8775 81.0514 26.8775C77.2984 26.9125 74.8624 32.2507 74.9906 35.8639ZM72.6595 35.7124C72.6143 34.5151 72.8066 33.3207 73.2252 32.1981C73.6438 31.0754 74.2805 30.0467 75.0985 29.1713C75.9165 28.2958 76.8997 27.591 77.9914 27.0973C79.0831 26.6036 80.2618 26.3308 81.4594 26.2948C82.2557 26.2567 83.0513 26.3852 83.7952 26.6719C84.5391 26.9586 85.2151 27.3973 85.78 27.9599C86.3448 28.5226 86.7861 29.1969 87.0757 29.9397C87.3653 30.6825 87.4969 31.4775 87.4619 32.274C87.6251 37.1926 84.2217 41.7033 78.6621 41.7033C75.1421 41.7033 72.8227 39.3489 72.6828 35.7124H72.6595Z"
          fill="#003A5D"
        />
        <path
          d="M22.4361 34.4886C22.1936 34.5761 21.944 34.6424 21.6901 34.6867C21.3987 34.7566 20.8393 34.8615 20.035 34.9897C19.6242 35.0206 19.2257 35.1441 18.8695 35.3511C18.7726 35.4141 18.6895 35.4962 18.6254 35.5924C18.5613 35.6886 18.5174 35.7968 18.4965 35.9105C18.4738 36.021 18.485 36.1358 18.5287 36.2398C18.5724 36.3438 18.6465 36.4322 18.7413 36.4933C19.0695 36.6701 19.4422 36.7471 19.8136 36.7148C20.1895 36.7146 20.5631 36.6556 20.9208 36.5399C21.2483 36.4407 21.5532 36.2784 21.8183 36.062C21.9913 35.9106 22.1235 35.7183 22.2029 35.5026C22.3045 35.1705 22.3824 34.8317 22.4361 34.4886ZM27.04 31.6213C27.0112 32.1113 26.945 32.5984 26.8418 33.0782L26.0609 37.4607C26.0194 37.6371 26.0395 37.8223 26.118 37.9856C26.1965 38.1489 26.3285 38.2804 26.4922 38.3582V38.533H21.9582V37.4257C21.2875 37.824 20.5661 38.1298 19.8136 38.3349C19.1189 38.5252 18.4007 38.6154 17.6806 38.6029C16.6848 38.7379 15.674 38.5059 14.8367 37.9502C14.5724 37.7869 14.3565 37.5561 14.211 37.2816C14.0656 37.0071 13.9959 36.6988 14.0091 36.3884C14.0531 35.9094 14.2399 35.4546 14.5453 35.083C14.8668 34.7232 15.2652 34.4404 15.7108 34.2555C16.2893 34.0093 16.9029 33.8559 17.5291 33.8009C18.3217 33.6727 19.4173 33.5445 20.8859 33.4163C21.3893 33.384 21.8847 33.2739 22.3545 33.0899C22.6692 32.9617 22.7857 32.8335 22.8323 32.5887C22.9139 32.1342 22.7741 31.8777 22.168 31.7612C20.09 31.6034 18.0004 31.8045 15.9906 32.3556L16.8414 30.0245C18.8234 29.7124 20.826 29.5488 22.8323 29.535C26.0493 29.5233 27.0749 30.4441 27.04 31.6213Z"
          fill="#003A5D"
        />
        <path
          d="M39.9892 38.533L41.551 29.7797H45.8286L44.2668 38.533H39.9892ZM41.7725 27.7517C41.9007 27.0291 43.0313 26.4463 44.3017 26.4463C45.5722 26.4463 46.5046 27.0291 46.3764 27.7517C46.2482 28.4743 45.1059 29.0571 43.8355 29.0571C42.5651 29.0571 41.6559 28.4743 41.7725 27.7517Z"
          fill="#003A5D"
        />
        <path
          d="M61.0157 34.5119L60.2697 34.71C59.9667 34.7799 59.4189 34.8732 58.6146 35.0014C58.2053 35.0396 57.8083 35.1626 57.4491 35.3627C57.3523 35.4285 57.2694 35.5129 57.2054 35.6109C57.1414 35.709 57.0975 35.8187 57.0761 35.9338C57.055 36.0443 57.067 36.1585 57.1105 36.2622C57.1541 36.3658 57.2272 36.4544 57.3209 36.5166C57.6524 36.6833 58.0233 36.7559 58.3932 36.7264C58.7647 36.7306 59.1346 36.6755 59.4888 36.5632C59.8254 36.4836 60.1425 36.3369 60.4212 36.132C60.591 35.9731 60.7225 35.7779 60.8059 35.5609C60.8974 35.2159 60.9674 34.8655 61.0157 34.5119ZM65.6779 31.633C65.6451 32.1232 65.5749 32.6103 65.4681 33.0899L64.6988 37.4724C64.6566 37.6504 64.6763 37.8374 64.7547 38.0027C64.8331 38.168 64.9655 38.3016 65.1301 38.3815V38.533H60.5844V37.3675C59.9121 37.7653 59.1913 38.0747 58.4398 38.2882C57.7366 38.4743 57.0108 38.5606 56.2836 38.5447C55.2925 38.7042 54.2772 38.4971 53.428 37.9619C52.8452 37.4374 52.5655 37.0994 52.6004 36.3884C52.6369 35.9111 52.8247 35.4579 53.1366 35.0946C53.4605 34.7346 53.858 34.4485 54.3021 34.2554C54.8774 34.0242 55.4811 33.8714 56.0971 33.8009C56.8896 33.6727 57.9969 33.5445 59.4655 33.4163C59.9678 33.3842 60.4627 33.2781 60.9341 33.1016C61.2371 32.9733 61.3653 32.8451 61.412 32.5887C61.4935 32.1458 61.3537 31.8894 60.7593 31.7612C58.6775 31.6087 56.5849 31.8097 54.5702 32.3556L55.421 30.0828C57.4027 29.7665 59.4053 29.5989 61.412 29.5816C64.6289 29.535 65.6545 30.4558 65.6196 31.633H65.6779Z"
          fill="#003A5D"
        />
        <path
          d="M13.4147 35.386C12.2678 35.6478 11.0945 35.7769 9.91808 35.7707C7.86671 35.7707 6.2466 34.7566 6.33985 32.8918C6.39813 31.6563 7.86671 29.0455 11.5965 29.0455C12.7685 29.0196 13.9228 29.3355 14.9183 29.9546L15.4544 26.9125C14.1144 26.3788 12.6761 26.1364 11.2351 26.2015C6.29323 26.2481 1.60773 28.4743 0.978331 32.985C0.348935 37.4957 5.99018 38.7312 8.48446 38.7312C9.91808 38.7312 11.41 38.6612 12.8086 38.5214L13.4147 35.386Z"
          fill="#003A5D"
        />
        <path
          d="M48.183 29.7331L48.486 27.9732L52.9617 26.9941L52.4839 29.7564H54.6751L54.2555 31.4698H52.1342L50.8404 38.4631C50.8404 38.4631 46.5396 38.4631 46.5163 38.4631L47.81 31.4698H46.1783L46.5163 29.6982L48.183 29.7331Z"
          fill="#003A5D"
        />
        <path d="M69.9671 38.5214L65.6079 38.533L67.7992 26.3181L72.0417 26.4463L69.9671 38.5214Z" fill="#003A5D" />
        <path
          d="M34.3363 31.8311C33.788 31.8275 33.2545 32.0081 32.8211 32.344C32.3582 32.7 32.0529 33.2228 31.9702 33.8009C31.8854 34.0776 31.8634 34.3698 31.9057 34.6561C31.9481 34.9424 32.0537 35.2156 32.215 35.456C32.3873 35.6303 32.5953 35.7653 32.8246 35.8518C33.0539 35.9383 33.2992 35.9742 33.5437 35.9571C33.9158 35.9642 34.2851 35.8927 34.6277 35.7474C34.9665 35.585 35.2561 35.3356 35.4669 35.0247C35.7082 34.6763 35.8676 34.2779 35.9331 33.8592C36.0113 33.5962 36.0256 33.3184 35.975 33.0488C35.9243 32.7792 35.81 32.5256 35.6417 32.309C35.4713 32.1401 35.2665 32.0099 35.0412 31.9275C34.8159 31.845 34.5755 31.8121 34.3363 31.8311ZM26.5504 41.5518L28.6251 29.8264H32.4481L32.1567 31.3183C32.5355 30.8264 33.0383 30.4442 33.6137 30.211C34.3787 29.8762 35.203 29.6979 36.038 29.6865C36.8408 29.6206 37.6464 29.7736 38.3691 30.1294C39.0666 30.4679 39.6086 31.0599 39.8843 31.7845C40.157 32.5302 40.2215 33.336 40.0708 34.1156C39.9671 34.7991 39.7268 35.4547 39.3644 36.0434C39.0019 36.6321 38.5247 37.1419 37.9611 37.5423C36.9083 38.3151 35.6161 38.6908 34.313 38.6029C33.8777 38.6087 33.4433 38.5617 33.0192 38.4631C32.722 38.3882 32.436 38.2745 32.1684 38.1251C31.9135 37.9364 31.6791 37.7216 31.469 37.484L30.7697 41.5052L26.5504 41.5518Z"
          fill="#003A5D"
        />
        <path
          d="M104.211 32.9034C104.211 32.2041 103.826 31.7379 103.104 31.7379C101.344 31.7379 99.4787 35.2345 99.1524 36.5632C102.031 36.5516 104.281 34.8382 104.211 32.9034ZM104.584 38.2882L104.922 38.5214C104.51 39.4247 103.857 40.1966 103.034 40.752C102.212 41.3073 101.251 41.6245 100.26 41.6683C99.8412 41.677 99.4254 41.5991 99.0384 41.4397C98.6514 41.2803 98.3014 41.0428 98.0104 40.7419C97.7194 40.441 97.4936 40.0834 97.3472 39.6913C97.2007 39.2991 97.1368 38.881 97.1593 38.4631C97.0194 34.71 100.528 31.225 103.407 31.225C104.642 31.225 105.738 31.7612 105.843 33.0899C105.948 36.0737 101.763 36.9828 99.0009 37.0528C98.8993 37.4085 98.8638 37.7798 98.896 38.1484C98.8773 38.4594 98.9248 38.7708 99.0353 39.0621C99.1458 39.3534 99.3169 39.6179 99.5372 39.8382C99.7575 40.0585 100.022 40.2296 100.313 40.3401C100.605 40.4507 100.916 40.4982 101.227 40.4795C101.919 40.4012 102.582 40.1623 103.165 39.7818C103.748 39.4014 104.234 38.8899 104.584 38.2882Z"
          fill="#003A5D"
        />
        <path
          d="M87.4153 32.8102C87.7435 32.7603 88.0743 32.7291 88.406 32.7169C88.7207 32.7169 89.0121 32.7752 89.0238 33.0899C89.0354 33.4046 88.6974 34.6051 88.6275 34.9082L87.9631 37.8453C87.695 39.0109 87.4037 40.3163 87.1472 41.342H88.8606L89.8163 36.6798C92.7535 33.6261 93.8958 32.6237 94.5834 32.6237C94.6561 32.6167 94.7294 32.6257 94.7983 32.65C94.8671 32.6743 94.9298 32.7133 94.982 32.7644C95.0342 32.8155 95.0746 32.8773 95.1003 32.9456C95.1261 33.0139 95.1367 33.087 95.1312 33.1598C95.0644 33.8776 94.9158 34.5854 94.6883 35.2695L93.7093 38.6729C93.4977 39.3019 93.3722 39.9567 93.3363 40.6193C93.328 40.7705 93.3532 40.9216 93.4102 41.0619C93.4672 41.2022 93.5546 41.3281 93.666 41.4306C93.7774 41.5331 93.9101 41.6097 94.0546 41.6548C94.1992 41.6999 94.3519 41.7125 94.5018 41.6916C95.749 41.6916 96.6581 40.3862 97.4507 39.1041L97.2292 38.6845C96.9029 39.209 96.1569 40.3279 95.5742 40.3279C95.3877 40.3279 95.2128 40.2114 95.2012 39.8967C95.2203 39.4093 95.3066 38.9269 95.4576 38.4631L96.5532 34.4886C96.7729 33.7831 96.9175 33.0565 96.9845 32.3206C96.9964 32.1676 96.9739 32.0138 96.9185 31.8706C96.8631 31.7274 96.7763 31.5985 96.6644 31.4934C96.5525 31.3882 96.4185 31.3095 96.2722 31.2631C96.1258 31.2166 95.9709 31.2036 95.8189 31.225C94.6534 31.225 93.1964 32.0526 89.9912 35.7007L90.3875 33.9174C90.6089 32.9384 90.8187 31.9244 91.0169 31.225C89.8287 31.6471 88.6139 31.9897 87.3803 32.2507L87.4153 32.8102Z"
          fill="#003A5D"
        />
      </g>
      <defs>
        <clipPath id="clip0_693_15149">
          <rect width="390.948" height="59" fill="white" transform="translate(0.978516)" />
        </clipPath>
      </defs>
    </svg>
  );
});

WBCGHorizontalLight.displayName = "WBCGHorizontalLight";
export default WBCGHorizontalLight;
