import { SVGProps, forwardRef } from "react";

const MBCGHorizontalLight = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  const { className, ...rest } = props;
  return (
    <svg
      ref={ref}
      width="405"
      height="60"
      viewBox="0 0 405 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <path
        d="M73.6762 38.6999C69.058 41.9797 63.5837 45.4042 57.6993 49.0095L57.4702 49.1542C57.4542 49.1699 57.4414 49.1887 57.4327 49.2094C57.424 49.2301 57.4196 49.2523 57.4196 49.2748C57.4196 49.2973 57.424 49.3195 57.4327 49.3402C57.4414 49.3609 57.4542 49.3797 57.4702 49.3954C57.5034 49.425 57.5463 49.4414 57.5908 49.4414C57.6353 49.4414 57.6782 49.425 57.7114 49.3954L57.9164 49.2868C62.9084 46.6823 68.636 43.5834 74.4118 40.4121H74.4962C74.1497 39.8794 73.8741 39.3038 73.6762 38.6999ZM104.002 14.1497C96.7674 6.11906 49.8616 13.3418 29.9417 17.6948L29.4715 17.7912C29.4447 17.7906 29.4182 17.7959 29.3937 17.8067C29.3693 17.8175 29.3476 17.8336 29.3301 17.8538C29.3126 17.8741 29.2998 17.8979 29.2927 17.9237C29.2855 17.9494 29.2842 17.9764 29.2887 18.0028C29.2933 18.0291 29.3036 18.0541 29.319 18.076C29.3343 18.0979 29.3543 18.1161 29.3776 18.1293C29.4008 18.1425 29.4267 18.1504 29.4534 18.1524C29.48 18.1544 29.5068 18.1505 29.5317 18.1409L29.99 18.0565C46.4974 15.1144 80.8267 11.0387 88.2182 18.358C90.4731 20.6008 89.9305 23.4826 87.3259 26.871C88.7712 27.8237 89.7944 29.296 90.1837 30.9827C100.385 24.1941 107.246 17.731 104.002 14.1497Z"
        fill="#CC2427"
      />
      <path
        d="M76.6189 36.5897C76.7154 39.4113 78.4999 41.7264 80.8392 41.7264C85.361 41.7264 87.2782 36.1797 87.1456 32.4659C87.0491 29.6322 85.2163 27.3291 82.8891 27.3291C79.0064 27.3291 76.4863 32.8517 76.6189 36.5897ZM74.2073 36.4329C74.1589 35.1933 74.3567 33.9564 74.789 32.7935C75.2213 31.6307 75.8797 30.5651 76.7261 29.6581C77.5726 28.7511 78.5903 28.0208 79.7205 27.5093C80.8507 26.9978 82.0711 26.7153 83.3111 26.678C86.9888 26.678 89.3401 29.0896 89.521 32.8758C89.6898 37.9523 86.1689 42.6308 80.4172 42.6308C76.7757 42.6308 74.3761 40.183 74.2314 36.4329H74.2073Z"
        fill="#003A5D"
      />
      <path
        d="M22.2492 35.1548C22.0008 35.2555 21.742 35.3283 21.4775 35.3718C21.176 35.4442 20.5972 35.5407 19.7652 35.6733C19.3426 35.7171 18.9327 35.8441 18.5594 36.0471C18.4469 36.1137 18.3507 36.2045 18.2777 36.313C18.2047 36.4215 18.1568 36.5448 18.1374 36.6741C18.1156 36.7884 18.1279 36.9065 18.173 37.0138C18.218 37.121 18.2937 37.2126 18.3906 37.277C18.7322 37.4542 19.1161 37.5335 19.4999 37.5061C19.8889 37.506 20.2754 37.445 20.6455 37.3252C20.9842 37.2226 21.2997 37.0546 21.5739 36.8309C21.7533 36.6698 21.8901 36.4668 21.9718 36.24C22.094 35.8865 22.1868 35.5236 22.2492 35.1548ZM27.0121 32.1885C26.9824 32.6954 26.9139 33.1993 26.8071 33.6958L25.9992 38.2296C25.9555 38.4137 25.9759 38.6073 26.057 38.7783C26.1382 38.9492 26.2751 39.0875 26.4454 39.1701V39.3389H21.7548V38.1331C21.0614 38.5489 20.3151 38.8693 19.5361 39.0857C18.8169 39.2783 18.0739 39.3677 17.3295 39.351C16.3008 39.4931 15.2556 39.2575 14.3873 38.6878C13.7844 38.1452 13.495 37.7955 13.5312 37.06C13.5746 36.5675 13.7682 36.1003 14.0859 35.7215C14.421 35.3491 14.8322 35.053 15.2917 34.8533C15.8838 34.5956 16.5087 34.4211 17.1486 34.3348C17.9686 34.2022 19.102 34.0696 20.6213 33.949C21.1431 33.9137 21.656 33.7957 22.1407 33.5993C22.4662 33.4787 22.5868 33.3461 22.635 33.0808C22.7194 32.6105 22.5747 32.3453 21.9477 32.2247C19.798 32.0674 17.6372 32.2753 15.557 32.8396L16.4372 30.4883C18.4877 30.1655 20.5594 29.9962 22.635 29.9819C25.9872 30.0301 27.0483 30.9707 27.0121 32.1885Z"
        fill="#003A5D"
      />
      <path
        d="M40.4086 39.3389L42.0244 30.2954H46.4497L44.8339 39.3389H40.4086ZM42.2535 28.2335C42.3861 27.4859 43.5557 26.883 44.8701 26.883C46.1844 26.883 47.149 27.4859 47.0164 28.2335C46.8838 28.9811 45.7021 29.584 44.3877 29.584C43.0734 29.584 42.1329 28.9328 42.2535 28.2335Z"
        fill="#003A5D"
      />
      <path
        d="M62.1613 35.1789C61.9083 35.2655 61.6506 35.3379 61.3896 35.396C61.0761 35.4683 60.5093 35.5648 59.6773 35.6974C59.2515 35.725 58.8383 35.8531 58.4715 36.0712C58.2728 36.2068 58.1344 36.4143 58.0857 36.65C58.0604 36.7643 58.0712 36.8837 58.1166 36.9916C58.1619 37.0996 58.2396 37.1909 58.3389 37.2529C58.6784 37.4358 59.064 37.5154 59.4482 37.482C59.8322 37.4809 60.214 37.424 60.5817 37.3132C60.9231 37.2081 61.2421 37.0405 61.5222 36.8188C61.6951 36.6522 61.8307 36.4508 61.9201 36.228C62.0191 35.8828 62.0996 35.5326 62.1613 35.1789ZM66.9845 32.2126C66.9498 32.7198 66.8773 33.2236 66.7675 33.7199L65.9716 38.2537C65.9302 38.4378 65.9516 38.6305 66.0325 38.8009C66.1133 38.9714 66.249 39.1099 66.4178 39.1942V39.351H61.7152V38.2055C61.0217 38.6183 60.2753 38.9347 59.4965 39.146C58.7696 39.3427 58.0187 39.4361 57.2657 39.4233C56.2315 39.5683 55.1801 39.328 54.3115 38.7481C54.0381 38.5791 53.8147 38.3403 53.6642 38.0564C53.5138 37.7724 53.4417 37.4534 53.4554 37.1323C53.4963 36.6357 53.6901 36.1639 54.0101 35.7818C54.3459 35.4132 54.7571 35.1212 55.2159 34.9257C55.804 34.6663 56.4246 34.4878 57.0607 34.3951C57.8807 34.2746 59.0262 34.1299 60.5455 34.0093C61.0652 33.9681 61.5766 33.8544 62.0648 33.6717C62.3783 33.5511 62.511 33.4064 62.5592 33.1532C62.6436 32.695 62.4989 32.4176 61.884 32.297C59.7302 32.1273 57.5633 32.3354 55.4811 32.912L56.3614 30.5004C58.4118 30.1771 60.4835 30.0078 62.5592 29.994C65.8993 30.0422 66.9604 30.9948 66.9242 32.2126H66.9845Z"
        fill="#003A5D"
      />
      <path
        d="M12.9163 36.0833C11.7297 36.3541 10.5159 36.4876 9.29884 36.4812C7.17663 36.4812 5.50056 35.4321 5.59702 33.5149C5.65731 32.2247 7.17663 29.5237 11.0352 29.5237C12.247 29.5024 13.4396 29.8288 14.4717 30.4642L15.0264 27.3291C13.6408 26.7736 12.1529 26.5188 10.6614 26.5815C5.54879 26.6418 0.701458 28.9328 0.0503235 33.6114C-0.600811 38.2899 5.23528 39.556 7.8157 39.5439C9.30967 39.5508 10.803 39.4784 12.2892 39.3269L12.9163 36.0833Z"
        fill="#003A5D"
      />
      <path
        d="M48.8854 30.2351L49.1989 28.3902L53.8292 27.3894L53.3348 30.2351H55.6017L55.1676 32.0559H52.9731L51.6346 39.2907C51.6346 39.2907 47.1852 39.2907 47.1611 39.2907L48.4995 32.0559H46.8114L47.1611 30.211L48.8854 30.2351Z"
        fill="#003A5D"
      />
      <path d="M71.4219 39.3389H66.9122L69.1791 26.7142L73.5682 26.8347L71.4219 39.3389Z" fill="#003A5D" />
      <path
        d="M34.5604 32.4176C33.9945 32.4095 33.4424 32.5922 32.9929 32.9361C32.5111 33.3227 32.196 33.8795 32.1127 34.4916C32.0259 34.776 32.0037 35.0762 32.0475 35.3703C32.0913 35.6644 32.2 35.945 32.3659 36.1918C32.5435 36.3736 32.7583 36.5151 32.9954 36.6067C33.2326 36.6982 33.4867 36.7377 33.7405 36.7223C34.1268 36.7317 34.5102 36.6534 34.8619 36.4932C35.2115 36.3296 35.5112 36.0757 35.7301 35.7577C35.9783 35.3965 36.143 34.9846 36.2124 34.5519C36.2923 34.2798 36.3066 33.9926 36.2542 33.7138C36.2018 33.4351 36.0842 33.1727 35.9109 32.9482C35.7384 32.7663 35.528 32.6244 35.2947 32.5328C35.0613 32.4411 34.8107 32.4018 34.5604 32.4176ZM26.5057 42.474L28.652 30.3316H32.607L32.2453 31.8509C32.6587 31.304 33.2191 30.8858 33.8611 30.6451C34.6551 30.3083 35.5067 30.1281 36.3691 30.1145C37.1986 30.0426 38.032 30.1968 38.7808 30.5607C39.501 30.9186 40.0604 31.534 40.3483 32.285C40.6311 33.0563 40.6978 33.8901 40.5412 34.6966C40.4339 35.4037 40.1854 36.082 39.8104 36.691C39.4355 37.3001 38.9418 37.8274 38.3587 38.2416C37.2645 39.031 35.9314 39.4185 34.5846 39.3389C34.1224 39.3418 33.6614 39.2933 33.2099 39.1942C32.9 39.1186 32.6032 38.9967 32.3297 38.8325C32.0658 38.6422 31.8232 38.4239 31.6062 38.1814L30.8827 42.3414L26.5057 42.474Z"
        fill="#003A5D"
      />
      <path
        d="M106.848 33.527C106.848 32.7914 106.45 32.3212 105.703 32.3212C103.882 32.3212 101.953 35.8662 101.615 37.3011C104.594 37.2891 106.921 35.4683 106.848 33.527ZM107.234 39.0857L107.584 39.3269C107.158 40.2599 106.481 41.0568 105.63 41.6293C104.779 42.2018 103.786 42.5275 102.761 42.5705C102.326 42.5829 101.894 42.5049 101.491 42.3414C101.089 42.1778 100.724 41.9324 100.422 41.6206C100.119 41.3088 99.8844 40.9374 99.7328 40.5301C99.5812 40.1228 99.516 39.6884 99.5412 39.2545C99.3965 35.3718 103.026 31.7785 106.004 31.7785C107.282 31.7785 108.416 32.3332 108.524 33.7078C108.633 36.7947 104.304 37.7352 101.446 37.7955C101.342 38.1678 101.305 38.5558 101.338 38.941C101.317 39.2632 101.364 39.5863 101.478 39.8885C101.592 40.1908 101.769 40.4653 101.997 40.6936C102.225 40.9219 102.5 41.0989 102.802 41.2125C103.104 41.3262 103.427 41.3739 103.749 41.3526C104.467 41.2744 105.157 41.0285 105.762 40.6347C106.368 40.2409 106.872 39.7103 107.234 39.0857Z"
        fill="#003A5D"
      />
      <path
        d="M89.4728 33.4184C89.7863 33.4184 90.2807 33.322 90.4977 33.322C90.7147 33.322 91.1247 33.3943 91.1368 33.7078C91.1488 34.0213 90.7991 35.2754 90.7268 35.6009L90.0395 38.6396C89.7622 39.8454 89.4607 41.1838 89.1954 42.257H90.968L91.9567 37.4338C94.9953 34.2625 96.177 33.2376 96.8885 33.2376C96.9636 33.2303 97.0395 33.2396 97.1107 33.2648C97.1819 33.2899 97.2468 33.3303 97.3008 33.3831C97.3548 33.436 97.3966 33.4999 97.4232 33.5706C97.4499 33.6413 97.4608 33.7169 97.4552 33.7922C97.3861 34.5348 97.2323 35.2671 96.997 35.9747L95.9841 39.4957C95.7643 40.142 95.6344 40.8156 95.5982 41.4973C95.5896 41.6537 95.6158 41.8101 95.6747 41.9552C95.7337 42.1003 95.824 42.2305 95.9393 42.3366C96.0546 42.4426 96.1919 42.5219 96.3414 42.5686C96.4909 42.6152 96.6489 42.6283 96.804 42.6067C98.0943 42.6067 99.0348 41.2562 99.8547 39.9298L99.6256 39.4957C99.288 40.0262 98.5163 41.1959 97.9134 41.1959C97.7205 41.1959 97.5396 41.0632 97.5275 40.7377C97.5544 40.2529 97.6478 39.7742 97.8049 39.3148L98.9263 35.1669C99.1537 34.433 99.3032 33.6772 99.3724 32.912C99.3848 32.7537 99.3615 32.5946 99.3042 32.4464C99.2469 32.2983 99.157 32.1649 99.0413 32.0561C98.9256 31.9474 98.7869 31.8659 98.6355 31.8179C98.4841 31.7698 98.3239 31.7564 98.1666 31.7785C96.9608 31.7785 95.4536 32.6347 92.1376 36.4088L92.5476 34.5639C92.7767 33.5631 92.9937 32.5141 93.1987 31.7785C91.9695 32.2151 90.7127 32.5696 89.4366 32.8396L89.4728 33.4184Z"
        fill="#003A5D"
      />
      <path
        d="M144.952 38.7119L146.194 31.9835H137.162L137.379 30.9224L137.741 29.0775L137.849 28.5832H124.115L123.669 30.9224H134.968L134.136 35.3839H122.825L122.379 37.7352H136.089L136.716 34.3348H143.432L141.395 45.4282H134.69L135.305 42.0279H121.559L121.125 44.3671H132.496L131.676 48.8407H120.269L119.823 51.1799H133.316L133.786 48.8407L134.003 47.7796H143.264L144.53 41.0512H147.737L148.171 38.7119H144.952Z"
        fill="#009ADE"
      />
      <path
        d="M379.372 13.4865L378.142 20.2028H387.161L386.956 21.2639L386.583 23.1088L386.486 23.6152H400.22L400.666 21.2639H389.368L390.2 16.8025H401.511L401.957 14.4511H388.235L387.608 17.8636H380.903L382.941 6.7581H389.645L389.03 10.1705H402.765L403.211 7.81921H391.84L392.66 3.35774H404.055L404.501 1.00642H391.02L390.984 1.13906L390.55 3.35774L390.333 4.41884H381.06L379.806 11.1352H376.599L376.152 13.4865H379.372Z"
        fill="#009ADE"
      />
      <path
        d="M167.524 31.3566L165.294 28.945H151.74L154.562 31.9233L150.945 50.975H161.242L164.353 48.7322L165.655 41.8953L163.678 39.7851L166.294 37.8317L167.524 31.3566ZM160.736 42.3776L159.976 46.3206C159.948 46.4922 159.863 46.6494 159.735 46.7667C159.609 46.8771 159.444 46.9334 159.277 46.9235H156.503L157.504 41.7988H160.302C160.374 41.7886 160.447 41.7953 160.516 41.8183C160.585 41.8413 160.648 41.8801 160.7 41.9315C160.739 41.9981 160.763 42.073 160.77 42.1503C160.776 42.2277 160.764 42.3054 160.736 42.3776ZM162.267 33.6476L161.628 37.048C161.599 37.2393 161.51 37.4165 161.375 37.5544C161.241 37.6685 161.068 37.7249 160.892 37.7112H158.276L159.216 33.0567H161.893C161.962 33.0518 162.032 33.0623 162.096 33.0873C162.161 33.1123 162.219 33.1513 162.267 33.2014C162.306 33.2693 162.327 33.3462 162.327 33.4245C162.327 33.5028 162.306 33.5798 162.267 33.6476Z"
        fill="#003A5D"
      />
      <path
        d="M183.489 31.3566L181.258 28.945H170.937L166.704 51.0232H170.322C170.648 51.0391 170.97 50.9457 171.238 50.7579C171.485 50.5613 171.645 50.2756 171.684 49.9621L173.035 43.0649H174.638L175.953 51.0232H179.956C180.088 51.0334 180.22 51.0137 180.343 50.9657C180.466 50.9177 180.577 50.8426 180.667 50.7459C180.768 50.6393 180.843 50.5112 180.887 50.3712C180.931 50.2312 180.943 50.0831 180.921 49.938L179.558 42.3535L181.765 40.6774L183.489 31.3566ZM178.22 33.6476L177.303 38.3743C177.27 38.5443 177.186 38.7 177.062 38.8205C176.933 38.9256 176.77 38.9812 176.604 38.9772H173.83L174.964 33.0809H177.773C177.843 33.076 177.912 33.0864 177.977 33.1114C178.041 33.1364 178.099 33.1754 178.147 33.2256C178.194 33.2842 178.225 33.3536 178.237 33.4273C178.25 33.5011 178.244 33.5768 178.22 33.6476Z"
        fill="#003A5D"
      />
      <path
        d="M196.053 28.9932H190.109L182.633 51.0232H186.54C186.844 51.0408 187.145 50.9559 187.396 50.782C187.632 50.5703 187.799 50.2933 187.878 49.9862L188.746 47.0923H191.773L191.459 51.0232H196.403L197.247 30.2231C197.261 30.057 197.24 29.8898 197.186 29.732C197.133 29.5743 197.046 29.4295 196.934 29.3067C196.689 29.0979 196.375 28.9863 196.053 28.9932ZM192.147 43.2217H189.88L192.979 32.8035L192.147 43.2217Z"
        fill="#003A5D"
      />
      <path
        d="M212.645 28.9932H205.495L202.348 31.4048L199.008 48.7322L201.238 51.0835H208.389L211.536 48.7322L212.428 44.0657C212.47 43.9333 212.482 43.7932 212.463 43.6556C212.445 43.518 212.396 43.3863 212.32 43.2699C212.224 43.1718 212.106 43.0969 211.977 43.0509C211.847 43.0049 211.709 42.9891 211.572 43.0046H207.822L207.171 46.3206C207.134 46.4888 207.05 46.6433 206.93 46.7667C206.802 46.8749 206.639 46.9308 206.472 46.9235H204.699C204.63 46.9284 204.561 46.918 204.496 46.8929C204.431 46.8679 204.373 46.8289 204.325 46.7788C204.289 46.7142 204.271 46.6416 204.271 46.5678C204.271 46.4939 204.289 46.4213 204.325 46.3567L206.737 33.6717C206.774 33.5035 206.858 33.349 206.978 33.2255C207.11 33.1152 207.277 33.0554 207.448 33.0567H209.221C209.29 33.0518 209.359 33.0622 209.424 33.0872C209.489 33.1123 209.547 33.1513 209.595 33.2014C209.63 33.2663 209.648 33.3388 209.648 33.4124C209.648 33.4861 209.63 33.5586 209.595 33.6234L208.98 36.9032H212.597C212.923 36.913 213.243 36.8202 213.514 36.638C213.76 36.4334 213.922 36.1462 213.972 35.8301L214.828 31.3324L212.645 28.9932Z"
        fill="#003A5D"
      />
      <path
        d="M226.199 39.8816L231.564 30.0663C231.643 29.9582 231.694 29.8324 231.713 29.7C231.732 29.5676 231.718 29.4325 231.673 29.3067C231.616 29.2019 231.53 29.1163 231.425 29.0605C231.32 29.0047 231.2 28.9813 231.082 28.9932H227.139L222.629 37.6508H221.653L223.305 28.9932H218.481L214.261 51.0232H217.878C218.205 51.0429 218.529 50.9492 218.795 50.7579C219.047 50.5635 219.211 50.2774 219.253 49.9621L220.833 41.7385H221.785L223.124 51.0232H227.187C227.313 51.0328 227.439 51.0115 227.555 50.9611C227.671 50.9106 227.772 50.8326 227.85 50.7338C227.921 50.6153 227.966 50.4832 227.983 50.3461C228 50.209 227.987 50.07 227.947 49.938L226.199 39.8816Z"
        fill="#003A5D"
      />
      <path
        d="M230.539 51.0232H240.355C240.682 51.0429 241.005 50.9492 241.271 50.7579C241.523 50.5635 241.687 50.2774 241.729 49.9621L242.32 46.9355H236.146L237.111 41.8952H240.572C240.872 41.9139 241.169 41.8192 241.404 41.63C241.654 41.419 241.841 41.1439 241.946 40.8341L242.971 37.8076H237.895L238.799 33.0567H243.622C243.949 33.0725 244.271 32.9792 244.539 32.7914C244.786 32.5948 244.946 32.3091 244.985 31.9956L245.54 28.969H234.687L230.539 51.0232Z"
        fill="#003A5D"
      />
      <path
        d="M246.697 33.0567H250.604L247.131 51.0232H250.749C251.075 51.039 251.397 50.9457 251.665 50.7579C251.912 50.5613 252.072 50.2756 252.111 49.9621L255.379 33.0808H258.116C258.443 33.1005 258.767 33.0068 259.032 32.8156C259.284 32.6211 259.449 32.3351 259.491 32.0197L260.045 28.9932H247.457L246.697 33.0567Z"
        fill="#003A5D"
      />
      <path
        d="M279.278 28.9932H266.014L268.884 32.0197L265.64 48.7322L267.871 51.0835H274.973L278.169 48.7322L279.061 44.0657C279.103 43.9333 279.115 43.7932 279.096 43.6556C279.077 43.518 279.028 43.3863 278.952 43.2699C278.854 43.1708 278.735 43.0954 278.603 43.0494C278.471 43.0034 278.331 42.9881 278.193 43.0046H274.455L273.767 46.3206C273.731 46.4917 273.642 46.6474 273.514 46.7667C273.452 46.8219 273.379 46.8642 273.301 46.8911C273.222 46.918 273.139 46.929 273.056 46.9235H271.356C271.284 46.9336 271.211 46.9244 271.143 46.8968C271.076 46.8693 271.017 46.8244 270.973 46.7668C270.929 46.7091 270.901 46.6408 270.892 46.5687C270.883 46.4967 270.893 46.4235 270.922 46.3567L273.333 33.6717C273.366 33.5018 273.45 33.346 273.574 33.2255C273.704 33.1204 273.866 33.0648 274.033 33.0688H275.793C275.865 33.0587 275.938 33.0679 276.005 33.0954C276.073 33.123 276.131 33.1678 276.175 33.2255C276.22 33.2831 276.248 33.3515 276.257 33.4235C276.266 33.4955 276.256 33.5687 276.227 33.6355L275.612 36.9153H279.23C279.555 36.925 279.876 36.8322 280.146 36.65C280.27 36.5512 280.373 36.4291 280.449 36.2904C280.526 36.1518 280.575 35.9995 280.592 35.8421L281.46 31.3445L279.278 28.9932Z"
        fill="#003A5D"
      />
      <path
        d="M293.217 28.9931L291.505 37.9643H288.165L289.889 28.9931H285.066L280.833 51.0232H284.451C284.778 51.0409 285.101 50.9474 285.367 50.7579C285.495 50.6588 285.601 50.5352 285.68 50.3944C285.758 50.2535 285.808 50.0983 285.826 49.938L287.309 42.1846H290.649L288.961 50.9991H292.578C292.905 51.0187 293.229 50.925 293.494 50.7338C293.746 50.5393 293.911 50.2533 293.953 49.938L297.98 28.969L293.217 28.9931Z"
        fill="#003A5D"
      />
      <path
        d="M309.652 28.9931H303.708L296.232 51.0232H300.138C300.439 51.0429 300.738 50.9577 300.982 50.782C301.244 50.5659 301.426 50.269 301.501 49.938L302.369 47.044H305.444L305.118 51.0232H310.062L310.906 30.2231C310.92 30.0569 310.9 29.8897 310.846 29.732C310.792 29.5743 310.706 29.4294 310.593 29.3066C310.332 29.0834 309.995 28.9709 309.652 28.9931ZM305.733 43.2216H303.478L306.565 32.8035L305.733 43.2216Z"
        fill="#003A5D"
      />
      <path
        d="M321.518 28.9932H316.694L312.474 51.0232H321.096C321.426 51.0408 321.753 50.9474 322.024 50.7579C322.266 50.5573 322.426 50.2736 322.47 49.9621L323.025 46.9355H318.081L321.518 28.9932Z"
        fill="#003A5D"
      />
      <path
        d="M333.889 28.9932H329.066L324.845 51.0232H333.467C333.797 51.0408 334.124 50.9474 334.395 50.7579C334.638 50.5573 334.797 50.2736 334.841 49.9621L335.396 46.9355H330.44L333.889 28.9932Z"
        fill="#003A5D"
      />
      <path
        d="M351.699 32.0197L352.241 28.9932H341.45L337.217 51.0232H347.044C347.371 51.039 347.693 50.9457 347.961 50.7579C348.208 50.5613 348.368 50.2756 348.407 49.9621L348.998 46.9355H342.824L343.789 41.8953H347.249C347.553 41.907 347.851 41.8133 348.094 41.63C348.336 41.4149 348.519 41.1407 348.624 40.8342L349.649 37.8076H344.585L345.477 33.0808H350.3C350.627 33.0986 350.95 33.005 351.217 32.8156C351.469 32.618 351.641 32.3349 351.699 32.0197Z"
        fill="#003A5D"
      />
      <path
        d="M361.876 40.8944L359.802 28.9931H355.304L351.072 51.0232H354.412C354.738 51.039 355.061 50.9457 355.328 50.7579C355.451 50.6567 355.553 50.5321 355.627 50.3914C355.702 50.2506 355.748 50.0965 355.762 49.938L357.836 39.0857L359.705 49.938C359.734 50.24 359.877 50.5199 360.103 50.7217C360.207 50.8168 360.329 50.8904 360.461 50.938C360.594 50.9856 360.734 51.0063 360.875 50.9991H364.408L368.64 28.969H364.203L361.876 40.8944Z"
        fill="#003A5D"
      />
      <path
        d="M374.946 28.9932L371.787 31.4048L368.459 48.7322L370.678 51.0835H378.298L381.458 48.7322L383.411 38.6517H377.84C377.541 38.6436 377.248 38.7371 377.008 38.9169C376.756 39.1303 376.568 39.4102 376.466 39.7248L375.513 42.5946H377.84L377.081 46.3206C377.053 46.4922 376.968 46.6494 376.839 46.7667C376.777 46.8219 376.705 46.8642 376.626 46.8911C376.547 46.918 376.464 46.929 376.381 46.9235H374.151C374.081 46.9302 374.011 46.9206 373.947 46.8955C373.882 46.8704 373.823 46.8304 373.777 46.7788C373.731 46.7196 373.702 46.6496 373.691 46.5757C373.681 46.5017 373.689 46.4263 373.716 46.3568L376.128 33.6717C376.156 33.5001 376.241 33.3428 376.369 33.2255C376.497 33.1184 376.661 33.0626 376.827 33.0688H378.901C378.971 33.0639 379.04 33.0743 379.104 33.0993C379.169 33.1243 379.227 33.1633 379.275 33.2135C379.32 33.2732 379.349 33.3431 379.359 33.4168C379.37 33.4906 379.361 33.5657 379.335 33.6355L378.708 36.9153H382.326C382.652 36.9284 382.974 36.8354 383.242 36.65C383.368 36.5523 383.474 36.4306 383.552 36.292C383.631 36.1533 383.681 36.0004 383.701 35.8421L384.557 31.3445L382.338 28.9329L374.946 28.9932Z"
        fill="#003A5D"
      />
      <path
        d="M388.668 28.9932L384.436 51.0232H394.263C394.59 51.039 394.912 50.9457 395.18 50.7579C395.426 50.5586 395.589 50.275 395.638 49.9621L396.217 46.9355H390.043L391.008 41.8953H394.468C394.772 41.907 395.07 41.8133 395.312 41.63C395.555 41.4149 395.738 41.1407 395.843 40.8342L396.868 37.8076H391.804L392.696 33.0808H397.519C397.846 33.0986 398.169 33.005 398.435 32.8156C398.687 32.6211 398.852 32.3351 398.894 32.0197L399.448 28.9932H388.668Z"
        fill="#003A5D"
      />
      <path
        d="M130.59 22.0357L129.384 15.4037L128.179 22.0357C128.152 22.2341 128.048 22.4143 127.891 22.538C127.733 22.6618 127.534 22.7195 127.335 22.6988H125.285L127.865 9.15767H130.638L131.844 16.4648L133.243 9.15767H136.016L133.412 22.6988H131.241C131.07 22.6872 130.91 22.6133 130.79 22.4911C130.67 22.3688 130.599 22.2068 130.59 22.0357Z"
        fill="#003A5D"
      />
      <path
        d="M142.889 21.2398L140.948 22.6988H136.559L135.184 21.2398L137.234 10.6046L139.176 9.15767H143.541L144.915 10.6046L144.385 13.3659C144.357 13.5644 144.254 13.7446 144.097 13.8683C143.939 13.9921 143.74 14.0497 143.541 14.0291H141.298L141.672 12.0154C141.672 11.7984 141.672 11.6657 141.406 11.6657H140.333C140.28 11.6608 140.226 11.6666 140.175 11.6829C140.124 11.6992 140.077 11.7255 140.037 11.7604C139.996 11.7953 139.963 11.838 139.939 11.886C139.916 11.9339 139.902 11.9862 139.899 12.0395L138.416 19.7929C138.416 20.0099 138.416 20.1425 138.681 20.1425H139.766C139.871 20.1494 139.975 20.1157 140.056 20.0484C140.136 19.9811 140.188 19.8853 140.2 19.7808L140.598 17.6827H142.901C143.348 17.6827 143.516 17.9239 143.432 18.3459L142.889 21.2398Z"
        fill="#003A5D"
      />
      <path
        d="M152.427 9.90531L151.909 22.6989H148.87L149.063 20.2873H147.158L146.627 22.0719C146.595 22.2614 146.493 22.432 146.341 22.5502C146.19 22.6684 145.999 22.7257 145.808 22.7109H143.396L147.99 9.16977H151.607C151.713 9.14595 151.822 9.14768 151.926 9.17481C152.031 9.20195 152.127 9.25369 152.207 9.32571C152.288 9.39773 152.35 9.48794 152.388 9.58878C152.426 9.68962 152.44 9.79815 152.427 9.90531ZM149.256 17.8998L149.775 11.497L147.869 17.8998H149.256Z"
        fill="#003A5D"
      />
      <path
        d="M161.688 9.90528L161.169 22.6989H158.131L158.336 20.2873H156.431L155.9 22.0718C155.868 22.2614 155.765 22.4319 155.614 22.5502C155.462 22.6684 155.272 22.7257 155.08 22.7109H152.668L157.263 9.16974H160.88C160.984 9.14816 161.092 9.15159 161.195 9.17974C161.298 9.20788 161.393 9.25996 161.471 9.3317C161.55 9.40343 161.611 9.4928 161.648 9.59249C161.686 9.69218 161.7 9.79937 161.688 9.90528ZM158.529 17.8998L159.035 11.4969L157.13 17.8998H158.529Z"
        fill="#003A5D"
      />
      <path
        d="M163.545 12.8474L163.075 12.3169L163.569 9.70027L164.196 9.23001H166.451L166.922 9.77262L166.451 12.3772L165.824 12.8474H163.545ZM166.331 9.91732C166.334 9.8996 166.334 9.88129 166.329 9.86374C166.325 9.8462 166.317 9.82988 166.305 9.81598C166.294 9.80209 166.279 9.79098 166.263 9.78347C166.246 9.77596 166.228 9.77226 166.21 9.77262H164.221C164.202 9.77094 164.184 9.77313 164.166 9.77904C164.149 9.78494 164.132 9.79445 164.119 9.80694C164.105 9.81942 164.094 9.83462 164.087 9.85155C164.08 9.86849 164.076 9.88678 164.076 9.90526L163.642 12.1601C163.642 12.2566 163.642 12.3169 163.75 12.3169H165.752C165.848 12.3169 165.885 12.3169 165.897 12.1722L166.331 9.91732ZM165.462 11.9069H165.101L164.92 11.3522H164.775L164.691 11.8225C164.685 11.8651 164.663 11.9039 164.629 11.9308C164.595 11.9576 164.553 11.9707 164.51 11.9672H164.172L164.51 10.1464H165.499L165.764 10.4358L165.667 10.9905L165.39 11.2196L165.571 11.714C165.607 11.8466 165.559 11.9069 165.462 11.9069ZM165.089 10.9181C165.185 10.9181 165.209 10.9181 165.221 10.7855V10.689C165.221 10.5926 165.221 10.5685 165.125 10.5685H164.896L164.823 10.9181H165.089Z"
        fill="#003A5D"
      />
      <path
        d="M183.212 9.15767L180.74 22.0357C180.713 22.2341 180.609 22.4143 180.452 22.538C180.294 22.6618 180.095 22.7195 179.896 22.6988H177.942L179.799 13.0524L176.676 22.0718C176.63 22.2759 176.513 22.4565 176.344 22.5806C176.176 22.7047 175.968 22.7638 175.76 22.7471H173.264L173.674 13.1006L171.937 22.1321C171.91 22.3306 171.807 22.5107 171.65 22.6345C171.492 22.7583 171.293 22.8159 171.093 22.7953H169.14L171.744 9.25413H175.362C175.808 9.25413 175.989 9.48323 175.989 9.90526L175.615 18.792L178.919 9.25413L183.212 9.15767Z"
        fill="#003A5D"
      />
      <path
        d="M190.724 9.90528L190.206 22.6989H187.167L187.36 20.2873H185.467L184.924 22.0718C184.892 22.2614 184.79 22.4319 184.638 22.5502C184.486 22.6684 184.296 22.7257 184.104 22.7109H181.693L186.287 9.16974H189.904C190.009 9.14867 190.117 9.15236 190.22 9.18052C190.324 9.20868 190.419 9.26053 190.498 9.33196C190.578 9.40338 190.64 9.49239 190.679 9.59193C190.718 9.69146 190.734 9.79875 190.724 9.90528ZM187.553 17.8998L188.059 11.4969L186.154 17.8998H187.553Z"
        fill="#003A5D"
      />
      <path
        d="M199.996 22.0357C200.023 22.1158 200.029 22.2012 200.014 22.2843C199.999 22.3673 199.964 22.4453 199.911 22.5112C199.858 22.5771 199.79 22.6288 199.712 22.6616C199.635 22.6943 199.55 22.7071 199.466 22.6988H197.054L196.246 17.8033H195.258L194.438 22.0357C194.411 22.2341 194.307 22.4143 194.15 22.538C193.992 22.6618 193.793 22.7195 193.594 22.6988H191.351L193.943 9.15767H200.213L201.576 10.6046L200.479 16.3563L199.128 17.3813L199.996 22.0357ZM197.404 15.2832C197.496 15.2852 197.587 15.2551 197.659 15.1979C197.732 15.1407 197.782 15.06 197.802 14.9696L198.356 12.0637C198.356 11.8466 198.356 11.714 198.091 11.714H196.367L195.668 15.3314L197.404 15.2832Z"
        fill="#003A5D"
      />
      <path
        d="M208.98 21.2398L207.039 22.6988H202.625L201.263 21.2398L203.313 10.6046L205.242 9.15767H209.643L211.066 10.6046L210.535 13.3659C210.508 13.5644 210.405 13.7446 210.248 13.8683C210.09 13.9921 209.891 14.0497 209.691 14.0291H207.449L207.834 12.0154C207.834 11.7984 207.834 11.6657 207.569 11.6657H206.484C206.377 11.659 206.272 11.6944 206.191 11.7642C206.11 11.8341 206.059 11.9329 206.05 12.0395L204.518 19.7929C204.518 20.0099 204.518 20.1425 204.784 20.1425H205.869C205.963 20.14 206.053 20.103 206.122 20.0386C206.191 19.9742 206.234 19.8868 206.243 19.7929L206.653 17.6948H208.956C209.402 17.6948 209.571 17.9359 209.486 18.358L208.98 21.2398Z"
        fill="#003A5D"
      />
      <path
        d="M214.225 17.2727L213.321 22.0357C213.294 22.2341 213.19 22.4143 213.033 22.538C212.875 22.6618 212.676 22.7195 212.477 22.6988H210.234L212.826 9.15767H215.793L214.683 14.6682H216.733L217.794 9.15767H220.761L218.301 22.0357C218.274 22.2341 218.17 22.4143 218.013 22.538C217.855 22.6618 217.656 22.7195 217.457 22.6988H215.214L216.251 17.2727H214.225Z"
        fill="#003A5D"
      />
      <path
        d="M237.955 9.15764L235.484 22.0356C235.456 22.2341 235.353 22.4143 235.196 22.538C235.038 22.6618 234.839 22.7194 234.639 22.6988H232.686L234.543 13.0524L231.42 22.0718C231.374 22.2759 231.256 22.4565 231.088 22.5806C230.919 22.7046 230.712 22.7637 230.504 22.7471H228.008L228.418 13.1006L226.741 22.0356C226.714 22.2341 226.611 22.4143 226.454 22.538C226.296 22.6618 226.097 22.7194 225.897 22.6988H223.944L226.549 9.15764H230.166C230.253 9.14457 230.343 9.15263 230.427 9.18113C230.51 9.20963 230.586 9.25776 230.647 9.32148C230.709 9.38521 230.754 9.46269 230.779 9.54746C230.805 9.63223 230.809 9.72183 230.793 9.80877L230.359 18.6955L233.663 9.15764H237.955Z"
        fill="#003A5D"
      />
      <path
        d="M245.467 9.90528L244.949 22.6989H241.91L242.103 20.2873H240.21L239.68 22.0718C239.642 22.2611 239.537 22.4303 239.384 22.5479C239.231 22.6655 239.04 22.7234 238.848 22.7109H236.436L241.03 9.16974H244.647C244.752 9.14867 244.861 9.15236 244.964 9.18052C245.067 9.20868 245.162 9.26053 245.242 9.33196C245.321 9.40338 245.383 9.49239 245.422 9.59193C245.462 9.69146 245.477 9.79875 245.467 9.90528ZM242.296 17.8998L242.803 11.4969L240.897 17.8998H242.296Z"
        fill="#003A5D"
      />
      <path
        d="M256.356 10.6046L254.306 21.2398L252.365 22.6988H246.034L248.627 9.15767H254.981L256.356 10.6046ZM251.195 20.1787C251.3 20.1856 251.403 20.1519 251.484 20.0846C251.565 20.0172 251.617 19.9214 251.629 19.817L253.124 12.0154C253.124 11.7984 253.124 11.6657 252.859 11.6657H251.135L249.495 20.1787H251.195Z"
        fill="#003A5D"
      />
      <path
        d="M261.143 22.0357L259.937 15.4037L258.731 22.0357C258.704 22.2341 258.601 22.4143 258.443 22.538C258.286 22.6618 258.086 22.7195 257.887 22.6988H255.837L258.442 9.15767H261.203L262.481 16.4648L263.88 9.15767H266.641L264.049 22.6988H261.878C261.786 22.7037 261.694 22.6904 261.608 22.6597C261.521 22.629 261.441 22.5815 261.373 22.5199C261.305 22.4583 261.249 22.3838 261.21 22.3007C261.17 22.2176 261.147 22.1275 261.143 22.0357Z"
        fill="#003A5D"
      />
      <path
        d="M270.367 14.5717H273.478L272.851 16.4287C272.809 16.6188 272.701 16.7882 272.547 16.9072C272.393 17.0262 272.202 17.0872 272.007 17.0798H269.885L269.294 20.1787H273.08L272.718 22.0357C272.691 22.2318 272.59 22.4101 272.435 22.5336C272.28 22.657 272.084 22.7162 271.886 22.6988H265.857L268.462 9.15767H275.094L274.756 11.0146C274.726 11.2109 274.622 11.3881 274.465 11.5093C274.308 11.6306 274.11 11.6866 273.912 11.6657H270.934L270.367 14.5717Z"
        fill="#003A5D"
      />
      <path
        d="M282.956 16.0187L281.943 21.2398L280.001 22.6988H275.323L273.948 21.2398L274.527 18.2856H276.818C277.268 18.2856 277.449 18.5026 277.361 18.9367L277.18 19.829C277.18 20.0702 277.18 20.1787 277.445 20.1787H278.82C278.872 20.1837 278.925 20.1783 278.975 20.1627C279.025 20.1472 279.072 20.1219 279.112 20.0883C279.153 20.0546 279.186 20.0134 279.21 19.9668C279.235 19.9202 279.249 19.8693 279.254 19.817L279.784 17.0798H276.493L275.13 15.6569L276.095 10.6046L278.036 9.15767H282.522L283.896 10.6046L283.51 12.6304C283.483 12.8289 283.38 13.009 283.223 13.1328C283.065 13.2565 282.866 13.3142 282.666 13.2936H280.424L280.665 12.0154C280.665 11.7984 280.665 11.6657 280.399 11.6657H279.194C279.14 11.6608 279.087 11.6666 279.036 11.6829C278.985 11.6992 278.938 11.7255 278.897 11.7604C278.857 11.7953 278.824 11.838 278.8 11.886C278.776 11.9339 278.763 11.9862 278.76 12.0395L278.277 14.5717H281.557L282.956 16.0187Z"
        fill="#003A5D"
      />
      <path
        d="M292.373 16.0187L291.36 21.2398L289.443 22.6988H284.74L283.414 21.2398L283.993 18.2856H286.296C286.73 18.2856 286.911 18.5026 286.826 18.9367L286.658 19.829C286.658 20.0702 286.658 20.1787 286.911 20.1787H288.237C288.29 20.1837 288.342 20.1783 288.393 20.1627C288.443 20.1472 288.489 20.1219 288.53 20.0883C288.57 20.0546 288.603 20.0134 288.628 19.9668C288.652 19.9202 288.667 19.8693 288.671 19.817L289.202 17.0798H285.91L284.548 15.6569L285.512 10.6046L287.454 9.15767H291.939L293.314 10.6046L292.928 12.6304C292.901 12.8289 292.798 13.009 292.64 13.1328C292.483 13.2565 292.283 13.3142 292.084 13.2936H289.841L290.082 12.0154C290.082 11.7984 290.082 11.6657 289.817 11.6657H288.611C288.558 11.6608 288.504 11.6666 288.453 11.6829C288.402 11.6992 288.355 11.7255 288.315 11.7604C288.274 11.7953 288.241 11.838 288.217 11.886C288.194 11.9339 288.18 11.9862 288.177 12.0395L287.695 14.5717H290.974L292.373 16.0187Z"
        fill="#003A5D"
      />
      <path
        d="M295.231 12.8474L294.761 12.3169L295.255 9.70027L295.882 9.23001H298.101L298.583 9.77262L298.077 12.3772L297.462 12.8474H295.231ZM298.017 9.91732C298.017 9.83291 298.017 9.77262 297.896 9.77262H295.918C295.822 9.77262 295.774 9.77262 295.762 9.90526L295.328 12.1601C295.328 12.2566 295.328 12.3169 295.448 12.3169H297.438C297.534 12.3169 297.57 12.3169 297.582 12.1722L298.017 9.91732ZM297.148 11.9069H296.787L296.606 11.3522H296.461L296.377 11.8225C296.371 11.8651 296.349 11.9039 296.315 11.9308C296.281 11.9576 296.239 11.9707 296.196 11.9672H295.87L296.196 10.1464H297.197L297.45 10.4358L297.353 10.9905L297.076 11.2196L297.257 11.714C297.305 11.8466 297.245 11.9069 297.148 11.9069ZM296.775 10.9181C296.871 10.9181 296.895 10.9181 296.919 10.7855V10.689C296.919 10.5926 296.919 10.5685 296.823 10.5685H296.594L296.521 10.9181H296.775Z"
        fill="#003A5D"
      />
      <path
        d="M323.495 0.644691L319.805 21.6257C319.789 21.7856 319.74 21.9405 319.663 22.0815C319.586 22.2225 319.481 22.3466 319.356 22.4466C319.23 22.5466 319.085 22.6204 318.931 22.6637C318.776 22.707 318.614 22.719 318.454 22.6988H315.271L318.044 6.91487L313.221 21.5895C313.149 21.9222 312.96 22.2178 312.687 22.4216C312.415 22.6255 312.078 22.724 311.738 22.6988H307.65L308.06 6.91487L305.48 21.6257C305.464 21.7867 305.415 21.9428 305.337 22.0847C305.259 22.2265 305.154 22.3512 305.026 22.4514C304.899 22.5515 304.753 22.625 304.597 22.6676C304.441 22.7101 304.278 22.7207 304.117 22.6988H300.934L304.817 0.644691H310.761C311.497 0.644691 311.81 1.01849 311.81 1.71786L311.425 16.1875L316.561 0.644691H323.495Z"
        fill="#009ADE"
      />
      <path
        d="M329.741 9.45912H334.818L333.841 12.4857C333.778 12.7972 333.607 13.0762 333.357 13.2728C333.107 13.4695 332.796 13.5708 332.478 13.5589H329.018L328.138 18.5871H334.311L333.769 21.6136C333.752 21.7735 333.704 21.9285 333.627 22.0694C333.55 22.2104 333.445 22.3345 333.319 22.4345C333.194 22.5345 333.049 22.6083 332.894 22.6516C332.74 22.695 332.578 22.7069 332.418 22.6868H322.591L326.462 0.644691H337.314L336.819 3.67126C336.802 3.83122 336.752 3.986 336.673 4.12647C336.595 4.26693 336.489 4.39025 336.362 4.48914C336.235 4.58803 336.089 4.6605 335.934 4.70227C335.779 4.74405 335.616 4.75428 335.457 4.73237H330.634L329.741 9.45912Z"
        fill="#009ADE"
      />
      <path
        d="M344.452 21.6257L342.414 10.7734L340.521 21.6257C340.504 21.7856 340.456 21.9405 340.379 22.0815C340.302 22.2225 340.197 22.3466 340.072 22.4466C339.946 22.5466 339.801 22.6204 339.646 22.6637C339.492 22.707 339.33 22.719 339.17 22.6988H335.818L339.701 0.644691H344.211L346.478 12.558L348.527 0.644691H353.037L349.154 22.6988H345.634C345.34 22.6947 345.057 22.5834 344.84 22.3858C344.622 22.1882 344.484 21.9179 344.452 21.6257Z"
        fill="#009ADE"
      />
      <path
        d="M354.352 7.57806L355.895 0.644691H360.718L357.945 6.52902C357.841 6.87556 357.616 7.17282 357.31 7.36571C357.004 7.5586 356.638 7.63405 356.281 7.57806H354.352Z"
        fill="#009ADE"
      />
      <path
        d="M372.981 11.8225L371.438 20.3355L368.327 22.7471H360.694L358.427 20.3355L359.283 15.5123H362.997C363.733 15.5123 364.01 15.862 363.889 16.5854L363.636 18.0324C363.576 18.4062 363.696 18.5991 364.07 18.5991H366.313C366.398 18.6074 366.484 18.5982 366.566 18.5719C366.647 18.5456 366.723 18.5029 366.787 18.4464C366.852 18.3899 366.904 18.3208 366.94 18.2434C366.977 18.166 366.997 18.0819 367 17.9962L367.796 13.5589H362.442L360.212 11.2317L361.671 3.00809L364.782 0.596485H372.089L374.368 3.00809L373.849 6.312C373.833 6.47186 373.785 6.62684 373.708 6.76781C373.63 6.90878 373.526 7.03291 373.4 7.13289C373.274 7.23287 373.13 7.30669 372.975 7.35001C372.82 7.39334 372.658 7.40529 372.499 7.38516H368.881L369.135 5.32324C369.195 4.97355 369.135 4.74445 368.689 4.74445H366.771C366.599 4.73436 366.43 4.79166 366.299 4.9042C366.169 5.01674 366.087 5.17568 366.072 5.34735L365.409 9.45915H370.762L372.981 11.8225Z"
        fill="#009ADE"
      />
      <path
        d="M118.05 59.5H394.179L394.794 56.1117H118.665L118.05 59.5ZM129.529 0.5L128.878 3.97272H300.295L300.91 0.5H129.529Z"
        fill="#009ADE"
      />
    </svg>
  );
});

MBCGHorizontalLight.displayName = "MBCGHorizontalLight";

export default MBCGHorizontalLight;
