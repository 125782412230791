import * as React from "react";
import { styled } from "styled-components";
import { getGameTeamImage } from "../../lib/images";
import { colors } from "../../styles/styleUtils";
import { Team } from "../../types/apiTypes.types";

type TeamChipProps = {
  team?: Team;
};

const ChipContainer = styled.div<{ $bgColor: string }>`
  aspect-ratio: 1/1;
  border: 1px solid ${({ $bgColor }) => $bgColor || colors["lvl_-1"]};
  border-radius: 50%;
  container-name: chipcontainer;
  container-type: inline-size;
  display: grid;
  place-content: center;
  > div {
    background-color: ${({ $bgColor }) => $bgColor || colors["lvl_-1"]};
  }
`;

const InnerChip = styled.div`
  border-radius: 50%;
  display: grid;
  grid-area: 1/1;
  height: 90cqi;
  place-content: center;
  width: 90cqi;
  @container chipcontainer (max-width: 35px) {
    height: 88cqi;
    width: 88cqi;
  }
`;

const TeamImage = styled.img`
  grid-area: 1/1;
  height: 60cqi;
  width: 60cqi;
  @container chipcontainer (max-width: 35px) {
    height: 58cqi;
    width: 58cqi;
  }
`;

/**
 * Stylized display for a team logo.
 */
export const MMLTeamChip = ({ team }: TeamChipProps) => {
  return (
    <ChipContainer $bgColor={team?.color || colors["lvl_3"]}>
      <InnerChip>
        {team ? (
          <TeamImage
            src={getGameTeamImage(team.link, "bgd")}
            aria-label={`${team?.school} ${team?.nick} logo`}
            alt={`${team?.school} ${team?.nick} logo`}
            draggable="false"
          />
        ) : null}
      </InnerChip>
    </ChipContainer>
  );
};
