import { styled } from "styled-components";
import { colors } from "../../styles/styleUtils";

type GameTypeChipProps = {
  gameType: "c" | "t" | "u" | "s";
};

const Chip = styled.div<{ mode: "filled" | "stroke" }>`
  border: 1px solid ${colors["lvl_-5"]};
  border-radius: 50%;
  display: grid;
  font-size: 8px;
  height: 13px;
  place-content: center;
  width: 13px;

  ${({ mode }) =>
    mode === "filled" &&
    `background-color: ${colors["lvl_-5"]}; 
         color: ${colors.lvl_5}`};

  > * {
    grid-area: 1/1;
    font-weight: 500;
    // Helps center the game mode letter as best as possible.
    transform: translateX(0.3px);
  }
`;

export const MMLGameTypeChip = ({ gameType }: GameTypeChipProps) => {
  return (
    <Chip mode={["c", "t"].includes(gameType) ? "filled" : "stroke"}>
      <p>{gameType.toUpperCase()}</p>
    </Chip>
  );
};
