const LiveActivityOn = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M22.8428 12.5874C22.2723 12.8047 21.6746 12.9134 21.0226 12.9134C18.1701 12.9134 15.8609 10.6042 15.8609 7.75165C15.8609 6.71931 15.9968 5.6598 16.5401 4.8448C15.5078 4.49163 14.3939 4.27429 13.2257 4.27429C7.8467 4.27429 3.5 8.62099 3.5 14C3.5 19.3791 7.8467 23.7258 13.2257 23.7258C18.6048 23.7258 22.9515 19.3791 22.9515 14C22.9515 13.511 22.8972 13.022 22.8428 12.5874ZM13.0355 6.63784C12.9811 6.69217 12.954 6.7465 12.954 6.828V14.3261H7.19459C7.11309 14.3261 7.03159 14.3532 6.97725 14.4076C6.92292 14.4619 6.89575 14.5162 6.89575 14.5977C6.89575 14.6792 6.92292 14.7607 6.97725 14.8151C7.03159 14.8694 7.11309 14.8966 7.19459 14.8966H13.2256C13.3071 14.8966 13.3886 14.8694 13.443 14.8151C13.4973 14.7607 13.5245 14.6792 13.5245 14.5977V6.828C13.5245 6.7465 13.4973 6.69217 13.443 6.63784C13.3886 6.5835 13.3071 6.55634 13.2256 6.55634C13.1441 6.55634 13.0898 6.5835 13.0355 6.63784Z"
        fill="var(--color_lvl_-5, #17171d)"
      />
      <path
        d="M24.4999 7.75165C24.4999 9.67215 22.943 11.229 21.0225 11.229C19.102 11.229 17.5452 9.67215 17.5452 7.75165C17.5452 5.83116 19.102 4.27429 21.0225 4.27429C22.943 4.27429 24.4999 5.83116 24.4999 7.75165Z"
        fill="var(--color_lvl_-5, #17171d)"
      />
    </svg>
  );
};

export default LiveActivityOn;
