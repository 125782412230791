import * as React from "react";

export default function Close() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1779 3.04381C15.0838 2.94967 14.9311 2.94967 14.837 3.04381L3.26554 14.6152C3.1714 14.7094 3.1714 14.862 3.26554 14.9562C3.35968 15.0503 3.51232 15.0503 3.60647 14.9562L15.1779 3.38474C15.2721 3.2906 15.2721 3.13796 15.1779 3.04381Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.2504 3.04381C3.34455 2.94967 3.49718 2.94967 3.59133 3.04381L15.1628 14.6152C15.2569 14.7094 15.2569 14.862 15.1628 14.9562C15.0686 15.0503 14.916 15.0503 14.8219 14.9562L3.2504 3.38474C3.15626 3.2906 3.15626 3.13796 3.2504 3.04381Z"
        fill="currentColor"
      />
    </svg>
  );
}
