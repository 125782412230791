import { styled, css } from "styled-components";
import { colors, pxToEm } from "./styleUtils";

export interface ButtonProps {
  $primary?: boolean;
  $secondary?: boolean;
  size?: "sm" | "md" | "xsm";
}

const buttonStyles = css<ButtonProps>`
  align-items: center;
  background-color: ${({ $primary, $secondary }) => {
    if ($primary) {
      return colors["lvl_-5"];
    } else if ($secondary) {
      return colors.lvl_5;
    } else {
      return colors.bcg_accent_2;
    }
  }};
  border-radius: 4px;
  border: ${({ $secondary }) => ($secondary ? `1px solid ${colors["lvl_-5"]}` : "none")};
  color: ${({ $primary, $secondary }) => {
    if ($primary) {
      return colors.lvl_5;
    } else if ($secondary) {
      return colors["lvl_-5"];
    } else {
      return "#17171d";
    }
  }};
  display: flex;
  font-size: ${({ size }) => {
    switch (size) {
      case "md":
        return pxToEm(12);
      case "sm":
      case "xsm":
        return pxToEm(10);
      default:
        return pxToEm(14);
    }
  }};
  font-weight: 500;
  height: ${({ size }) => {
    switch (size) {
      case "md":
        return "40px";
      case "sm":
        return "32px";
      case "xsm":
        return "24px";
      default:
        return "52px";
    }
  }};
  justify-content: center;
  letter-spacing: 0.96px;
  line-height: 1;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition:
    color 0.2s ease-in-out,
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  &:disabled {
    background-color: ${({ $secondary, $primary }) => {
      if ($primary) {
        return colors["lvl_4"];
      } else if (!$primary && !$secondary) {
        return colors["lvl_1"];
      }
    }};
    border-color: ${({ $secondary }) => $secondary && colors["lvl_2"]};
    color: ${({ $secondary, $primary }) => ($primary || $secondary ? colors["lvl_2"] : colors["lvl_4"])};
  }

  &:visited {
    color: ${({ $primary, $secondary }) =>
      $primary ? `${colors.lvl_5}` : $secondary ? `${colors["lvl_-5"]}` : "#17171d"};
  }

  &:hover:not(:disabled),
  &:focus {
    background-color: ${({ $primary, $secondary }) =>
      $primary ? colors["lvl_-2"] : $secondary ? colors.lvl_3 : colors.bcg_accent_2_hover};
    border-color: ${({ $secondary }) => ($secondary ? colors["lvl_-1"] : "none")};
  }
`;

export const Button = styled.button<ButtonProps>`
  ${buttonStyles}
`;

export const LinkButton = styled.a<ButtonProps>`
  ${buttonStyles}
  color: ${({ $primary, $secondary }) =>
    $primary ? `${colors.lvl_5}` : $secondary ? `${colors["lvl_-5"]}` : "#17171d"};
`;

/**Button intended to surround an SVG icon. */
export const IconButton = styled.button`
  align-items: center;
  border-radius: 50%;
  display: flex;
  padding: 2px;
  transition:
    color 0.2s ease-in-out,
    background-color 0.2s ease-in-out,
    border 0.2s ease-in-out;
  &:hover,
  &:focus-visible {
    background: ${colors.lvl_3};
  }
  &:focus-visible {
    outline: 1px solid ${colors["lvl_-2"]};
  }
  &:first-child {
    margin-right: 0.25em;
  }
`;

/**
 * Loading indicator that can be placed inline with button text.
 */
export const CircleLoader = styled.div`
  display: inline-block;
  border: 4px solid rgb(0, 0, 0 / 0.3);
  border-left-color: #17171d;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0 8px;
  animation: spin 0.6s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
