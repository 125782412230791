import * as React from "react";
import { colors } from "../../styles/styleUtils";

export default function QuestionOn() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <circle cx="9" cy="9" r="9" fill={colors["lvl_-5"]} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
        fill={colors["lvl_-5"]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3873 6.21619C7.12688 6.56273 7.0556 6.98869 7.05556 7.28114C7.05554 7.44148 6.93116 7.57145 6.77775 7.57143C6.62433 7.57141 6.49998 7.44141 6.5 7.28107C6.50005 6.90993 6.58745 6.34049 6.95038 5.85754C7.32342 5.36114 7.95441 5 8.9539 5C9.93152 5 10.6428 5.33602 11.0659 5.8646C11.4878 6.39171 11.5928 7.07414 11.4216 7.69347C11.3237 8.04783 11.0947 8.2921 10.8476 8.47633C10.6508 8.62303 10.4193 8.74751 10.2089 8.86058C10.1622 8.8857 10.1165 8.91025 10.0725 8.93436C9.54733 9.22205 9.23168 9.46294 9.23168 9.93548V10.7097C9.23168 10.87 9.10731 11 8.9539 11C8.80048 11 8.67611 10.87 8.67611 10.7097V9.93548C8.67611 9.08084 9.31321 8.69473 9.81461 8.42007C9.86759 8.39105 9.91936 8.36315 9.96986 8.33595C10.1778 8.22392 10.3641 8.12352 10.5249 8.00363C10.7182 7.85956 10.8381 7.71227 10.8879 7.53234C11.012 7.08308 10.9336 6.60423 10.6398 6.23719C10.3471 5.87162 9.8103 5.58065 8.9539 5.58065C8.09464 5.58065 7.63761 5.8831 7.3873 6.21619Z"
        fill={colors["lvl_5"]}
      />
      <path
        d="M9.5 12.4966C9.5 12.7655 9.27551 13 8.9966 13C8.72449 13 8.5 12.7655 8.5 12.4966C8.5 12.2276 8.72449 12 8.9966 12C9.27551 12 9.5 12.2276 9.5 12.4966Z"
        fill={colors["lvl_5"]}
      />
    </svg>
  );
}
