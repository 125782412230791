import BarAccountOff from "../../assets/icons/BarAccountOff";
import { HeaderProps } from "./HeaderTypes";
import MBCGHorizontalLight from "../../assets/icons/game-logos/MBCGHorizontalLight";
import MBCGHorizontalDark from "../../assets/icons/game-logos/MBCGHorizontalDark";
import WBCGHorizontalLight from "../../assets/icons/game-logos/WBCGHorizontalLight";
import WBCGHorizontalDark from "../../assets/icons/game-logos/WBCGHorizontalDark";
import styles from "./InGameNavigation.module.css";

type NavigationProps = Pick<
  HeaderProps,
  "inGameNavItems" | "activeGame" | "mode" | "isIframe" | "handleBlockedRedirect"
> & {
  isWomens: boolean;
};

type NavItemProps = {
  isActivePage: boolean;
  isWomens: boolean;
  ariaLabel: string;
  href: string;
  children: React.ReactNode;
  handleBlockedRedirect?: (href: string) => void;
};

/*
  If there are unsaved changes, NavButtons should pop open modal (via onClick) confirming they want to leave the page.
*/
const NavItem = ({ isActivePage, isWomens, handleBlockedRedirect, ariaLabel, href, children }: NavItemProps) => {
  return handleBlockedRedirect ? (
    <button
      className={`${styles["game-link"]} ${isActivePage ? styles["active-page"] : ""}`}
      onClick={() => {
        handleBlockedRedirect(href);
      }}
    >
      {children}
    </button>
  ) : (
    <a
      className={`${styles["game-link"]} ${isActivePage ? styles["active-page"] : ""}`}
      href={href}
      aria-label={ariaLabel}
    >
      {children}
    </a>
  );
};

export const InGameNavigation = ({
  inGameNavItems,
  isWomens,
  activeGame,
  mode,
  isIframe,
  handleBlockedRedirect,
}: NavigationProps) => {
  /*
    Don't like doing it this way but there is an issue importing images and svg's (must use a react component).
    Also appears that this will be ad-served anyways, so its likely to change.
  */
  function renderGameLogo() {
    switch (activeGame) {
      case "mbcg":
        if (mode === "light") return <MBCGHorizontalLight className={styles["game-logo"]} />;
        else return <MBCGHorizontalDark className={styles["game-logo"]} />;
      case "wbcg":
        if (mode === "light") return <WBCGHorizontalLight className={styles["game-logo"]} />;
        else return <WBCGHorizontalDark className={styles["game-logo"]} />;
      default:
        return <MBCGHorizontalLight className={styles["game-logo"]} />;
    }
  }

  return (
    <div className={styles["navigation-container"]}>
      <div className={styles["navigation-inner-container"]}>
        <div className={styles["game-logo-container"]}>{renderGameLogo()}</div>
        <div className={styles["links-container"]}>
          {inGameNavItems.map((navItem, index) => {
            return (
              <NavItem
                key={`nav-item-${index}`}
                href={`${navItem.page}${isIframe ? "?iframe=" : ""}`}
                ariaLabel={navItem.ariaTitle || navItem.title}
                isActivePage={navItem.isActive || false}
                isWomens={isWomens}
                handleBlockedRedirect={handleBlockedRedirect}
              >
                {navItem.title}
              </NavItem>
            );
          })}
          {handleBlockedRedirect ? (
            <button
              className={styles["account-settings"]}
              aria-label="Account Settings"
              onClick={() => {
                handleBlockedRedirect(`/settings${isIframe ? "?iframe=" : ""}`);
              }}
            >
              <BarAccountOff />
            </button>
          ) : (
            <a
              className={styles["account-settings"]}
              aria-label="Account Settings"
              href={`/settings${isIframe ? "?iframe=" : ""}`}
            >
              <BarAccountOff />
            </a>
          )}
          <div className={styles.divider} />
        </div>
        <div className={styles.divider} />
      </div>
    </div>
  );
};
