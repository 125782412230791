import { ComponentProps, forwardRef, useEffect, useState } from "react";
import NCAALogoDark from "../../assets/icons/NCAALogoDark";
import NCAALogoLight from "../../assets/icons/NCAALogoLight";

import { ImageType, getImageByType } from "../../lib/images";

interface NCAALogoProps extends ComponentProps<"svg"> {
  /* Boolean indicating whether to show the dark version of the NCAA logo */
  isDarkMode?: boolean;
  /* String identifier for the team used to construct the logo path */
  teamLink: string;
  /* Optional string for the image alt text */
  alt?: string;
  /* Optional string for CSS class names */
  className?: string;
}

/** Props for Team logo */
interface ThemedTeamLogoProps extends ComponentProps<"img"> {
  isDarkMode?: boolean;
  showNcaaLogo?: boolean;
  teamLink: string;
  logoType?: ImageType;
}

const NCAALogo = forwardRef<SVGSVGElement, NCAALogoProps>(({ isDarkMode, teamLink, ...props }, ref) => {
  return isDarkMode ? <NCAALogoDark ref={ref} {...props} /> : <NCAALogoLight ref={ref} {...props} />;
});
/**
 * A component for displaying team logos that handles both light and dark modes.
 * NOTE: should only be used in cases where both variants of a logo can toggle,
 * otherwise you're loading twice as many images!
 * Features:
 * - Attempts to load both light and dark versions of team logos
 * - Falls back gracefully in multiple stages:
 *   1. Preferred theme logo (dark/light based on isDarkMode)
 *   2. Alternate theme logo if preferred isn't available
 *   3. NCAA logo if no team logos load or if showNcaaLogo is true
 * - Shows an invisible placeholder while loading to prevent layout shifts
 * - Accepts standard img element props
 *
 * Props:
 * - isDarkMode: Boolean to toggle between dark/light themes
 * - showNcaaLogo: Boolean to force NCAA logo display
 * - teamLink: String identifier for the team
 * - ...standard img element props
 */
export const MMLSafeLogo = forwardRef<HTMLImageElement, ThemedTeamLogoProps>(
  ({ isDarkMode = false, showNcaaLogo = false, logoType = "game", teamLink, className = "", style, ...props }, ref) => {
    const [loadedImages, setLoadedImages] = useState<{
      light?: string;
      dark?: string;
    } | null>(null);

    const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);

    useEffect(() => {
      setHasAttemptedLoad(false);
      setLoadedImages(null);

      const lightImage = new Image();
      const darkImage = new Image();

      let attemptCount = 0;
      const markAttempt = () => {
        attemptCount++;
        if (attemptCount >= 2) {
          setHasAttemptedLoad(true);
        }
      };

      lightImage.src = getImageByType(teamLink, "bgl");
      darkImage.src = getImageByType(teamLink, "bgd");

      lightImage.onload = () => {
        setLoadedImages((prev) => ({
          ...prev,
          light: lightImage.src,
        }));
        markAttempt();
      };

      darkImage.onload = () => {
        setLoadedImages((prev) => ({
          ...prev,
          dark: darkImage.src,
        }));
        markAttempt();
      };

      lightImage.onerror = () => {
        setLoadedImages((prev) => ({
          ...prev,
          light: undefined,
        }));
        markAttempt();
      };

      darkImage.onerror = () => {
        setLoadedImages((prev) => ({
          ...prev,
          dark: undefined,
        }));
        markAttempt();
      };

      return () => {
        lightImage.onload = null;
        darkImage.onload = null;
        lightImage.onerror = null;
        darkImage.onerror = null;
      };
    }, [teamLink]);

    // Show blank placeholder while loading
    if (!hasAttemptedLoad) {
      return (
        <div
          className={className}
          style={{
            width: style?.width || "100%",
            height: style?.height || "100%",
            ...style,
            visibility: "hidden",
          }}
          aria-hidden="true"
        />
      );
    }

    if (showNcaaLogo || teamLink === undefined || (!loadedImages?.light && !loadedImages?.dark)) {
      return <NCAALogo isDarkMode={isDarkMode} teamLink={teamLink} alt={props.alt} className={className} />;
    }

    const preferredImage = isDarkMode ? loadedImages.dark : loadedImages.light;
    if (preferredImage) {
      return (
        <img src={preferredImage} alt={`${teamLink} logo`} ref={ref} className={className} style={style} {...props} />
      );
    }

    const fallbackImage = isDarkMode ? loadedImages.light : loadedImages.dark;
    if (fallbackImage) {
      return (
        <img src={fallbackImage} alt={`${teamLink} logo`} ref={ref} className={className} style={style} {...props} />
      );
    }

    return null;
  },
);

NCAALogo.displayName = "NCAALogo";
MMLSafeLogo.displayName = "MMLSafeLogo";
