import { colors, media } from "../../styles/styleUtils";
import { PropsWithChildren, ReactElement, ReactNode } from "react";
import { styled, keyframes, css } from "styled-components";
import { LibraryWrapper } from "../common/LibraryWrapper";
import { IconButton } from "../../styles/buttons.styled";
import ModalClose from "../../assets/icons/ModalClose";
import * as Dialog from "@radix-ui/react-dialog";

type ModalProps = {
  /**
   * An accessible description to be announced when the dialog is opened.
   * Alternatively, you can provide aria-describedby to Dialog.Content and exclude this component.
   *
   * @see https://www.radix-ui.com/docs/primitives/components/dialog#description
   */
  description?: string | ReactElement;
  handleClose?: () => void;
  isIframe?: boolean;
  modalContent?: ReactElement;
  showCloseIcon?: boolean;
  svpFullScreen?: boolean;
  title?: string;
  isOpen: boolean;
  /** Only set `maxHeight` if the entire container MUST scroll.
   * Otherwise, manage `max-height` and `overflow` in the child element passed. */
  maxHeight?: number;
  maxWidth?: number;
  /** some elements in a modal may need expand the entire width of the modal,
   * if so remove set removeContentPadding to true and handle padding from
   * individual elements in the modal */
  removeContentPadding?: boolean;
  /* Render a custom component over the modal overlay */
  firstOverlayChild?: ReactNode;
};

export const MMLModal = ({
  description,
  handleClose,
  isIframe = false,
  showCloseIcon = true,
  svpFullScreen = false,
  title,
  children,
  isOpen,
  maxHeight,
  maxWidth = 600,
  removeContentPadding = false,
  firstOverlayChild,
}: PropsWithChildren<ModalProps>) => {
  return (
    <Dialog.Root open={isOpen}>
      <StyledOverlay $svpFullScreen={svpFullScreen}>
        {/* If there is a better way to render a component in-between the overlay and the content, do so. */}
        {/* The only way around it (that I know of) is to refactor the modal or hack a solution by injecting it into the DOM. */}
        {firstOverlayChild}
        <StyledContent
          $isIframe={isIframe}
          $svpFullScreen={svpFullScreen}
          $maxWidth={maxWidth}
          $maxHeight={maxHeight}
          $removeContentPadding={removeContentPadding}
        >
          <LibraryWrapper>
            {showCloseIcon && handleClose && (
              <CloseIconContainer>
                <Dialog.Close asChild>
                  <IconButton onClick={handleClose} style={{ borderRadius: "4px", padding: "4px" }}>
                    <ModalClose />
                  </IconButton>
                </Dialog.Close>
              </CloseIconContainer>
            )}
            <div className="modal-content-wrapper">
              {title && <ModalTitle>{title.toUpperCase()}</ModalTitle>}
              {description && <ModalDescription>{description}</ModalDescription>}
              {children}
            </div>
          </LibraryWrapper>
        </StyledContent>
      </StyledOverlay>
    </Dialog.Root>
  );
};

const overlayShow = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const contentShow = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledOverlay = styled(Dialog.Overlay)<{ $svpFullScreen: boolean }>`
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  inset: 0;
  justify-content: center;
  position: fixed;
  z-index: 4;
  ${media.svp} {
    ${({ $svpFullScreen }) =>
      $svpFullScreen &&
      `
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    `}
  }
  ${media.motion} {
    animation: ${overlayShow} 350ms cubic-bezier(0.16, 1, 0.3, 1);
  }
`;

const defaultSvpStyles = css`
  margin: 24px;
  max-height: calc(100vh - 48px);
`;

const fullScreenSvpStyles = css`
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  border-radius: 0px;
  margin: 0;
`;

const StyledContent = styled(Dialog.Content)<{
  $isIframe: boolean;
  $maxHeight: number | undefined;
  $maxWidth: number;
  $svpFullScreen: boolean;
  $removeContentPadding: boolean;
}>`
  background-color: ${colors.lvl_5};
  border-radius: 4px;
  grid-area: 1 / 1;
  top: ${({ $isIframe }) => ($isIframe ? "35%" : "50%")};
  width: clamp(
    240px,
    100%,
    ${({ $maxWidth }) => {
      return $maxWidth || 600;
    }}px
  );
  z-index: 4;

  ${media.motion} {
    animation: ${contentShow} 350ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  ${media.svp} {
    width: unset;
    ${({ $svpFullScreen }) => ($svpFullScreen ? fullScreenSvpStyles : defaultSvpStyles)}
  }

  div.modal-content-wrapper {
    max-height: ${({ $maxHeight }) => ($maxHeight ? `${$maxHeight}px` : `unset`)};
    overflow-y: auto;
    padding: ${({ $removeContentPadding }) => ($removeContentPadding ? "0" : "0 15px 15px")};

    &:first-child {
      padding-top: 32px;
    }

    // 44px is the height of the close icon
    ${media.svp} {
      ${({ $svpFullScreen }) =>
        $svpFullScreen ? `max-height: calc(100vh - 44px);` : `max-height: calc(100vh - 124px);`}
    }
  }
`;

const CloseIconContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 8px;
`;

const ModalTitle = styled(Dialog.Title)`
  font-weight: 900;
  font-size: 16px;
  max-width: 200px;
  letter-spacing: -0.37px;
  line-height: 1.25;
  margin-bottom: 5px;
  text-align: center;
  margin: 0 auto;
`;

const ModalDescription = styled(Dialog.Description)`
  color: ${colors["lvl_-2"]};

  text-align: center;
  margin-bottom: 20px;
`;
