import { SVGProps, forwardRef } from "react";

const WBCGHorizontalDark = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  const { className, ...rest } = props;
  return (
    <svg
      ref={ref}
      width="391"
      height="59"
      viewBox="0 0 391 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <g clipPath="url(#clip0_693_15529)">
        <path
          d="M364.855 12.066L363.663 18.5098H372.328L372.12 19.5278L371.773 21.2978L371.669 21.7837H384.845L385.273 19.5278H374.434L375.232 15.2474H386.095L386.511 12.9915H373.358L372.756 16.2655H366.324L368.267 5.61077H374.711L374.11 8.88468H387.298L387.726 6.6288H376.805L377.603 2.34841H388.536L388.964 0.0925293H376.018L375.995 0.219785L375.567 2.34841L375.371 3.36645H366.474L365.26 9.81017H362.182L361.766 12.066H364.855Z"
          fill="#EC7700"
        />
        <path
          d="M139.591 37.7137L140.782 31.2584H132.117L132.325 30.2404L132.673 28.4819L132.777 27.9961H119.6L119.172 30.2404H130.012L129.213 34.5208H118.362L117.934 36.7766H131.088L131.689 33.5143H138.133L136.178 44.1574H129.746L130.336 40.8951H117.147L116.731 43.1394H127.64L126.853 47.4314H115.91L115.493 49.6757H128.427L128.45 49.56L128.878 47.4314L129.086 46.4133H137.971L139.186 39.958H142.263L142.691 37.7137H139.591Z"
          fill="#EC7700"
        />
        <path
          d="M159.894 30.4139L157.742 28.1001H144.681L147.4 30.9692L143.825 49.3748H153.762L156.805 47.0611L158.089 40.467L156.18 38.4309L158.702 36.5452L159.894 30.4139ZM153.311 41.0454L152.571 44.8746C152.539 45.0377 152.459 45.1871 152.339 45.3027C152.217 45.4064 152.06 45.4601 151.9 45.4531H149.216L150.188 40.5017H152.953C153.023 40.4934 153.094 40.5016 153.16 40.5257C153.226 40.5498 153.286 40.5892 153.334 40.6405C153.365 40.7036 153.381 40.7728 153.381 40.843C153.381 40.9131 153.365 40.9823 153.334 41.0454H153.311ZM154.804 32.635L154.167 35.909C154.144 36.0913 154.063 36.2615 153.936 36.3949C153.803 36.506 153.634 36.5638 153.462 36.5568H150.94L151.807 32.0797H154.387C154.455 32.0749 154.523 32.0848 154.587 32.1087C154.651 32.1327 154.709 32.1702 154.757 32.2186C154.799 32.2783 154.828 32.3468 154.84 32.4189C154.852 32.491 154.847 32.5649 154.827 32.635H154.804Z"
          fill="white"
        />
        <path
          d="M175.326 30.4139L173.174 28.1001H163.202L159.072 49.3748H162.6C162.919 49.3875 163.232 49.2939 163.491 49.1088C163.727 48.9125 163.884 48.6369 163.931 48.3337L165.238 41.6817H166.835L168.084 49.3748H171.948C172.075 49.384 172.203 49.3637 172.322 49.3156C172.44 49.2674 172.546 49.1927 172.63 49.0972C172.713 48.9904 172.77 48.8658 172.796 48.7332C172.822 48.6007 172.817 48.4639 172.781 48.3337L171.462 41.0454L173.591 39.4374L175.326 30.4139ZM170.236 32.635L169.357 37.1931C169.33 37.3545 169.248 37.5017 169.125 37.6095C169.004 37.7165 168.847 37.7742 168.686 37.7715H166.002L167.158 32.0797H169.866C169.936 32.0688 170.008 32.0773 170.074 32.1042C170.14 32.1311 170.198 32.1754 170.24 32.2325C170.283 32.2895 170.31 32.3571 170.317 32.428C170.324 32.4989 170.312 32.5705 170.282 32.635H170.236Z"
          fill="white"
        />
        <path
          d="M187.461 28.1349H181.677L174.423 49.4096H178.183C178.48 49.4249 178.773 49.3392 179.016 49.1666C179.242 48.9635 179.403 48.6978 179.479 48.4031L180.323 45.6035H183.296L182.984 49.4096H187.762L188.618 29.3149C188.631 29.1555 188.611 28.9951 188.56 28.8437C188.508 28.6924 188.425 28.5535 188.317 28.4357C188.079 28.2326 187.774 28.1254 187.461 28.1349ZM183.643 41.8668H181.457L184.476 31.8137L183.643 41.8668Z"
          fill="white"
        />
        <path
          d="M203.472 28.1349H196.531L193.477 30.4486L190.215 47.1653L192.366 49.479H199.308L202.362 47.1653L203.229 42.6651C203.27 42.5362 203.282 42.3998 203.264 42.2658C203.246 42.1318 203.199 42.0034 203.125 41.89C203.031 41.7949 202.917 41.7225 202.79 41.6784C202.664 41.6343 202.529 41.6196 202.396 41.6355H198.787L198.151 44.9094C198.115 45.0735 198.031 45.2229 197.908 45.3374C197.848 45.3904 197.779 45.4309 197.703 45.4568C197.628 45.4826 197.548 45.4931 197.468 45.4878H195.756C195.69 45.4942 195.623 45.485 195.56 45.4609C195.498 45.4368 195.442 45.3985 195.397 45.349C195.363 45.2871 195.345 45.2174 195.345 45.1465C195.345 45.0757 195.363 45.006 195.397 44.9441L197.781 32.6929C197.817 32.535 197.897 32.3906 198.012 32.2764C198.136 32.1667 198.298 32.1088 198.463 32.1145H200.164C200.234 32.1036 200.306 32.112 200.372 32.1389C200.438 32.1658 200.496 32.2101 200.539 32.2672C200.581 32.3242 200.608 32.3918 200.615 32.4627C200.622 32.5336 200.61 32.6052 200.58 32.6698L199.967 35.828H203.495C203.812 35.839 204.123 35.7499 204.386 35.5735C204.62 35.3752 204.776 35.1006 204.826 34.7984L205.659 30.4486L203.472 28.1349Z"
          fill="white"
        />
        <path
          d="M216.58 38.6508L221.739 29.1645C221.82 29.0632 221.872 28.9423 221.89 28.8142C221.908 28.6861 221.892 28.5554 221.843 28.4357C221.789 28.3352 221.706 28.253 221.605 28.1994C221.505 28.1459 221.39 28.1235 221.276 28.1349H217.482L213.097 36.4874H212.16L213.768 28.1349H209.141L205.011 49.4096H208.482C208.796 49.4223 209.106 49.3286 209.361 49.1435C209.482 49.0498 209.583 48.933 209.658 48.8C209.734 48.6669 209.782 48.5202 209.8 48.3684L211.351 40.4439H212.265L213.549 49.4096H217.47C217.593 49.4155 217.716 49.3924 217.829 49.3421C217.941 49.2919 218.04 49.2159 218.118 49.1204C218.264 48.8901 218.314 48.6118 218.257 48.3453L216.58 38.6508Z"
          fill="white"
        />
        <path
          d="M220.698 49.4096H230.172C230.491 49.4222 230.804 49.3286 231.063 49.1435C231.299 48.9472 231.455 48.6716 231.503 48.3684L232.07 45.4531H226.112L227.06 40.5943H230.392C230.686 40.6001 230.974 40.5109 231.213 40.3398C231.446 40.1288 231.621 39.862 231.722 39.5647L232.717 36.6378H227.824L228.703 32.0798H233.33C233.647 32.0967 233.961 32.0071 234.221 31.8252C234.457 31.629 234.614 31.3534 234.661 31.0502L235.205 28.1349H224.793L220.698 49.4096Z"
          fill="white"
        />
        <path
          d="M236.328 32.0797H240.099L236.709 49.4327H240.18C240.495 49.4454 240.804 49.3517 241.059 49.1666C241.18 49.0729 241.281 48.9561 241.357 48.8231C241.432 48.69 241.48 48.5433 241.499 48.3915L244.692 32.1029H247.329C247.646 32.1198 247.96 32.0302 248.22 31.8484C248.456 31.6521 248.612 31.3765 248.66 31.0733L249.204 28.158H237.068L236.328 32.0797Z"
          fill="white"
        />
        <path
          d="M267.782 28.1349H254.93L257.695 31.0502L254.56 47.1537L256.7 49.4674H263.641L266.683 47.1537L267.563 42.6535C267.601 42.5242 267.611 42.3884 267.593 42.2549C267.575 42.1213 267.529 41.993 267.459 41.8784C267.364 41.7833 267.25 41.711 267.124 41.6669C266.997 41.6228 266.863 41.6081 266.73 41.6239H263.109L262.472 44.8978C262.441 45.0608 262.36 45.2102 262.241 45.3258C262.115 45.4314 261.954 45.4851 261.79 45.4762H260.078C260.011 45.4809 259.945 45.4709 259.883 45.4469C259.821 45.4229 259.765 45.3855 259.719 45.3374C259.678 45.2793 259.652 45.2123 259.642 45.1419C259.632 45.0715 259.638 44.9998 259.661 44.9325L262.056 32.6813C262.083 32.5199 262.164 32.3727 262.287 32.2649C262.407 32.1559 262.565 32.0978 262.727 32.1029H264.428C264.496 32.0981 264.564 32.1079 264.628 32.1319C264.692 32.1559 264.75 32.1933 264.798 32.2417C264.829 32.3068 264.845 32.3779 264.845 32.45C264.845 32.522 264.829 32.5931 264.798 32.6582L264.196 35.8164H267.725C268.037 35.8274 268.345 35.7382 268.604 35.5619C268.842 35.3671 268.998 35.0908 269.043 34.7868L269.888 30.437L267.782 28.1349Z"
          fill="white"
        />
        <path
          d="M281.248 28.1349L279.571 36.7998H276.343L278.021 28.1349H273.393L269.263 49.4096H272.734C273.052 49.4222 273.365 49.3286 273.625 49.1435C273.861 48.9472 274.017 48.6716 274.064 48.3684L275.51 40.8951H278.726L277.084 49.4096H280.612C280.927 49.4223 281.236 49.3286 281.491 49.1435C281.612 49.0498 281.713 48.933 281.789 48.8C281.864 48.6669 281.912 48.5202 281.931 48.3684L285.864 28.1349H281.248Z"
          fill="white"
        />
        <path
          d="M297.109 28.1349H291.325L284.06 49.4096H287.831C288.124 49.4231 288.414 49.3376 288.652 49.1666C288.882 48.9645 289.047 48.6989 289.127 48.4031L289.96 45.6035H292.944L292.632 49.4096H297.398L298.254 29.3149C298.268 29.1555 298.248 28.9951 298.196 28.8437C298.144 28.6924 298.062 28.5534 297.953 28.4357C297.718 28.2353 297.418 28.1283 297.109 28.1349ZM293.303 41.8668H291.116L294.124 31.8137L293.303 41.8668Z"
          fill="white"
        />
        <path
          d="M308.562 28.1349H303.935L299.805 49.4096H308.134C308.452 49.4222 308.766 49.3286 309.025 49.1435C309.261 48.9472 309.417 48.6716 309.465 48.3684L309.997 45.4531H305.23L308.562 28.1349Z"
          fill="white"
        />
        <path
          d="M320.512 28.1349H315.884L311.754 49.4096H320.072C320.39 49.4222 320.704 49.3286 320.963 49.1435C321.084 49.0498 321.185 48.933 321.26 48.8C321.336 48.6669 321.384 48.5202 321.402 48.3684L321.946 45.4531H317.168L320.512 28.1349Z"
          fill="white"
        />
        <path
          d="M337.68 31.0502L338.224 28.1349H327.812L323.682 49.4096H333.157C333.475 49.424 333.789 49.3302 334.048 49.1435C334.168 49.0498 334.27 48.933 334.345 48.8C334.42 48.6669 334.469 48.5202 334.487 48.3684L335.054 45.4531H329.108L330.045 40.5943H333.423C333.713 40.6001 333.998 40.5107 334.233 40.3398C334.469 40.132 334.645 39.8642 334.742 39.5647L335.748 36.6378H330.855L331.722 32.0798H336.408C336.721 32.0968 337.031 32.007 337.287 31.8252C337.514 31.6278 337.655 31.3498 337.68 31.0502Z"
          fill="white"
        />
        <path
          d="M347.49 39.6225L345.512 28.1349H341.174L337.044 49.4096H340.26C340.578 49.4222 340.891 49.3286 341.15 49.1435C341.386 48.9472 341.543 48.6716 341.59 48.3684L343.603 37.9566L345.385 48.3684C345.424 48.6579 345.559 48.9263 345.766 49.1319C345.971 49.3189 346.241 49.4186 346.518 49.4096H349.919L354.061 28.1349H349.711L347.49 39.6225Z"
          fill="white"
        />
        <path
          d="M360.134 28.1349L357.08 30.4486L353.829 47.1653L355.97 49.479H363.327L366.37 47.1653L368.29 37.436H362.911C362.62 37.4275 362.335 37.5172 362.101 37.6906C361.861 37.8973 361.681 38.1651 361.58 38.4656L360.655 41.2305H362.899L362.159 44.9094C362.132 45.0741 362.051 45.2249 361.927 45.3374C361.805 45.4412 361.648 45.4949 361.488 45.4878H359.324C359.258 45.4925 359.192 45.4825 359.13 45.4585C359.068 45.4345 359.012 45.3971 358.966 45.349C358.922 45.2922 358.894 45.225 358.884 45.1541C358.874 45.0832 358.882 45.0108 358.908 44.9441L361.291 32.6929C361.327 32.5321 361.411 32.3864 361.534 32.2764C361.593 32.2214 361.662 32.1787 361.737 32.1509C361.813 32.1231 361.893 32.1107 361.974 32.1145H363.975C364.043 32.1096 364.111 32.1195 364.175 32.1435C364.239 32.1674 364.297 32.2049 364.345 32.2533C364.376 32.3184 364.392 32.3895 364.392 32.4615C364.392 32.5336 364.376 32.6047 364.345 32.6698L363.744 35.828H367.272C367.585 35.8389 367.893 35.7498 368.151 35.5735C368.389 35.3787 368.546 35.1024 368.591 34.7984L369.435 30.4486L367.284 28.1349H360.134Z"
          fill="white"
        />
        <path
          d="M373.381 28.1349L369.285 49.4096H378.772C379.086 49.4223 379.396 49.3286 379.651 49.1435C379.772 49.0498 379.873 48.933 379.948 48.8C380.024 48.6669 380.072 48.5202 380.091 48.3684L380.657 45.4531H374.711L375.648 40.5943H378.992C379.282 40.6001 379.566 40.5107 379.801 40.3398C380.038 40.132 380.214 39.8642 380.31 39.5647L381.317 36.6378H376.423L377.291 32.0798H381.976C382.29 32.0968 382.6 32.007 382.855 31.8252C382.976 31.7315 383.077 31.6148 383.153 31.4817C383.228 31.3487 383.277 31.202 383.295 31.0502L383.839 28.1349H373.381Z"
          fill="white"
        />
        <path
          d="M268.476 1.76994L267.261 14.7731L271.322 0.775039H276.204L275.498 14.7731L279.131 0.775039H283.573L277.592 20.0831C277.504 20.3761 277.324 20.6327 277.078 20.8142C276.831 20.9957 276.533 21.0924 276.227 21.0895H271.9L272.606 7.62366L268.973 20.0831C268.85 20.3761 268.645 20.6271 268.382 20.8058C268.119 20.9844 267.81 21.083 267.493 21.0895H263.259L264.103 0.775039H267.574C268.245 0.775039 268.534 1.1568 268.476 1.76994Z"
          fill="#EC7700"
        />
        <path
          d="M294.02 0.775039L296.114 2.96151L293.303 18.9147L290.422 21.0895H283.4L281.318 18.9147L284.129 2.96151L286.998 0.775039H294.02ZM288.236 5.10171L286.176 16.7976C286.119 17.1215 286.234 17.3182 286.581 17.3182H288.641C288.718 17.3261 288.797 17.3182 288.872 17.2949C288.946 17.2715 289.016 17.2333 289.075 17.1825C289.135 17.1316 289.183 17.0693 289.218 16.9991C289.252 16.929 289.273 16.8526 289.277 16.7745L291.348 5.07857C291.405 4.75465 291.348 4.55798 290.931 4.55798H288.872C288.791 4.54492 288.708 4.54894 288.628 4.56978C288.549 4.59062 288.475 4.62781 288.41 4.67899C288.346 4.73018 288.293 4.79422 288.255 4.86701C288.217 4.9398 288.195 5.01974 288.189 5.10171H288.236Z"
          fill="#EC7700"
        />
        <path
          d="M315.85 0.775056L312.46 20.1062C312.417 20.3988 312.263 20.6637 312.031 20.8465C311.799 21.0294 311.505 21.1164 311.211 21.0896H308.272L310.829 6.55937L306.363 20.0831C306.297 20.3881 306.122 20.6585 305.871 20.8438C305.619 21.029 305.309 21.1164 304.998 21.0896H301.25L301.632 6.55937L299.249 20.1062C299.205 20.3988 299.052 20.6637 298.82 20.8465C298.587 21.0294 298.294 21.1164 297.999 21.0896H295.072L298.647 0.775056H304.131C304.79 0.775056 305.079 1.12212 305.079 1.76996L304.732 15.0392L309.464 0.72879L315.85 0.775056Z"
          fill="#EC7700"
        />
        <path
          d="M321.021 8.90784H325.648L324.746 11.6959C324.703 11.9884 324.549 12.2533 324.317 12.4362C324.085 12.6191 323.791 12.706 323.497 12.6792H320.315L319.494 17.3067H325.186L324.688 20.0947C324.645 20.3872 324.492 20.6521 324.259 20.835C324.027 21.0179 323.733 21.1049 323.439 21.078H314.392L317.955 0.763529H327.916L327.453 3.55157C327.413 3.84622 327.26 4.11396 327.028 4.29922C326.795 4.48448 326.5 4.57301 326.204 4.54646H321.727L321.021 8.90784Z"
          fill="#EC7700"
        />
        <path
          d="M334.001 20.1062L332.116 10.1572L330.369 20.1062C330.326 20.3988 330.172 20.6637 329.94 20.8465C329.707 21.0294 329.414 21.1164 329.119 21.0895H326.042L329.617 0.775039H333.77L335.852 11.7537L337.773 0.775039H341.926L338.351 21.0895H335.1C334.826 21.0964 334.56 20.9985 334.356 20.8158C334.152 20.6331 334.025 20.3793 334.001 20.1062Z"
          fill="#EC7700"
        />
        <path
          d="M342.539 7.16093L343.962 0.775039H348.392L345.847 6.21229C345.746 6.52809 345.536 6.79735 345.254 6.97134C344.971 7.14533 344.636 7.21258 344.309 7.16093H342.539Z"
          fill="#EC7700"
        />
        <path
          d="M359.105 11.0827L357.716 18.9147L354.847 21.0895H347.814L345.731 18.9147L346.518 14.4723H349.989C350.66 14.4723 350.914 14.7962 350.798 15.4672L350.567 16.7976C350.509 17.1447 350.625 17.3182 350.972 17.3182H353.031C353.11 17.3261 353.189 17.3183 353.264 17.2951C353.34 17.2719 353.409 17.2338 353.47 17.1831C353.53 17.1324 353.58 17.0701 353.616 16.9999C353.652 16.9298 353.673 16.8531 353.679 16.7745L354.396 12.6792H349.468L347.305 10.5274L348.647 2.96151L351.516 0.775039H358.249L360.342 2.96151L359.81 6.00406C359.767 6.29443 359.615 6.55756 359.386 6.74015C359.156 6.92273 358.865 7.01111 358.573 6.98739H355.206L355.518 5.07857C355.576 4.75465 355.461 4.55798 355.113 4.55798H353.344C353.266 4.55004 353.187 4.55798 353.112 4.5813C353.038 4.60463 352.968 4.64285 352.909 4.69368C352.85 4.74451 352.801 4.80688 352.766 4.87702C352.732 4.94715 352.712 5.02359 352.707 5.10171L352.036 8.90778H356.976L359.105 11.0827Z"
          fill="#EC7700"
        />
        <path
          d="M125.188 20.2104L124.239 14.6574L123.175 20.2104C123.149 20.3741 123.061 20.5217 122.93 20.6228C122.799 20.7239 122.634 20.7709 122.469 20.7541H120.745L122.943 9.42842H125.257L126.31 15.5482L127.467 9.42842H129.78L127.571 20.7541H125.766C125.619 20.7517 125.479 20.6946 125.372 20.5941C125.265 20.4935 125.199 20.3567 125.188 20.2104Z"
          fill="white"
        />
        <path
          d="M135.125 19.5394L133.494 20.7541H129.815L128.658 19.5394L130.393 10.6431L132.013 9.48625H135.692L136.849 10.6431L136.397 12.9568C136.374 13.1226 136.288 13.2731 136.156 13.3766C136.025 13.4801 135.858 13.5287 135.692 13.5121H133.818L134.142 11.8231C134.142 11.6496 134.142 11.5339 133.922 11.5339H133.008C132.921 11.5274 132.834 11.5552 132.767 11.6114C132.7 11.6676 132.658 11.7477 132.649 11.8347L131.377 18.3594C131.377 18.5445 131.377 18.6486 131.596 18.6486H132.51C132.554 18.6534 132.598 18.6493 132.64 18.6366C132.682 18.6239 132.72 18.6029 132.754 18.5748C132.787 18.5467 132.815 18.5121 132.835 18.4731C132.854 18.4341 132.866 18.3915 132.869 18.3478L133.205 16.601H135.136C135.507 16.601 135.645 16.7976 135.576 17.1447L135.125 19.5394Z"
          fill="white"
        />
        <path
          d="M142.807 10.0647L142.356 20.7541H139.81L139.984 18.7296H138.399L137.948 20.2219C137.917 20.3801 137.83 20.5216 137.702 20.6197C137.574 20.7177 137.415 20.7655 137.254 20.7541H135.252L139.116 9.4284H142.17C142.256 9.41929 142.343 9.4295 142.424 9.45825C142.506 9.48701 142.58 9.5336 142.641 9.5946C142.702 9.6556 142.748 9.72949 142.777 9.81082C142.806 9.89216 142.816 9.97889 142.807 10.0647ZM140.146 16.7398L140.586 11.3835L138.977 16.7398H140.146Z"
          fill="white"
        />
        <path
          d="M150.569 10.0647L150.107 20.7541H147.562L147.735 18.7296H146.15L145.699 20.2219C145.671 20.3813 145.584 20.5243 145.455 20.6228C145.327 20.7213 145.166 20.7682 145.005 20.7541H143.004L146.868 9.4284H149.933C150.019 9.41929 150.106 9.4295 150.187 9.45825C150.268 9.48701 150.342 9.5336 150.403 9.5946C150.464 9.6556 150.511 9.72949 150.54 9.81082C150.568 9.89216 150.579 9.97889 150.569 10.0647ZM147.897 16.7398L148.337 11.3835L146.74 16.7398H147.897Z"
          fill="white"
        />
        <path
          d="M151.795 12.5172L151.402 12.066L151.819 9.87958L152.339 9.48625H154.202L154.595 9.93742L154.179 12.1239L153.658 12.5172H151.795ZM154.109 10.0531C154.109 9.98371 154.109 9.93742 154.017 9.93742H152.351C152.336 9.93579 152.321 9.93727 152.307 9.94177C152.293 9.94626 152.28 9.95368 152.27 9.96352C152.259 9.97335 152.25 9.98539 152.244 9.99884C152.238 10.0123 152.235 10.0268 152.235 10.0415L151.865 11.9388C151.865 12.0198 151.865 12.066 151.957 12.066H153.623C153.704 12.066 153.739 12.0661 153.751 11.9504L154.109 10.0531ZM153.08 11.7306L152.941 11.21H152.814L152.744 11.6033C152.739 11.6391 152.72 11.6716 152.693 11.695C152.665 11.7184 152.63 11.731 152.594 11.7306H152.316L152.594 10.2729H153.427L153.646 10.5159L153.554 10.9786L153.334 11.1637L153.484 11.5802C153.519 11.6727 153.473 11.7306 153.404 11.7306H153.08ZM153.08 10.8976C153.161 10.8976 153.184 10.8976 153.195 10.7819V10.7125C153.195 10.6315 153.195 10.6084 153.114 10.6084H152.952L152.883 10.8976H153.08Z"
          fill="white"
        />
        <path
          d="M166.649 9.42842L164.521 20.2104C164.495 20.3741 164.407 20.5217 164.276 20.6228C164.145 20.7239 163.98 20.7709 163.815 20.7541H162.207L163.78 12.6561L161.143 20.1988C161.104 20.3691 161.006 20.5199 160.866 20.6239C160.726 20.728 160.553 20.7782 160.379 20.7657H158.285L158.644 12.6676L157.186 20.2335C157.158 20.3982 157.068 20.5459 156.935 20.6468C156.802 20.7477 156.635 20.7943 156.469 20.7773H154.838L157.036 9.45156H160.101C160.472 9.45156 160.634 9.64822 160.622 10.0069L160.287 17.4339L163.075 9.45156L166.649 9.42842Z"
          fill="white"
        />
        <path
          d="M172.619 10.0647L172.167 20.7541H169.622L169.796 18.7296H168.211L167.76 20.2219C167.729 20.3801 167.641 20.5216 167.514 20.6197C167.386 20.7177 167.226 20.7655 167.066 20.7541H165.064L168.928 9.4284H171.982C172.068 9.41929 172.155 9.4295 172.236 9.45825C172.317 9.48701 172.391 9.5336 172.452 9.5946C172.513 9.6556 172.56 9.72949 172.589 9.81082C172.617 9.89216 172.628 9.97889 172.619 10.0647ZM169.958 16.7398L170.397 11.3835L168.789 16.7398H169.958Z"
          fill="white"
        />
        <path
          d="M180.034 20.2104C180.053 20.2773 180.056 20.3479 180.042 20.416C180.027 20.4841 179.997 20.5477 179.952 20.6012C179.908 20.6548 179.851 20.6967 179.786 20.7233C179.722 20.75 179.652 20.7606 179.583 20.7541H177.524L176.864 16.6588H176.089L175.395 20.2104C175.369 20.3741 175.282 20.5217 175.151 20.6228C175.019 20.7239 174.854 20.7709 174.69 20.7541H172.815L175.013 9.42842H180.323L181.48 10.5853L180.543 15.3863L179.386 16.2539L180.034 20.2104ZM177.871 14.5533C177.914 14.5581 177.958 14.554 178 14.5413C178.042 14.5287 178.081 14.5076 178.114 14.4795C178.148 14.4514 178.175 14.4168 178.195 14.3778C178.215 14.3388 178.227 14.2962 178.23 14.2526L178.692 11.8231C178.692 11.6496 178.692 11.5339 178.472 11.5339H177.038L176.448 14.5533H177.871Z"
          fill="white"
        />
        <path
          d="M187.23 19.5394L185.61 20.7541H181.931L180.774 19.5394L182.51 10.6431L184.141 9.48625H187.808L188.965 10.6431L188.514 12.9568C188.491 13.1226 188.404 13.2731 188.273 13.3766C188.141 13.4801 187.975 13.5287 187.808 13.5121H185.934L186.258 11.8231C186.258 11.6496 186.258 11.5339 186.038 11.5339H185.136C185.092 11.5291 185.047 11.5331 185.005 11.5456C184.962 11.5582 184.922 11.5791 184.888 11.607C184.854 11.635 184.825 11.6695 184.804 11.7086C184.783 11.7477 184.77 11.7905 184.766 11.8347L183.505 18.3594C183.505 18.5445 183.505 18.6486 183.724 18.6486H184.627C184.67 18.6534 184.714 18.6493 184.756 18.6366C184.798 18.6239 184.837 18.6029 184.87 18.5748C184.904 18.5467 184.931 18.5121 184.951 18.4731C184.971 18.4341 184.983 18.3915 184.985 18.3478L185.333 16.601H187.253C187.623 16.601 187.762 16.7976 187.693 17.1447L187.23 19.5394Z"
          fill="white"
        />
        <path
          d="M191.313 16.196L190.538 20.1872C190.513 20.3509 190.425 20.4985 190.294 20.5996C190.163 20.7008 189.998 20.7477 189.833 20.7309H187.959L190.157 9.40526H192.632L191.742 14.0327H193.442L194.333 9.40526H196.809L194.715 20.1872C194.692 20.3522 194.605 20.5015 194.473 20.6032C194.341 20.7048 194.174 20.7507 194.009 20.7309H192.135L193.014 16.196H191.313Z"
          fill="white"
        />
        <path
          d="M210.529 9.42842L208.481 20.2104C208.458 20.3754 208.371 20.5247 208.24 20.6263C208.108 20.728 207.941 20.7739 207.776 20.7541H206.144L207.718 12.6561L205.011 20.1872C204.97 20.3584 204.87 20.5095 204.728 20.6134C204.586 20.7173 204.411 20.7672 204.236 20.7541H202.153L202.512 12.6561L201.043 20.222C201.017 20.3856 200.929 20.5332 200.798 20.6344C200.667 20.7355 200.502 20.7825 200.337 20.7657H198.706L200.904 9.44H203.958C204.328 9.44 204.49 9.63666 204.479 9.99529L204.155 17.4223L206.943 9.44L210.529 9.42842Z"
          fill="white"
        />
        <path
          d="M216.498 10.0647L216.035 20.7541H213.502L213.664 18.7296H212.079L211.628 20.2219C211.6 20.3792 211.514 20.5205 211.388 20.6187C211.262 20.7169 211.105 20.7652 210.945 20.7541H208.932L212.808 9.4284H215.862C215.948 9.41929 216.034 9.4295 216.116 9.45825C216.197 9.48701 216.271 9.5336 216.332 9.5946C216.393 9.6556 216.44 9.72949 216.468 9.81082C216.497 9.89216 216.507 9.97889 216.498 10.0647ZM213.837 16.7398L214.265 11.3835L212.669 16.7398H213.837Z"
          fill="white"
        />
        <path
          d="M225.291 10.6431L223.555 19.5394L221.936 20.7541H216.637L218.835 9.42842H224.145L225.291 10.6431ZM220.952 18.6486C220.996 18.6534 221.04 18.6493 221.082 18.6367C221.124 18.624 221.162 18.6029 221.196 18.5748C221.229 18.5467 221.257 18.5121 221.277 18.4731C221.296 18.4341 221.308 18.3915 221.311 18.3478L222.584 11.8231C222.584 11.6496 222.584 11.5339 222.364 11.5339H220.918L219.529 18.6486H220.952Z"
          fill="white"
        />
        <path
          d="M228.946 20.2104L227.997 14.6574L226.933 20.2104C226.907 20.3741 226.82 20.5217 226.689 20.6228C226.557 20.7239 226.392 20.7709 226.227 20.7541H224.504L226.702 9.42842H229.015L230.068 15.5482L231.225 9.42842H233.539L231.329 20.7541H229.524C229.378 20.7517 229.237 20.6946 229.13 20.5941C229.023 20.4935 228.958 20.3567 228.946 20.2104Z"
          fill="white"
        />
        <path
          d="M236.374 13.9633H238.977L238.445 15.5135C238.412 15.6747 238.323 15.819 238.194 15.9207C238.064 16.0224 237.903 16.0749 237.739 16.0688H235.957L235.46 18.6486H238.63L238.329 20.2104C238.303 20.3741 238.216 20.5217 238.084 20.6228C237.953 20.7239 237.788 20.7709 237.623 20.7541H232.568L234.766 9.42842H240.319L240.03 10.9786C240.006 11.1444 239.92 11.2948 239.789 11.3984C239.657 11.5019 239.49 11.5505 239.324 11.5339H236.837L236.374 13.9633Z"
          fill="white"
        />
        <path
          d="M246.578 15.178L245.721 19.5394L244.09 20.7541H240.18L239.023 19.5394L239.509 17.0637H241.429C241.8 17.0637 241.95 17.2488 241.869 17.619L241.73 18.3594C241.73 18.556 241.73 18.6486 241.95 18.6486H243.107C243.15 18.6534 243.194 18.6493 243.236 18.6366C243.278 18.6239 243.317 18.6029 243.35 18.5748C243.384 18.5467 243.411 18.5121 243.431 18.4731C243.451 18.4341 243.463 18.3915 243.466 18.3478L243.905 16.0341H241.163L240.007 14.8772L240.828 10.6547L242.448 9.49783H246.207L247.364 10.6547L247.04 12.3553C247.014 12.5189 246.927 12.6665 246.796 12.7677C246.664 12.8688 246.499 12.9158 246.335 12.899H244.46L244.657 11.8347C244.657 11.6611 244.657 11.5455 244.437 11.5455H243.454C243.367 11.539 243.281 11.5668 243.214 11.623C243.147 11.6792 243.104 11.7593 243.095 11.8463L242.679 13.9749H245.432L246.578 15.178Z"
          fill="white"
        />
        <path
          d="M254.131 15.178L253.275 19.5394L251.655 20.7541H247.734L246.577 19.5394L247.063 17.0637H248.971C249.342 17.0637 249.48 17.2488 249.411 17.619L249.272 18.3594C249.272 18.556 249.272 18.6486 249.492 18.6486H250.649C250.692 18.6534 250.736 18.6493 250.778 18.6366C250.82 18.6239 250.859 18.6029 250.892 18.5748C250.926 18.5467 250.953 18.5121 250.973 18.4731C250.993 18.4341 251.005 18.3915 251.008 18.3478L251.459 16.0341H248.705L247.548 14.8772L248.37 10.6547L250.001 9.49783H253.749L254.906 10.6547L254.582 12.3553C254.556 12.5189 254.469 12.6665 254.338 12.7677C254.206 12.8688 254.041 12.9158 253.877 12.899H252.002L252.199 11.8347C252.199 11.6611 252.199 11.5455 251.979 11.5455H250.996C250.952 11.5407 250.907 11.5447 250.865 11.5572C250.822 11.5698 250.782 11.5906 250.748 11.6186C250.714 11.6466 250.685 11.6811 250.664 11.7202C250.643 11.7593 250.63 11.8021 250.626 11.8463L250.221 13.9749H252.974L254.131 15.178Z"
          fill="white"
        />
        <path
          d="M256.214 12.5172L255.82 12.066L256.248 9.87958L256.769 9.48625H258.631L259.025 9.93742L258.608 12.1239L258.076 12.5172H256.214ZM258.527 10.0531C258.531 10.0392 258.531 10.0246 258.528 10.0106C258.525 9.99652 258.519 9.98334 258.51 9.97211C258.501 9.96088 258.49 9.9519 258.477 9.94588C258.463 9.93986 258.449 9.93697 258.435 9.93742H256.769C256.754 9.93583 256.738 9.93726 256.724 9.94164C256.709 9.94602 256.695 9.95327 256.684 9.96295C256.672 9.97263 256.662 9.98455 256.655 9.99804C256.648 10.0115 256.643 10.0263 256.642 10.0415L256.271 11.9388C256.271 12.0198 256.271 12.066 256.376 12.066H258.041C258.122 12.066 258.157 12.0661 258.169 11.9504L258.527 10.0531ZM257.498 11.7306L257.359 11.21H257.232L257.151 11.6033C257.146 11.6372 257.129 11.6682 257.104 11.6914C257.078 11.7145 257.046 11.7283 257.012 11.7306H256.723L257.012 10.2729H257.845L258.065 10.5159L257.972 10.9786L257.741 11.1637L257.891 11.5802C257.949 11.6727 257.903 11.7306 257.822 11.7306H257.498ZM257.498 10.8976C257.567 10.8976 257.59 10.8976 257.602 10.7819V10.7125C257.602 10.6315 257.602 10.6084 257.521 10.6084H257.324L257.266 10.8976H257.498Z"
          fill="white"
        />
        <path
          d="M113.515 58.653H379.084L379.674 55.4022H114.116L113.515 58.653ZM124.609 0.902347L123.996 4.22254H259.823L260.425 0.902347H124.609Z"
          fill="#EC7700"
        />
        <path
          d="M71.5665 37.7137C67.1357 40.8488 61.8836 44.1458 56.2381 47.6048L56.0183 47.7321C55.989 47.7658 55.9728 47.8089 55.9728 47.8536C55.9728 47.8982 55.989 47.9414 56.0183 47.9751C56.052 48.0043 56.0951 48.0205 56.1397 48.0205C56.1844 48.0205 56.2275 48.0043 56.2612 47.9751L56.4463 47.8709C61.2357 45.3721 66.7424 42.4105 72.2722 39.368H72.3647C72.0205 38.8578 71.7516 38.3006 71.5665 37.7137ZM100.65 14.1715C93.7088 6.45525 48.7069 13.3964 29.5955 17.5727L29.1559 17.6653C29.1334 17.6679 29.1117 17.6753 29.0922 17.6869C29.0727 17.6985 29.0558 17.7141 29.0427 17.7327C29.0296 17.7512 29.0206 17.7723 29.0162 17.7946C29.0118 17.8168 29.0121 17.8398 29.0171 17.8619C29.0256 17.906 29.0508 17.9451 29.0874 17.971C29.1241 17.9969 29.1694 18.0075 29.2138 18.0007L29.6534 17.9082C45.4908 15.097 78.4267 11.1868 85.5067 18.209C87.67 20.3492 87.161 23.1141 84.6506 26.3764C86.0399 27.2901 87.0254 28.7022 87.4039 30.3213C97.191 23.8082 103.762 17.5958 100.65 14.1715Z"
          fill="#CC2427"
        />
        <path
          d="M74.3897 35.6892C74.4822 38.4078 76.1944 40.6175 78.4271 40.6175C82.7654 40.6175 84.6163 35.2959 84.4891 31.7443C84.3965 29.0257 82.6265 26.8045 80.3938 26.8045C76.6687 26.8045 74.2856 32.1145 74.3897 35.6892ZM72.076 35.5504C72.0264 34.3613 72.2136 33.1741 72.6265 32.0579C73.0394 30.9417 73.6699 29.9185 74.4813 29.0479C75.2928 28.1772 76.2691 27.4764 77.3535 26.986C78.438 26.4957 79.609 26.2256 80.7987 26.1914C81.5891 26.1536 82.3787 26.2811 83.1171 26.5657C83.8555 26.8503 84.5265 27.2857 85.0871 27.8441C85.6477 28.4026 86.0858 29.0719 86.3732 29.8091C86.6607 30.5464 86.7912 31.3355 86.7565 32.1261C86.8494 33.3251 86.6924 34.5303 86.2954 35.6655C85.8984 36.8007 85.27 37.8411 84.4501 38.7208C83.6301 39.6005 82.6365 40.3005 81.532 40.7763C80.4276 41.2521 79.2364 41.4935 78.0338 41.4851C77.2453 41.5195 76.4582 41.3896 75.7226 41.1035C74.9869 40.8175 74.3189 40.3815 73.7607 39.8234C73.2026 39.2653 72.7667 38.5972 72.4806 37.8616C72.1946 37.126 72.0647 36.3389 72.0991 35.5504H72.076Z"
          fill="white"
        />
        <path
          d="M22.2267 34.3241C21.9885 34.4089 21.745 34.4785 21.4979 34.5324L19.8552 34.8216C19.4474 34.8522 19.0519 34.9748 18.6983 35.1802C18.6038 35.2434 18.5232 35.3252 18.4615 35.4207C18.3999 35.5162 18.3584 35.6234 18.3397 35.7355C18.3137 35.8452 18.3232 35.9604 18.3668 36.0643C18.4105 36.1683 18.4861 36.2557 18.5826 36.3139C18.904 36.4898 19.2704 36.5663 19.6354 36.5337C20.028 36.5418 20.4192 36.4831 20.7922 36.3602C21.1173 36.2617 21.4199 36.1006 21.683 35.8859C21.8583 35.7388 21.9903 35.5469 22.0648 35.3306C22.1483 35.0005 22.2025 34.6637 22.2267 34.3241ZM26.8542 31.4782C26.8151 31.9641 26.7456 32.4471 26.646 32.9243L25.8709 37.2741C25.8327 37.4504 25.8554 37.6345 25.9352 37.7962C26.015 37.958 26.1473 38.0879 26.3105 38.1649V38.3384H21.7871V37.2394C21.1208 37.6362 20.4051 37.9435 19.6585 38.1533C18.9634 38.3321 18.2476 38.4177 17.5299 38.4078C16.5415 38.5418 15.5382 38.3115 14.7071 37.76C14.4481 37.5944 14.2363 37.3646 14.0924 37.093C13.9485 36.8214 13.8773 36.5171 13.8858 36.2098C13.9341 35.7355 14.1189 35.2854 14.4179 34.9141C14.7391 34.5593 15.1339 34.279 15.5748 34.0927C16.1415 33.8418 16.7416 33.6742 17.3563 33.5953C18.143 33.468 19.2305 33.3408 20.6997 33.2135C21.1991 33.1799 21.6906 33.0706 22.1573 32.8896C22.4581 32.7739 22.5854 32.6351 22.6201 32.3922C22.7126 31.941 22.6201 31.6865 21.9722 31.5708C19.9097 31.4137 17.8356 31.6133 15.8409 32.1608L16.6738 29.8471C18.6417 29.5432 20.6289 29.3808 22.6201 29.3612C25.8014 29.3959 26.8542 30.3098 26.8542 31.4782Z"
          fill="white"
        />
        <path
          d="M39.6491 38.3384L41.1877 29.6504H45.4334L43.8948 38.3384H39.6491ZM41.4191 27.6374C41.5463 26.9318 42.6801 26.3533 43.9295 26.3533C45.1789 26.3533 46.116 26.9318 46.0003 27.6374C45.8846 28.3431 44.7393 28.9216 43.4783 28.9216C42.2173 28.9216 41.2918 28.3547 41.4191 27.6374Z"
          fill="white"
        />
        <path
          d="M60.5073 34.3473C60.3222 34.4051 60.0793 34.4745 59.7785 34.5439C59.4777 34.6133 58.9224 34.7059 58.1242 34.8331C57.7182 34.873 57.3246 34.995 56.9673 35.1918C56.7768 35.3272 56.6445 35.5297 56.5971 35.7586C56.5777 35.8682 56.5903 35.981 56.6334 36.0836C56.6765 36.1862 56.7482 36.2742 56.8401 36.3371C57.1726 36.5053 57.5441 36.5812 57.9159 36.5569C58.2854 36.5573 58.6525 36.4987 59.0034 36.3833C59.3284 36.2848 59.631 36.1237 59.8942 35.909C60.0663 35.7545 60.1975 35.5597 60.2759 35.3422C60.3787 35.017 60.456 34.6844 60.5073 34.3473ZM65.1348 31.4898C65.0965 31.9758 65.0269 32.4588 64.9265 32.9359L64.1514 37.2973C64.1133 37.4736 64.136 37.6576 64.2158 37.8194C64.2956 37.9811 64.4279 38.1111 64.591 38.188V38.3384H60.0793V37.1816C59.412 37.5764 58.6965 37.8836 57.9507 38.0955C57.2566 38.2802 56.54 38.3658 55.822 38.35C54.8352 38.4852 53.8327 38.2593 52.9993 37.7137C52.4208 37.1931 52.1432 36.8576 52.1779 36.1635C52.2172 35.687 52.4031 35.2344 52.7101 34.8678C53.03 34.5116 53.4252 34.2311 53.8669 34.0465C54.4287 33.7907 55.0251 33.6192 55.6369 33.5375C56.4236 33.4102 57.5226 33.2829 58.9803 33.1557C59.4752 33.1244 59.9626 33.0191 60.4263 32.8433C60.7387 32.7161 60.8544 32.5888 60.9007 32.3343C60.9816 31.8947 60.8428 31.6402 60.2528 31.5129C58.1904 31.3611 56.1171 31.5606 54.1214 32.1029L54.9544 29.7892C56.9214 29.4762 58.909 29.3099 60.9007 29.2918C64.0936 29.4075 65.1116 30.3214 65.0885 31.4898H65.1348Z"
          fill="white"
        />
        <path
          d="M13.2726 35.2149C12.134 35.4733 10.9696 35.6014 9.80203 35.5967C7.77752 35.5967 6.15791 34.5902 6.25046 32.7392C6.25046 31.5129 7.76595 28.9216 11.4679 28.9216C12.6309 28.8985 13.7758 29.2119 14.765 29.8239L15.3087 26.8045C13.9731 26.2802 12.5429 26.0399 11.1093 26.0988C6.26203 26.0988 1.59987 28.3547 1.02144 32.8433C0.443009 37.332 5.99595 38.5467 8.47164 38.5351C9.89458 38.5351 11.3869 38.4657 12.7636 38.3269L13.2726 35.2149Z"
          fill="white"
        />
        <path
          d="M47.7703 29.6041L48.0826 27.8341L52.5134 26.8624L52.0507 29.6041H54.2256L53.8091 31.351H51.692L50.4195 38.2922C50.4195 38.2922 46.1391 38.2922 46.1275 38.2922L47.4001 31.351H45.792L46.116 29.5925L47.7703 29.6041Z"
          fill="white"
        />
        <path d="M69.392 38.3269L65.0769 38.3384L67.2518 26.2145L71.4628 26.3418L69.392 38.3269Z" fill="white" />
        <path
          d="M34.0383 31.6865C33.4906 31.683 32.9574 31.8621 32.5228 32.1955C32.0618 32.5609 31.7627 33.0926 31.6899 33.6763C31.6032 33.9506 31.58 34.241 31.6221 34.5256C31.6642 34.8102 31.7704 35.0815 31.9328 35.319C32.1016 35.4917 32.306 35.6257 32.5317 35.7116C32.7574 35.7975 32.9991 35.8333 33.2401 35.8165C33.6131 35.8234 33.9834 35.7525 34.3275 35.6082C34.6605 35.4468 34.9441 35.1992 35.1489 34.891C35.394 34.547 35.5563 34.1511 35.6232 33.7341C35.6964 33.4719 35.7073 33.1963 35.6551 32.9292C35.6029 32.662 35.489 32.4108 35.3224 32.1955C35.1582 32.0225 34.9582 31.8873 34.7365 31.7994C34.5147 31.7115 34.2765 31.673 34.0383 31.6865ZM26.2989 41.3347L28.3697 29.6967H32.0948L31.8056 31.2353C32.218 30.7354 32.7575 30.3558 33.3673 30.1363C34.1275 29.8079 34.9456 29.6349 35.7736 29.6273C36.5694 29.5582 37.3689 29.7061 38.0873 30.0553C38.7748 30.3977 39.3107 30.9831 39.5912 31.698C39.8709 32.4563 39.935 33.2772 39.7763 34.0696C39.6686 34.7463 39.4269 35.3947 39.0654 35.9767C38.7038 36.5588 38.2297 37.0628 37.6708 37.4592C36.6267 38.2281 35.3435 38.6011 34.0499 38.5119C33.61 38.5205 33.1709 38.4739 32.7426 38.3731C32.4447 38.3082 32.1594 38.1949 31.8981 38.0376C31.6359 37.8576 31.3986 37.6436 31.1924 37.4014L30.4983 41.3925L26.2989 41.3347Z"
          fill="white"
        />
        <path
          d="M103.381 32.7508C103.397 32.5948 103.378 32.4372 103.326 32.2894C103.274 32.1416 103.189 32.0074 103.078 31.8965C102.967 31.7856 102.833 31.7009 102.685 31.6486C102.537 31.5962 102.38 31.5776 102.224 31.5939C100.477 31.5939 98.6259 35.0067 98.3136 36.3833C101.229 36.3718 103.45 34.7059 103.381 32.7508ZM103.751 38.0955L104.086 38.3269C103.681 39.2258 103.034 39.9942 102.216 40.5459C101.399 41.0975 100.444 41.4108 99.4589 41.4504C99.0416 41.4622 98.6264 41.3873 98.2395 41.2306C97.8526 41.0738 97.5024 40.8385 97.2111 40.5395C96.9197 40.2406 96.6936 39.8845 96.5468 39.4936C96.4001 39.1028 96.3359 38.6859 96.3585 38.269C96.2197 34.5439 99.7134 31.0849 102.571 31.0849C103.797 31.0849 104.885 31.6171 104.977 32.9359C105.081 35.8974 100.928 36.8114 98.1863 36.8692C98.0977 37.2245 98.0626 37.591 98.0822 37.9567C98.0637 38.2653 98.1108 38.5745 98.2205 38.8636C98.3302 39.1527 98.5 39.4153 98.7187 39.6339C98.9373 39.8526 99.1999 40.0224 99.489 40.1321C99.7782 40.2418 100.087 40.2889 100.396 40.2704C101.087 40.198 101.751 39.9632 102.334 39.5852C102.917 39.2073 103.403 38.6968 103.751 38.0955Z"
          fill="white"
        />
        <path
          d="M86.7218 32.6582C87.0436 32.6082 87.3681 32.5773 87.6936 32.5657C88.006 32.5657 88.3067 32.5657 88.3183 32.9359C88.3299 33.3061 87.9944 34.4398 87.925 34.7406L87.254 37.6674C86.9879 38.8243 86.6987 40.1084 86.4442 41.138H88.1679L89.1281 36.5106C92.0434 33.468 93.1656 32.4731 93.8597 32.4731C93.9314 32.4659 94.0037 32.4747 94.0716 32.4989C94.1394 32.5231 94.201 32.5621 94.252 32.613C94.3029 32.6639 94.3419 32.7255 94.3661 32.7934C94.3903 32.8612 94.3991 32.9336 94.3918 33.0053C94.3301 33.7212 94.1865 34.4276 93.9638 35.1108L92.992 38.4772C92.7708 39.0986 92.646 39.7501 92.6218 40.4092C92.6118 40.5602 92.6355 40.7115 92.6913 40.8522C92.7471 40.9929 92.8335 41.1193 92.9443 41.2224C93.0551 41.3254 93.1875 41.4025 93.3319 41.4479C93.4762 41.4934 93.6288 41.5061 93.7787 41.4851C95.0281 41.4851 95.9305 40.1894 96.7171 38.9169L96.4858 38.4888C96.1618 39.0094 95.433 40.12 94.843 40.12C94.6579 40.12 94.4844 40.0043 94.4844 39.692C94.5057 39.2226 94.5954 38.7588 94.7505 38.3153L95.8263 34.3704C96.0555 33.6691 96.1992 32.9427 96.2544 32.2071C96.2663 32.0551 96.2439 31.9025 96.1889 31.7604C96.1339 31.6183 96.0477 31.4903 95.9367 31.3859C95.8257 31.2816 95.6926 31.2035 95.5474 31.1574C95.4022 31.1113 95.2484 31.0984 95.0975 31.1196C93.9407 31.1196 92.4946 31.941 89.3132 35.562L89.7065 33.792C89.9263 32.8202 90.1346 31.8137 90.3428 31.1196C89.1635 31.5385 87.9578 31.8786 86.7334 32.1376L86.7218 32.6582Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_693_15529">
          <rect width="389.863" height="59" fill="white" transform="translate(0.246094)" />
        </clipPath>
      </defs>
    </svg>
  );
});

WBCGHorizontalDark.displayName = "WBCGHorizontalDark";

export default WBCGHorizontalDark;
