import { createGlobalStyle } from "styled-components";
import { breakpoints, colors, FONTS } from "./styleUtils";

export const GlobalStyles = createGlobalStyle`  
  // CSS Reset ↓ | https://www.joshwcomeau.com/css/custom-css-reset/
  *, *::before, *::after {
    box-sizing: border-box;
  }
  * {
    margin: 0;
  }
  html, body {
    height: 100%;
  }
  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }  
  #root, #__next {
    isolation: isolate;
    height: 100%;
  }

  // Custom Global Styles ↓

  body {
    line-height: 1.5;
    font-size: 1.6rem;
    -webkit-tap-highlight-color: rgb(0,0,0 / 0);
    /* make sure ads and headers never cause x scroll */
    overflow-x: hidden;
    max-width: 100%;
    will-change: scroll-position;
    background: ${colors.lvl_5};
    color: ${colors["lvl_-5"]};
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
  }

  h2, h3, h4, h5 {
    letter-spacing: -0.5px;
  }

  h1 {
    @media(min-width: ${breakpoints.mvp}) {
      font-size: 16.0rem;
      letter-spacing: -2px;
    }
    @media(max-width: ${breakpoints.mvp}) {
      font-size: 10.2rem;
      letter-spacing: -1.6px;
    }
    @media(max-width: ${breakpoints.svp}) {
      font-size: 8.0rem;
      letter-spacing: -1.2px;
    }
  }

  h2 {
    letter-spacing: -0.5px;

    @media(min-width: ${breakpoints.mvp}) {
      font-size: 7.2rem;
    }
    @media(max-width: ${breakpoints.mvp}) {
      font-size: 6.0rem;
    }
    @media(max-width: ${breakpoints.svp}) {
      font-size: 5.6rem;
    }
  }

  h3 {
    font-size: 2.8rem;
  }

  h4 {
    @media(min-width: ${breakpoints.mvp}) {
      font-size: 4.2rem;
    }
    @media(max-width: ${breakpoints.mvp}) {
      font-size: 3.2rem;
    }
  }

  h5 {
    letter-spacing: -0.5px;
    @media(min-width: ${breakpoints.mvp}) {
      font-size: 6.0rem;
    }
    @media(max-width: ${breakpoints.mvp}) {
      font-size: 3.2rem;
    }
    @media(max-width: ${breakpoints.svp}) {
      font-size: 2.2rem;
    }
  }

  h6 {
    @media(min-width: ${breakpoints.mvp}) {
      font-size: 6.0rem;
    }
    @media(max-width: ${breakpoints.mvp}) {
      font-size: 3.2rem;
    }
    @media(max-width: ${breakpoints.svp}) {
      font-size: 1.3rem;
    }
  }

  a {
    color: inherit;
  }

  * {
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
  }
  p, h1, h2, h3, h4, h5, code, .u-selectable {
    user-select: text;
    -webkit-user-select: text;
    -webkit-touch-callout: default;
    white-space: normal;
  }
  input,
  textarea,
  select {
    user-select: text;
    -webkit-touch-callout: default;
  }
  body p {
    margin-bottom: 0;
    padding: 0;
    line-height: 1.5em;
    font-size: inherit;
    text-rendering: optimizeLegibility;
  }
  
  button, body a {
    cursor: pointer;
    text-transform: none;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    border-radius: 0;
    background: transparent;
    line-height: 1;
    /* font: inherit; */
    outline: none;
  }
  button:active, a:active {
    outline: none;
  }
  pre {
    padding: 1rem;
    background: ${colors["lvl_-4"]};
    color: ${colors.lvl_5};
    border-radius: 4px;
    ::selection {
      background: ${colors["lvl_-3"]};
    }
  }
  *:disabled {
    cursor: not-allowed;
  }

  ::selection {
    background: ${colors.lvl_3};
  }
  table {
    border-collapse: collapse;
  }
`;
