import PlayLogo from "../../assets/icons/PlayLogo";
import { HeaderProps } from "./HeaderTypes";
import { gameNavItems } from "./HeaderConsts";
import styles from "./DesktopGameNaviation.module.css";

type NavigationProps = Pick<HeaderProps, "activeGame" | "isIframe" | "handleBlockedRedirect">;

export const DesktopGameNavigation = ({ activeGame, handleBlockedRedirect, isIframe }: NavigationProps) => {
  return (
    <div className={`header-grid ${styles["navigation-container"]}`}>
      <nav className={styles["navigation"]}>
        {gameNavItems.map((item) =>
          handleBlockedRedirect ? (
            <button
              className={
                styles["nav-item"] + " " + item.abbreviation === "play"
                  ? "play-logo-container"
                  : `${item.abbreviation === activeGame ? "active" : ""} game-link`
              }
              key={`${item.abbreviation}-button`}
              onClick={() => {
                handleBlockedRedirect(item.href);
              }}
              aria-label={item.title}
            >
              {item.title === "play" ? (
                <div className="play-logo">
                  <PlayLogo />
                </div>
              ) : (
                <span className={styles["inner-link"]}>{item.title}</span>
              )}
            </button>
          ) : (
            <a
              key={`${item.abbreviation}-link`}
              href={`${item.href}${isIframe ? "?iframe=" : ""}`}
              className={`${styles["nav-item"]} ${
                item.abbreviation === "play"
                  ? "play-logo-container"
                  : `${item.abbreviation === activeGame ? styles["active"] : ""} ${styles["game-link"]}`
              }`}
              aria-label={item.title}
            >
              {item.title === "play" ? (
                <div className={styles["play-logo"]}>
                  <PlayLogo />
                </div>
              ) : (
                <span className={styles["inner-link"]}>{item.title}</span>
              )}
            </a>
          ),
        )}
      </nav>
    </div>
  );
};
