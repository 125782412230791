import { styled } from "styled-components";
import { colors } from "../../styles/styleUtils";
import { Team, BcgGameMatchup } from "../../types/apiTypes.types";
import { MMLCard } from "../../styles/MMLCard.styled";
import { MMLTeamChip } from "../TeamChip";
import { getBroadcasterImage } from "../../lib/images";
import { MMLSectionTitle } from "../../components/../styles/MMLSectionTitle";
import { breakpoints } from "../../styles/styleUtils";
import { ScheduleCompletedGame } from "../../types/apiTypes.types";
import { MMLTabs } from "../Tabs";

interface ScheduleProps {
  team1: TeamWithMatchupHistory;
  team2: TeamWithMatchupHistory;
  darkMode?: boolean;
}

interface BcgGameMatchupWithOpponent extends BcgGameMatchup {
  opponent?: Team;
}

interface TeamWithMatchupHistory extends Team {
  seasons: PreviousGames[];
  nextMatchup: BcgGameMatchupWithOpponent | undefined;
  darkMode?: boolean;
}

interface PreviousGames {
  tournamentName: string;
  matchups: ScheduleCompletedGame[];
}

const MMLTeamScheduleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;

  @media (max-width: ${breakpoints.mvp}) {
    padding: 24px 16px 16px;
  }

  @media (max-width: ${breakpoints.svp}) {
    padding: 24px 0 0 0;
  }
`;

const MobileContainer = styled.div`
  display: none;

  @media (max-width: ${breakpoints.mvp}) {
    display: block;
  }
`;

const DesktopContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  height: 600px;
  width: 100%;
  margin-right: 10px;

  // 42px is the total padding needed, but 16px padding is already present in the parent element
  // Necessary for proper scrollbar padding.
  padding-top: calc(42px - 16px);

  // Custom Scrollbar
  // Not possible to style as needed for Firefox. Only Chrome and safari work
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors["lvl_-5"]};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors["lvl_-5"]};
  }
  &::-webkit-scrollbar-thumb:active {
    background: ${colors["lvl_-5"]};
  }
  &::-webkit-scrollbar-track {
    background: ${colors.lvl_3};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track:hover {
    background: ${colors.lvl_3};
  }
  &::-webkit-scrollbar-track:active {
    background: ${colors.lvl_3};
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  @media (max-width: ${breakpoints.mvp}) {
    display: none;
  }
`;

const DesktopDivider = styled.div`
  border-left: 0.5px solid ${colors["lvl_-3"]};
  position: absolute;
  height: 90%;
  width: 0.5px;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: ${breakpoints.mvp}) {
    display: none;
  }
`;

const TeamScheduleRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 24px;
  padding: 0px 42px;

  // scrollbar takes up width. Need to negate that
  transform: translateX(12px);

  @media (max-width: ${breakpoints.mvp}) {
    margin-top: 24px;
    padding: 0px;
    transform: translateX(0px);
  }

  @media (max-width: ${breakpoints.svp}) {
    margin: 24px 0;
  }
`;

const StyledTitle = styled(MMLSectionTitle)`
  margin-bottom: 10px;
`;

const SectionContainer = styled.div`
  display: grid;
  grid-row-gap: 18px;
`;

// Outermost Team Schedule Component
export const MMLTeamSchedule = ({ team1, team2, darkMode = false }: ScheduleProps) => {
  return (
    <MMLTeamScheduleContainer>
      {/* Mobile View Only */}
      <MobileContainer>
        <MMLTabs
          tabs={[
            {
              id: "team1",
              triggerContent: team1.abbrev,
              viewContent: <TeamScheduleRow {...team1} darkMode={darkMode} />,
            },
            {
              id: "team2",
              triggerContent: team2.abbrev,
              viewContent: <TeamScheduleRow {...team2} darkMode={darkMode} />,
            },
          ]}
        />
      </MobileContainer>

      {/* Desktop View Only */}
      <DesktopContainer>
        <TeamScheduleRow {...team1} darkMode={darkMode} />
        <DesktopDivider />
        <TeamScheduleRow {...team2} darkMode={darkMode} />
      </DesktopContainer>
    </MMLTeamScheduleContainer>
  );
};

const TeamScheduleRow = ({ darkMode, ...teamSchedule }: TeamWithMatchupHistory) => {
  return (
    <TeamScheduleRowContainer>
      <div>
        <StyledTitle>Next Game</StyledTitle>
        <SectionContainer>
          <TeamScheduleNextGame {...teamSchedule.nextMatchup} darkMode={darkMode} />
        </SectionContainer>
      </div>
      <div>
        <StyledTitle>Previous Games</StyledTitle>
        <SectionContainer>
          {...teamSchedule?.seasons.map((season) => (
            <TeamSchedulePreviousGames tournamentName={season.tournamentName} matchups={season.matchups} />
          ))}
        </SectionContainer>
      </div>
    </TeamScheduleRowContainer>
  );
};

// Common schedule card styled components
const TeamScheduleCardBanner = styled.div`
  position: relative;
  border-radius: 7px 7px 0px 0px;
  background-color: ${colors.lvl_4};
  text-align: center;
  font-size: 9px;
  text-transform: uppercase;
  height: 16px;
  padding-top: 1px;
`;

const TeamScheduleBannerTail = styled.div`
  position: absolute;
  background: ${colors.lvl_4};
  height: 10px;
  width: 100%;
`;

const TeamScheduleCards = styled.div`
  width: 343px;
  position: relative;
`;

const StyledMMLCard = styled(MMLCard)<{ $index?: number; $listItemCount?: number }>`
  padding: 0;
  position: relative;
  z-index: 2;

  border-radius: ${({ $index, $listItemCount }) => $index !== $listItemCount && $index !== 0 && "0px"};
  border-radius: ${({ $index, $listItemCount }) => $listItemCount && $index === 0 && "7px 7px 0px 0px"};
  border-radius: ${({ $index, $listItemCount }) => $listItemCount && $index === $listItemCount && " 0px 0px 7px 7px"};
  border-bottom: ${({ $index, $listItemCount }) => $index !== $listItemCount && "0px"};
  border-radius: ${({ $listItemCount }) => $listItemCount === 0 && "7px"};
`;

// "Next Game" styled components
const NextGameTeamChipWrapper = styled.div`
  height: 42px;
  width: 42px;
  margin: 0 8px;
`;

const VersusTeamSection = styled.div`
  padding: 9px 14px;
  display: flex;
  align-items: center;
`;

const TBATeamName = styled.div`
  background-color: ${colors.lvl_3};
  width: 241px;
  height: 21px;
  border-radius: 2px;
`;

const Divider = styled.div`
  height: 0.5px;
  background-color: ${colors.lvl_2};
`;

const BroadcastContainer = styled.div`
  padding: 8px 16px 8px 14px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NextGameTeamName = styled.h4`
  font-size: 22px;
`;

// "Next Game" List Item
export const TeamScheduleNextGame = ({ darkMode = false, ...nextMatchup }) => {
  const broadcaster = nextMatchup?.broadcaster_classname;

  function convertDate(timestamp?: number) {
    if (!timestamp) return false;

    const date = new window.Date(timestamp);
    const monthAndDay = date
      .toLocaleDateString("en-US", {
        timeZone: "America/New_York",
        month: "long",
        day: "numeric",
      })
      .toLocaleUpperCase();

    const hours = date.getUTCHours() % 12 || 12;
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    return `${monthAndDay} AT ${hours}:${minutes} ${ampm} EST`;
  }

  const dateString = convertDate(nextMatchup?.time);

  return (
    <TeamScheduleCards>
      <TeamScheduleCardBanner>
        {/* This "Next Game" component will always be "NCAA Tournament" in this case */}
        <p>NCAA Tournament</p>
        <TeamScheduleBannerTail />
      </TeamScheduleCardBanner>
      <StyledMMLCard>
        <VersusTeamSection>
          <p>vs.</p>
          <NextGameTeamChipWrapper>
            <MMLTeamChip team={nextMatchup.opponent} />
          </NextGameTeamChipWrapper>
          {nextMatchup.opponent ? <NextGameTeamName>{nextMatchup.opponent.school}</NextGameTeamName> : <TBATeamName />}
        </VersusTeamSection>
        <Divider />
        <BroadcastContainer>
          {dateString ? <p>{dateString}</p> : <p>TBA</p>}
          {broadcaster ? (
            <img
              alt={`${broadcaster} Logo`}
              src={getBroadcasterImage(broadcaster, darkMode ? "bgd" : "bgl")}
              height={24}
            />
          ) : null}
        </BroadcastContainer>
      </StyledMMLCard>
    </TeamScheduleCards>
  );
};

// "Previous Game" styled components
const PreviousGameTeamChipWrapper = styled.div`
  height: 28px;
  width: 28px;
  margin: 0 8px;
`;

const NextGameContainer = styled.div`
  padding: 7px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PreviousGameStandardCopy = styled.p`
  font-size: 12px;
  letter-spacing: 0.4px;
`;

const Date = styled(PreviousGameStandardCopy)`
  width: 36px;
  text-align: left;
`;

const SchoolInfo = styled.div`
  display: flex;
  align-items: center;

  // 172px is the total width of the vs. copy, team logo, and team name together
  width: 172px;
`;

const WinLoss = styled(PreviousGameStandardCopy)<{ $scoreDiff: number }>`
  color: ${({ $scoreDiff }) => ($scoreDiff < 0 ? colors.live_error : "#009345")};
  width: 22px;
  text-align: center;
`;

const Score = styled(PreviousGameStandardCopy)`
  width: 45px;
  text-align: right;
`;

// "Previous Game" List Item
export const TeamSchedulePreviousGames = ({ tournamentName, matchups }: PreviousGames) => {
  return (
    <TeamScheduleCards>
      <TeamScheduleCardBanner>
        <p>{tournamentName}</p>
        <TeamScheduleBannerTail />
      </TeamScheduleCardBanner>

      {matchups.map((game, index) => {
        const dateString = new window.Date(game?.date);
        const day = dateString.getDate() + 1;
        const month = dateString.getMonth() + 1;

        const opponent = {
          abbrev: game.opponent_abbrev,
          color: game.opponent_color,
          team_id: game.opponent_id,
          link: game.opponent_link,

          // These are here just satisfy the type requirements.
          // Don't want to fetch for a team if its not needed.
          nick: game.opponent_abbrev,
          school: "",
          is_eliminated: false,
          seed: 0,
        };

        return (
          <StyledMMLCard $index={index} $listItemCount={matchups.length - 1} key={`opponent-${game.opponent_id}`}>
            <NextGameContainer>
              <Date>
                {month}/{day}
              </Date>
              <SchoolInfo>
                <PreviousGameStandardCopy>vs.</PreviousGameStandardCopy>
                <PreviousGameTeamChipWrapper>
                  <MMLTeamChip team={opponent} />
                </PreviousGameTeamChipWrapper>
                <PreviousGameStandardCopy>{opponent.abbrev}</PreviousGameStandardCopy>
              </SchoolInfo>
              <WinLoss $scoreDiff={game.score_diff}>{game.score_diff < 0 ? "L" : "W"}</WinLoss>
              <Score>{game.score}</Score>
            </NextGameContainer>
          </StyledMMLCard>
        );
      })}
    </TeamScheduleCards>
  );
};
