import DontBetOnItLogo from "../../assets/icons/DontBetOnIt";
import CapitalOne from "../../assets/icons/CapitalOne";
import styles from "./UtilityBar.module.css";

function UtilityBarComponent() {
  return (
    <div className={`header-grid ${styles.container}`}>
      <div className={styles.content}>
        <div className={styles["ncaa-content-wrapper"]}>
          {/* Adding `className` here allows parent app to target this element and replace its font */}
          <div className="header-ncaa-text">
            <a
              href="https://www.ncaa.com/?cid=bcg_nav"
              target="_blank"
              rel="noreferrer noopener"
              className={styles["utility-bar-link"]}
            >
              VISIT NCAA.com
            </a>
            {" | "}
            <a
              href="https://ncaa.com/march-madness-live/watch?"
              target="_blank"
              rel="noreferrer noopener"
              className={styles["utility-bar-link"]}
            >
              Watch Games
            </a>
          </div>
          <div>
            <DontBetOnItLogo />
          </div>
        </div>
        <div className={styles["presented-by-container"]}>
          <p className={styles["presented-by-text"]}>Presented By</p>
          <div className={styles.logo}>
            <CapitalOne />
          </div>
          <p className={styles["presented-by-text"]}>
            Capital One is the Official Bank
            <br />
            and Credit Card of the NCAA®
          </p>
        </div>
      </div>
    </div>
  );
}

export default UtilityBarComponent;
