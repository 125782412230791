import * as React from "react";

export default function DontBetOnIt() {
  return (
    <svg width="123" height="24" viewBox="0 0 123 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9210_15785)">
        <path
          d="M23.4372 11.8879H20.3232L19.9532 13.1891H20.4337L18.896 15.6325L18.6797 11.8879H15.5273L15.1669 13.1843H15.6474L13.5858 16.312H11.9039C11.6566 16.2875 11.4284 16.168 11.2671 15.9784C11.1058 15.7888 11.0241 15.5439 11.0389 15.2952C11.0389 14.4421 11.8511 13.2036 12.6728 13.2036H13.0188L12.841 13.8542H14.3499L14.893 11.8735H12.5671C11.9143 11.8852 11.28 12.0932 10.7463 12.4703C10.2125 12.8475 9.80391 13.3766 9.57323 13.9891L10.1787 11.8879H8.08352L7.7183 13.1891H8.25652L7.7183 15.1168L6.35834 11.9168H4.43613L4.07091 13.2132H4.5851L3.38372 17.6084H4.9359L5.68556 14.8662C5.90662 15.3964 6.81486 17.6036 6.81486 17.6036H8.54005L9.33296 14.8132C9.2608 15.2375 9.2608 15.6709 9.33296 16.0952C9.43824 16.5429 9.6971 16.9393 10.0643 17.215C10.4315 17.4908 10.8836 17.6283 11.3417 17.6036H14.4797L15.0179 16.7939H17.2332L17.2765 17.5988H19.2371L19.7177 16.7939H21.9955L22.0868 17.6084H23.4756L23.7784 16.9867L23.4372 11.8879Z"
          fill="white"
        />
        <path
          d="M24.3358 18.5433V18.2108H24.5569C24.6674 18.2108 24.7875 18.2349 24.7875 18.3699C24.7875 18.5048 24.6674 18.5433 24.528 18.5433H24.3358ZM24.3358 18.6831H24.5232L24.8019 19.165H24.9846L24.6818 18.6831C24.7195 18.6832 24.7567 18.6755 24.7913 18.6605C24.8258 18.6455 24.8569 18.6235 24.8826 18.596C24.9083 18.5684 24.9281 18.5358 24.9407 18.5002C24.9532 18.4646 24.9584 18.4268 24.9557 18.3891C24.9557 18.1771 24.8308 18.0855 24.5809 18.0855H24.1724V19.1554H24.3358V18.6735V18.6831ZM24.5328 19.5361C24.7147 19.5295 24.8906 19.4694 25.0386 19.3632C25.1867 19.2571 25.3003 19.1096 25.3654 18.9392C25.4304 18.7687 25.4441 18.5829 25.4046 18.4047C25.3651 18.2266 25.2742 18.064 25.1432 17.9373C25.0123 17.8106 24.8471 17.7253 24.6681 17.692C24.4892 17.6588 24.3045 17.6791 24.1369 17.7504C23.9694 17.8217 23.8265 17.9408 23.726 18.0929C23.6255 18.2451 23.5719 18.4235 23.5717 18.606C23.571 18.7312 23.5956 18.8553 23.644 18.9707C23.6925 19.0861 23.7638 19.1905 23.8537 19.2775C23.9435 19.3644 24.0501 19.4322 24.1668 19.4766C24.2835 19.5211 24.4081 19.5413 24.5328 19.5361ZM23.7784 18.606C23.7831 18.4586 23.8309 18.3159 23.9159 18.1956C24.0009 18.0753 24.1192 17.9826 24.2563 17.9293C24.3934 17.8759 24.543 17.8641 24.6867 17.8954C24.8304 17.9267 24.9617 17.9997 25.0644 18.1052C25.167 18.2108 25.2364 18.3443 25.264 18.4891C25.2916 18.634 25.2761 18.7837 25.2194 18.9198C25.1628 19.0558 25.0675 19.1722 24.9454 19.2544C24.8233 19.3365 24.6799 19.3809 24.5328 19.3819C24.4317 19.3833 24.3314 19.364 24.238 19.3253C24.1446 19.2865 24.06 19.2291 23.9894 19.1565C23.9188 19.0839 23.8637 18.9977 23.8274 18.903C23.7911 18.8084 23.7744 18.7073 23.7784 18.606Z"
          fill="#00A3DE"
        />
        <path
          d="M20.7269 15.3819L21.9282 13.5602L21.9955 15.3819H20.7269ZM15.9213 15.3819L17.1227 13.5506L17.1852 15.3819H15.9213Z"
          fill="#00A3DE"
        />
        <path
          d="M22.0916 17.5843L21.9955 16.7939H19.7369L19.2564 17.5988H17.2765L17.2332 16.7939H15.0179L14.4797 17.6084H11.3417C10.8836 17.6331 10.4315 17.4956 10.0643 17.2199C9.6971 16.9441 9.43824 16.5477 9.33296 16.1C9.2608 15.6757 9.2608 15.2423 9.33296 14.818L8.54005 17.5843H6.81006C6.81006 17.5843 5.90662 15.3771 5.68556 14.8662L4.9359 17.5843H3.38372L4.5851 13.1891H4.05168L4.4169 11.8927H6.33912L7.69908 15.0927L8.2373 13.165H7.7183L8.08352 11.8638H10.1595L9.55401 13.965C9.78469 13.3525 10.1933 12.8234 10.727 12.4462C11.2608 12.0691 11.895 11.8611 12.5479 11.8494H14.8737L14.3307 13.8301H12.8218L12.9996 13.1795H12.6536C11.8318 13.1795 11.0197 14.418 11.0197 15.2711C11.0092 15.3977 11.0238 15.5252 11.0626 15.6462C11.1014 15.7672 11.1637 15.8793 11.2459 15.976C11.328 16.0728 11.4285 16.1523 11.5414 16.2099C11.6544 16.2676 11.7776 16.3023 11.9039 16.312H13.5858L15.6474 13.1747H15.1669L15.5273 11.8783H18.6845L18.9008 15.6229L20.4385 13.1795H19.958L20.328 11.8783H23.4372L23.7832 16.953C25.0807 14.0814 25.1993 10.8131 24.1133 7.85458C23.0274 4.89603 20.8241 2.48491 17.9797 1.1424C15.1353 -0.200103 11.8784 -0.366142 8.91294 0.680178C5.94748 1.7265 3.51172 3.90112 2.13218 6.73398C0.752642 9.56683 0.540144 12.8303 1.54062 15.8191C2.5411 18.8078 4.67419 21.2816 7.47878 22.7058C10.2834 24.1299 13.5342 24.3901 16.5285 23.4299C19.5228 22.4698 22.02 20.3665 23.4804 17.5747L22.0916 17.5843Z"
          fill="#00A3DE"
        />
      </g>
      <defs>
        <clipPath id="clip0_9210_15785">
          <rect width="121.83" height="24" fill="white" transform="translate(0.918457)" />
        </clipPath>
      </defs>
    </svg>
  );
}
