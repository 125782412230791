import * as React from "react";

export default function PlayVideo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="54" viewBox="0 0 55 54" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5 48.5625C39.4086 48.5625 49.0625 38.9086 49.0625 27C49.0625 15.0914 39.4086 5.4375 27.5 5.4375C15.5914 5.4375 5.9375 15.0914 5.9375 27C5.9375 38.9086 15.5914 48.5625 27.5 48.5625ZM27.5 50C40.2025 50 50.5 39.7025 50.5 27C50.5 14.2975 40.2025 4 27.5 4C14.7975 4 4.5 14.2975 4.5 27C4.5 39.7025 14.7975 50 27.5 50Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7083 21.3869V32.6134C22.7083 33.7474 23.4936 34.6667 24.4623 34.6667C24.7802 34.6667 25.0921 34.5655 25.3647 34.3741L33.3565 28.7608C34.1872 28.1774 34.4565 26.9161 33.9581 25.9438C33.81 25.6548 33.6034 25.4129 33.3565 25.2395L25.3647 19.6262C24.5341 19.0428 23.4567 19.3581 22.9583 20.3305C22.7947 20.6496 22.7083 21.0148 22.7083 21.3869Z"
        fill="currentColor"
      />
    </svg>
  );
}
