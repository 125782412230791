import QuestionOff from "../../assets/icons/QuestionOff";
import QuestionOn from "../../assets/icons/QustionOn";
import InfoOn from "../../assets/icons/InfoOn";
import InfoOff from "../../assets/icons/InfoOff";
import { PropsWithChildren, ReactNode, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { MMLTooltipPortalAndContent } from "./TooltipPortalAndContent";
import styled from "styled-components";
import { CSSProperties } from "react";

export type Align = "start" | "center" | "end";
export type Position = "top" | "bottom" | "left" | "right";
export type DefaultTriggerType = "question" | "info";

export interface TooltipProps {
  /** tooltip title */
  title?: string;
  /** tooltip main content */
  content: ReactNode;
  /** ariaLabel for the trigger */
  ariaLabel?: string;
  /** Position of the tooltip relative to content. */
  position?: Position;
  /** Choose the alignment from the tail */
  align?: Align;
  /** Manually set width on the portal container (does not include tail). */
  width?: number;
  /** Boolean used to open tooltip on initial load. */
  defaultOpen?: boolean;
  /** If true, interacting outside of portal will not close it */
  preventInteractOutsideClose?: boolean;
  /** There are 2 main types of trigger designs... an "i" icon or a "?" icon */
  defaultTriggerType?: DefaultTriggerType;
  /** Pass any custom CSS to the default trigger container */
  defaultTriggerCSS?: CSSProperties;
  /** Optional function for when tooltip is closed */
  onClose?: () => void;
  /** Optional function for when tooltip is opened */
  onOpen?: () => void;
}

const StyledTrigger = styled(Popover.Trigger)`
  cursor: pointer;
`;

const DefaultTriggerContainer = styled.div`
  height: 18px;
  width: 18px;
`;

/**
  *  @description When used with children like so, you are creating a custom trigger. Otherwise it will use the default trigger icon (as most mocks use).
  *  Also, the majority of the time the trigger is absolute-ly positioned within a container. Use `defaultTriggerCSS` to pass the styles to make the trigger absolute
  *
  *  @example // Custom trigger by using children:
  *  <MMLTooltip
      align="end"
      title={"Custom Trigger"}
      content={<p>Here we are using an MMLButton as a custom trigger</p>}
    >
      <MMLButton size="md">Custom Trigger</MMLButton>
    </MMLTooltip>

  *  @example // Default trigger question icon version
  *  <MMLTooltip
      title="Default Trigger Question"
      content="This is an example using the default trigger that most Figma mocks use"
      defaultTriggerType="question"
      />

  *  @example // Default Trigger with absolute position
  *  <MMLTooltip
      title="Default Trigger Question"
      content="This is an example using the default trigger that most Figma mocks use"
      defaultTriggerType="question"

      // Applies to the trigger container
      defaultTriggerCSS={{ position: "absolute", top: "12px", right: "12px", zIndex: 1 }}
      />
 * */
export const MMLTooltip = ({
  children,
  content,
  width,
  title,
  defaultTriggerType,
  defaultTriggerCSS,
  defaultOpen,
  preventInteractOutsideClose,
  ariaLabel = "info tooltip trigger",
  position = "top",
  align = "end",
  onClose,
  onOpen,
}: PropsWithChildren<TooltipProps>) => {
  const [isOpen, setIsOpen] = useState(defaultOpen || false);

  function renderTriggerType(triggerType: DefaultTriggerType = "question") {
    switch (triggerType) {
      case "question":
        return isOpen ? <QuestionOn /> : <QuestionOff />;
      case "info":
        return isOpen ? <InfoOn /> : <InfoOff />;
      default:
        return isOpen ? <QuestionOn /> : <QuestionOff />;
    }
  }

  function handleVisibilityStates(isOpen: boolean) {
    if (onClose) onClose();
    if (onOpen) onOpen();
    setIsOpen(isOpen);
  }

  return (
    // Using the Radix "Popover" over the "Tooltip" because it needs to open on click (rather than hover).
    // The "Popover" is more true to the intended design of our tooltips.
    <Popover.Root open={isOpen} onOpenChange={handleVisibilityStates} defaultOpen={defaultOpen}>
      <StyledTrigger
        asChild
        aria-label={ariaLabel}
        onTouchEnd={(event) => event.stopPropagation()}
        onClick={(event) => event.stopPropagation()}
        onKeyUp={(event) => event.stopPropagation()}
      >
        {children || (
          <DefaultTriggerContainer style={defaultTriggerCSS}>
            {renderTriggerType(defaultTriggerType)}
          </DefaultTriggerContainer>
        )}
      </StyledTrigger>
      <MMLTooltipPortalAndContent
        preventInteractOutsideClose={preventInteractOutsideClose}
        content={content}
        position={position}
        align={align}
        title={title}
        width={width}
        setIsOpen={setIsOpen}
      />
    </Popover.Root>
  );
};
