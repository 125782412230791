import styled from "styled-components";
import { colors, pxToRem } from "./styleUtils";

interface ButtonProps {
  primary?: boolean;
  secondary?: boolean;
  size?: "sm" | "md" | "xsm";
}

export const Button = styled.button<ButtonProps>`
  background-color: ${({ primary, secondary }) =>
    primary ? colors["lvl_-5"] : secondary ? colors.lvl_5 : colors.bcg_accent_2};
  border: ${({ primary, secondary }) =>
    secondary ? `1px solid ${colors["lvl_-5"]}` : primary ? `1px solid ${colors.lvl_5}` : "none"};
  color: ${({ primary, secondary }) => (primary ? `${colors.lvl_5}` : secondary ? `${colors["lvl_-5"]}` : "#17171d")};
  height: ${({ size }) =>
    size === "md" ? pxToRem(40) : size === "sm" ? pxToRem(32) : size === "xsm" ? pxToRem(24) : pxToRem(52)};
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  letter-spacing: 0.96px;
  font-weight: 500;
  font-size: ${({ size }) =>
    size === "md" ? pxToRem(12) : size === "sm" || size === "xsm" ? pxToRem(10) : pxToRem(14)};
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

  :disabled {
    background-color: ${({ theme, secondary, primary }) =>
      primary ? theme.lvl_4 : !secondary && !primary && theme.lvl_1};

    border-color: ${({ theme, secondary }) => secondary && theme.lvl_2};
    color: ${({ theme, secondary, primary }) => (primary || secondary ? theme.lvl_2 : theme.lvl_5)};
  }

  @media (hover: hover) {
    :hover:not(:disabled) {
      background-color: ${(props) =>
        props.primary ? colors["lvl_-2"] : props.secondary ? colors.lvl_5 : colors.bcg_accent_2_hover};
      border-color: ${(props) => (props.secondary ? colors["lvl_-1"] : "none")};
    }
  }

  &:focus-visible {
    outline: 2px solid ${colors["lvl_-2"]};
    outline-offset: 2px;
  }
`;

/**
 * Loading indicator that can be placed inline with button text.
 */
export const CircleLoader = styled.div`
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-left-color: #17171d;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 8px;
  animation: spin 0.6s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
