import { PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";
import * as Tabs from "@radix-ui/react-tabs";
import { colors } from "../../styles/styleUtils";

export interface MMLTabsProps {
  /** id - separates the different triggers / content. (equals the `value` prop for radix-ui) */
  /** triggerContent - string or react node to render the triggers / switches */
  /** viewContent - string or react node to render in the "viewport" */
  tabs: { id: string; triggerContent: string | ReactNode; viewContent: string | ReactNode }[];
  /** default tab ID to show on render  */
  defaultTabId?: string;
  /** Pass event handler for custom functionality when the value changes. */
  onValueChange?: (value: string) => void;
}

const StyledRoot = styled(Tabs.Root)`
  width: 100%;
`;

const StyledTabList = styled(Tabs.List)<{ $tabsCount: number }>`
  display: grid;
  grid-template-columns: ${({ $tabsCount }) => `repeat(${$tabsCount}, 1fr)`};
  grid-column-gap: 2px;
  border-radius: 4px;
  border: 0.75px solid ${colors["lvl_-5"]};
  height: 28px;
  padding: 2px;
`;

const StyledTrigger = styled(Tabs.Trigger)`
  font-size: 10px;
  border-radius: 4px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;
  &[data-state="active"] {
    background-color: ${colors["lvl_-5"]};
    color: ${colors["lvl_5"]};
  }
  &[data-state="inactive"] {
    &:hover {
      background-color: ${colors["lvl_3"]};
    }
  }
`;

/**
 * @description Render 2 or more tabs to switch between different views.
 * Note: There are 2 versions of "Tabs"... this is the version that looks like a switcher
 */
export const MMLTabs = ({ tabs, defaultTabId = tabs[0].id, onValueChange }: PropsWithChildren<MMLTabsProps>) => {
  return (
    <StyledRoot onValueChange={onValueChange} defaultValue={defaultTabId}>
      <StyledTabList $tabsCount={tabs.length}>
        {tabs.map((tab) => (
          <StyledTrigger aria-label={tab.id} value={tab.id}>
            {tab.triggerContent}
          </StyledTrigger>
        ))}
      </StyledTabList>
      {tabs.map((tab) => (
        <Tabs.Content value={tab.id}>{tab.viewContent}</Tabs.Content>
      ))}
    </StyledRoot>
  );
};
