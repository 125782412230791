import { useQuery } from "@tanstack/react-query";
import useApiFetch, { v3StaticApiEndpoint } from "./useApiFetch";
import useGlobalState from "./useGlobalState";

/**
 * Hook to access the Mega Bundle which contains entries from all games.
 */
export default function useBundle() {
  const [{ staticEndpointKey }] = useGlobalState();
  const { fetchApiEndpoint } = useApiFetch();

  return useQuery({
    queryKey: ["bundle"],
    queryFn: () => fetchApiEndpoint<Bundle>(`${v3StaticApiEndpoint}/${staticEndpointKey}`),
    enabled: Boolean(staticEndpointKey),
    gcTime: null,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

export interface Bundle {
  wbcg_entries?: BCGEntry[];
  mbcg_entries?: BCGEntry[];

  user: {
    bcgId: number;
    brand: string;
    forceLogout: boolean;
    id: string;
    mbcgPlayerStatus: number;
    wbcgPlayerStatus: number;
    name: string;
    staticEndpointKey: string;
    urbanAirshipId: string;
    emailSubscribed: boolean | null;
    favoriteGame: "ncaamsalarycap" | "ncaabracketchallenge" | "not_set";
  };
}

type TeamId = number;
type SlotNumbers = 1 | 2 | 3 | 4 | 5;
export type PickRecord = {
  [slot in SlotNumbers]?: TeamId;
};

export interface BCGEntry {
  /** Timestamp: e.g. "2022-02-03T11:59:22.000-05:00" */
  createdAtTimestamp: string;
  groups: BCGGroup[];
  id: string;
  isDefault: boolean;
  isPostlock: boolean;
  name: string;
  percentile: number;
  picks: BCGPick[];
  /** Timestamp: e.g. "2022-02-03T11:59:22.000-05:00" */
  picksLockedInAt: string | null;
  possiblePointsRemaining: number;
  rank: number | null;
  tiebreakerLoser: number;
  tiebreakerWinner: number;
  totalPoints: number;
  tplusTotalPoints: number;
  upsetPossiblePointsRemaining: number;
  userId: string;
}

export interface BCGPick {
  gameId: string;
  teamId: string;
}

export interface BCGGroup {
  autoRenewEnabled: boolean;
  creatorId: string;
  groupMembershipKey: string;
  groupPoints: number;
  groupPossiblePointsRemaining: number;
  group_id: string;
  isPrivate: boolean;
  group_rank: number;
  maxMembersPerUser: number;
  membersCount: number;
  name: string;
  rank: null;
  scoringMode: "traditional" | "traditionalPlus";
  showPttcPromo: boolean;
  chatId: string;
}
