import * as React from "react";

export default function FavoriteOn() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="28/Play-Favourite-Fill">
        <g id="Icon">
          <path d="M6.71028 7.29081H21.2674V21.3797H6.71028V7.29081Z" fill="var(--color_lvl_5)" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 26C20.6274 26 26 20.6274 26 14C26 7.37258 20.6274 2 14 2C7.37258 2 2 7.37258 2 14C2 20.6274 7.37258 26 14 26ZM15.7093 12.1994L14.4331 8.60106C14.2856 8.18506 13.7144 8.18506 13.5669 8.60106L12.2907 12.1994L8.45104 12.2925C8.01157 12.3032 7.83446 12.8811 8.18836 13.1498L11.2256 15.4553L10.1332 19.0935C10.005 19.5203 10.4678 19.8768 10.829 19.6294L14 17.4573L17.171 19.6294C17.5322 19.8768 17.995 19.5203 17.8668 19.0935L16.7744 15.4553L19.8116 13.1498C20.1655 12.8811 19.9884 12.3032 19.549 12.2925L15.7093 12.1994Z"
            fill="var(--color_lvl_-5)"
          />
        </g>
      </g>
    </svg>
  );
}
