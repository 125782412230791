import { createGlobalStyle } from "styled-components";

export const ColorStyles = createGlobalStyle`
  :root {
    --color_lvl_0: #8f909b;
    --color_lvl_1: #a8a9b3;
    --color_lvl_-1: #767783;
    --color_lvl_2: #c1c2ca;
    --color_lvl_-2: #5d5f6b;
    --color_lvl_-3: #464750;
    --color_lvl_3: #dfdfe7;
    --color_lvl_-4: #23232a;
    --color_lvl_4: #f4f4fa;
    --color_lvl_-5: #17171d;
    --color_lvl_5: #fdfdfd;

    --color_lvl_0a: 143, 144, 155;
    --color_lvl_1a: 168, 169, 179;
    --color_lvl_-1a: 118, 119, 131;
    --color_lvl_2a: 193, 194, 202;
    --color_lvl_-2a: 93, 95, 107;
    --color_lvl_-3a: 70, 71, 80;
    --color_lvl_3a: 223, 223, 231;
    --color_lvl_-4a: 35, 35, 42;
    --color_lvl_4a: 244, 244, 250;
    --color_lvl_-5a: 23, 23, 29;
    --color_lvl_5a: 253, 253, 253;
    --solidBgAlpha: 255, 255, 255;
    --solidAlpha: 0, 0, 0;

    --color_white: #fdfdfd;
    --color_black: #17171d;

    --color_primaryaccent: #009CDE;
    --color_secondaryaccent: #6cd2ff;
    --color_control: var(--color_black); /* does not switch in dark mode */
    --color_live_error: #E41E0C;
    --color_live_error_hover: #f43625;
    --color_mens_accent: #009CDE;
    --color_womens_accent: #EA7603;
    --color_cap_one_navy: #003A5D;
    --color_correct: #009345;
    --color_incorrect: #E41E0C;

    /* Legacy Color Names */
    --color_slc_accent: var(--color_primaryaccent);
    --color_bcg_navy: var(--color_cap_one_navy);
    --color_bcg_accent1: var(--color_cap_one_navy);
    --color_bcg_accent1_alt: color-mix(in oklab, var(--color_cap_one_navy) 70%, white);
    --color_bcg_accent2: var(--color_womens_accent);
    --color_bcg_accent2_hover: color-mix(in oklab, var(--color_womens_accent) 70%, white);
    --color_bcg_accent3: var(--color_correct);
  }

  [data-theme="dark"] {
    --color_lvl_1: #767783;
    --color_lvl_-1: #a8a9b3;
    --color_lvl_2: #5d5f6b;
    --color_lvl_-2: #c1c2ca;
    --color_lvl_3: #464750;
    --color_lvl_-3: #dfdfe7;
    --color_lvl_4: #23232a;
    --color_lvl_-4: #f4f4fa;
    --color_lvl_5: #17171d;
    --color_lvl_-5: #fdfdfd;

    --color_lvl_1a: 118, 119, 131;
    --color_lvl_-1a: 168, 169, 179;
    --color_lvl_2a: 93, 95, 107;
    --color_lvl_-2a: 193, 194, 202;
    --color_lvl_3a: 70, 71, 80;
    --color_lvl_-3a: 223, 223, 231;
    --color_lvl_4a: 35, 35, 42;
    --color_lvl_-4a: 244, 244, 250;
    --color_lvl_5a: 23, 23, 29;
    --color_lvl_-5a: 253, 253, 253;

    --solidBgAlpha: 0, 0, 0;
    --solidAlpha: 255, 255, 255;

    /* Legacy Color Names (these switch in dark mode) */
    --color_primaryaccent: var(--color_cap_one_navy);
    --color_bcg_accent1: var(--color_mens_accent);
  }
`;
