import styled from "styled-components";

/**
 * @description Generic title component used for sections. i.e "Previous Games" in team schedule and "My Brackets"
 */
export const MMLSectionTitle = styled.h3`
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;
