import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import styled from "styled-components";
import { colors, media, pxToRem } from "./styleUtils";

export const InputContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
`;

export const Input = styled.input`
  &:not([type="checkbox"]):not([type="radio"]) {
    width: 100%;
    padding: 12px 14px;
    background: ${colors.lvl_4};
    border: 1px solid ${colors.lvl_2};
    border-radius: 4px;
    font-size: 1.6rem;
    transition: all 0.1s ease-out;
    outline: none;

    &:focus {
      padding: 11px 13px;
      border: 1px solid ${colors["lvl_-5"]};
      background: ${colors.lvl_5};
    }

    &:disabled {
      background-color: ${colors.lvl_4};
      border-color: ${colors.lvl_2};
      color: ${colors.lvl_2};
      -webkit-text-fill-color: ${colors.lvl_2};
      opacity: 1; /* required on iOS */

      &::placeholder {
        color: ${colors.lvl_2};
      }

      &:hover {
        cursor: not-allowed;
      }
    }

    &.error {
      border-color: ${colors.live_error};

      :focus {
        border-color: ${colors.live_error};
      }
    }
  }
`;

export const InputErrorText = styled.div<{ $shouldShow: boolean }>`
  display: ${({ $shouldShow }) => !$shouldShow && "none"};
  position: relative;
  padding-top: 0.6rem;
  color: ${colors.live_error};
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.15;
  letter-spacing: normal;
`;

export const FormErrorText = styled(InputErrorText)`
  top: -10px;
  font-weight: 500;
  line-height: 1.8rem;
`;

/**
 * A more opinionated InputContainer with relative positioning & made a FlexRow.
 */
export const InputRow = styled(InputContainer)`
  display: flex;
  flex-direction: row;
  position: relative;

  &.register-text {
    margin-bottom: 24px;

    label {
      margin-left: 0;
      width: 100%;
      max-width: 100%;
      font-weight: 325;
      font-size: ${pxToRem(14)};
      line-height: 1.2;

      ${media.singleColumn} {
        font-size: ${pxToRem(12)};
        line-height: 1.3;
        letter-spacing: 0.4px;
        font-style: oblique;
      }
    }

    a {
      color: #009cde;
      text-decoration: none;
    }
  }

  &.flex-flow-column {
    flex-flow: column;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.align-center {
    align-items: center;
  }

  .native-external-link {
    cursor: pointer;
    color: #009cde;
  }
`;

/**
 * @example
 * <InputRow>
 *  <PwInput />
 *  <StyledVisibilityToggle />
 * </InputRow>
 */
export const PwInput = styled(Input)`
  &:not([type="checkbox"]):not([type="radio"]) {
    &:focus {
      & + button {
        background: ${colors.lvl_5};
        border-color: ${colors["lvl_-5"]};
        border-width: 1px;
        border-left: none;
        transition: all 0.1s ease-in-out;

        &.error {
          border-color: ${colors.live_error};
        }
      }
    }

    &.error {
      border-color: ${colors.live_error};
      border-right: 1px solid ${colors.live_error};

      &:focus {
        border-right: 1px solid ${colors.live_error};
      }
    }
  }
`;

export const StyledCodeInput = styled(Input)`
  &:not([type="checkbox"]):not([type="radio"]) {
    margin: 0 5px;
  }
`;

/* Styles for Radio Buttons */
export const StyledRadio = styled(RadioGroupPrimitive.Item)`
  border: solid 1px ${colors.lvl_0};
  flex: 0 0 23px;
  height: 23px;
  border-radius: 100%;
  align-self: center;
  margin-right: 10px;

  &:hover {
    background-color: ${colors.lvl_3};
  }
`;

export const StyledIndicator = styled(RadioGroupPrimitive.Indicator)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: ${colors["lvl_-5"]};
    position: absolute;
    left: 0;
    top: 0;
    outline: 1px solid ${colors["lvl_-5"]};
    border: solid 3px ${colors.lvl_5};
  }
  &:hover {
    background-color: solid 3px ${colors.lvl_3};
    &::after {
      background-color: ${colors["lvl_-2"]};
    }
  }
`;

export const RadioGroup = RadioGroupPrimitive.Root;
export const RadioGroupRadio = StyledRadio;
export const RadioGroupIndicator = StyledIndicator;

export const RadioFlex = styled.div<{ groupSelect?: boolean }>`
  display: flex;
  justify-content: flex-end;
  border-bottom: solid 0.5px ${colors.lvl_2};
  padding: ${({ groupSelect }) => (groupSelect ? "0 75px" : "0")};
  ${media.svp} {
    padding: ${({ groupSelect }) => (groupSelect ? "0 40px" : "0")};
  }

  :hover {
    background-color: ${({ groupSelect }) => (groupSelect ? colors.lvl_4 : "transparent")};
  }
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

export const RadioLabel = styled.label`
  font-size: 12px;
  line-height: 1;
  text-align: start;
  flex: 1 1 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    transform: translate(5px);
  }
`;

export const RadioLabelIcon = styled.div`
  align-self: center;
  flex: 0 0 18px;
  height: 20px;
  margin: 0 8px 0 12px;

  svg {
    display: inline;
    width: 18px;
    height: 18px;
    position: relative;
    top: 2px;
  }
`;
